import { Box, Stack } from '@mui/system'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import NumericField from '../../../../components/fields/numeric-field'
import { TradeDirectionField } from '../../../../components/fields/trade-direction-field'
import { PricingPayload } from '../../../../services/data/types/pricing'
import { Leg, TradeDirection } from '../../../../services/data/types/trade-ticket'
import { formatNaiveDate } from '../../../../utils/dates'
import { defaultInputDecimalPlaces, priceInputDecimalPlaces } from '../../../../utils/numbers'
import { CurrencyAdorment, PercentAdorment } from './shared/fields/adorments'
import BooleanSelector from './shared/fields/boolean-selector'
import DateSelector from './shared/fields/date-selector'
import { ProfileOperator } from './shared/profile-operator'
import { ProfileRow } from './shared/profile-row'

type FxForwardProfileProps = {
  leg: Leg
  currencySymbol: string
  isTradeConfirmation: boolean
  onUpdateLeg: (leg: Leg) => void
  onUpdatePricing: (payload: PricingPayload) => void
  onTradeDirectionChange: (tradeDirection: TradeDirection) => void
}

export function FxForwardProfile(props: FxForwardProfileProps) {
  const { leg, currencySymbol, isTradeConfirmation, onUpdateLeg, onUpdatePricing, onTradeDirectionChange } = props

  const { t } = useTranslation('tradeTicket')

  useEffect(() => {
    priceQuoteAmount()
  }, [leg.settlementDate?.getTime(), leg.isGiveUp])

  function priceQuoteAmount() {
    const payload = createPricingPayload()

    if (!payload || !leg.notional || !leg.forwardRate) {
      return
    }

    payload.quantity = Number(leg.notional)
    payload.price = Number(leg.forwardRate)

    onUpdatePricing(payload)
  }

  function createPricingPayload() {
    if (!leg.tradeAssetType || !leg.settlementDate) {
      return
    }

    const payload: PricingPayload = {
      asset_type: leg.tradeAssetType.asset_type_tag,
      quantity_type: 'notional',
      quantity: undefined,
      price: undefined,
      consideration: undefined,
      static_data: {},
      proposed_terms: {
        settlement_date: { NaiveDate: formatNaiveDate(leg.settlementDate) },
        is_give_up: { Boolean: leg.isGiveUp },
      },
    }

    if (leg.initialMarginRate) {
      payload.proposed_terms.initial_margin_rate = { Float: Number(leg.initialMarginRate) / 100 }
    }

    return payload
  }

  return (
    <Stack gap={3}>
      <ProfileRow>
        <TradeDirectionField
          value={leg.tradeDirection}
          disabled={isTradeConfirmation}
          onChange={onTradeDirectionChange}
          sx={{ maxWidth: '150px', mr: 2 }}
        />

        <NumericField
          required
          fixedLabel
          name="notional"
          label={t('common:notional')}
          value={leg.notional}
          decimalPlaces={defaultInputDecimalPlaces}
          onValueChange={(notional) => onUpdateLeg({ ...leg, notional })}
          onBlur={priceQuoteAmount}
          onEnterDown={priceQuoteAmount}
          endAdornment={<CurrencyAdorment symbol={currencySymbol} />}
        />
        <ProfileOperator operator="x" />
        <NumericField
          required
          fixedLabel
          name="forward_rate"
          label={t('common:forward_rate')}
          value={leg.forwardRate}
          decimalPlaces={priceInputDecimalPlaces}
          onValueChange={(forwardRate) => onUpdateLeg({ ...leg, forwardRate })}
          onBlur={priceQuoteAmount}
          onEnterDown={priceQuoteAmount}
        />
        <ProfileOperator operator="=" />
        <NumericField
          disabled
          required
          fixedLabel
          name="quote_amount"
          label={t('common:quote_amount')}
          value={leg.quoteAmount}
          decimalPlaces={defaultInputDecimalPlaces}
          endAdornment={<CurrencyAdorment symbol={currencySymbol} />}
        />
      </ProfileRow>

      <ProfileRow>
        <Box sx={{ maxWidth: '150px', mr: 2 }} />

        <DateSelector
          required
          label={t('common:settlement_date')}
          value={leg.settlementDate}
          onChange={(settlementDate) => onUpdateLeg({ ...leg, settlementDate })}
          onBlur={priceQuoteAmount}
          onEnterDown={priceQuoteAmount}
        />
        <ProfileOperator />
        <NumericField
          fixedLabel
          name="initial_margin_rate"
          label={t('common:initial_margin_rate')}
          value={leg.initialMarginRate}
          decimalPlaces={defaultInputDecimalPlaces}
          onValueChange={(initialMarginRate) => onUpdateLeg({ ...leg, initialMarginRate })}
          onBlur={priceQuoteAmount}
          onEnterDown={priceQuoteAmount}
          endAdornment={<PercentAdorment />}
        />
        <ProfileOperator />
        <BooleanSelector
          label={t('common:is_give_up')}
          value={leg.isGiveUp}
          onChange={(isGiveUp) => onUpdateLeg({ ...leg, isGiveUp })}
        />
      </ProfileRow>
    </Stack>
  )
}
