import { Dialog } from '@mui/material'
import { FormEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ModalActions from '../../../components/modal-actions'
import ModalContent from '../../../components/modal-content'
import ModalTitle from '../../../components/modal-title'
import { queryClient } from '../../../services/data/react-query'
import useAddGroupMutation from '../data/use-add-group-mutation'
import useGroupsWithMembers from '../data/use-groups-with-members'
import CreateUpdateGroupForm from './create-update-group-form'

type CreateGroupModalProps = {
  open: boolean
  onClose: () => void
}

function CreateGroupModal(props: CreateGroupModalProps) {
  const { open, onClose } = props
  const { t } = useTranslation('settings')

  const groupsResponse = useGroupsWithMembers()
  const groupTypes = groupsResponse.data?.data.group_types || {}

  const {
    mutate: addGroup,
    error: addGroupError,
    reset: addGroupReset,
    isLoading: addGroupIsLoading,
  } = useAddGroupMutation()

  const initialFormValues = {
    groupName: '',
    groupType: 'regular',
  }

  const [formValues, setFormValues] = useState(initialFormValues)

  function handleChange(name: string, value: string) {
    setFormValues({ ...formValues, [name]: value })
  }

  function handleClose() {
    setFormValues(initialFormValues)
    onClose()
    addGroupReset()
  }

  function handleSubmit(event: FormEvent) {
    event.preventDefault()
    const addGroupParams = {
      groupName: formValues.groupName,
      groupType: formValues.groupType,
    }

    addGroup(addGroupParams, {
      onSuccess: () => {
        queryClient.invalidateQueries(['tenant', 'current', 'groupswithmembers'])
        handleClose()
      },
    })
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <form onSubmit={handleSubmit}>
        <ModalTitle onClose={handleClose} title={t('create_groups_modal.create_title')} />
        <ModalContent>
          <CreateUpdateGroupForm
            groupTypes={groupTypes}
            errorMessage={addGroupError}
            onChange={handleChange}
            values={formValues}
          />
        </ModalContent>
        <ModalActions isLoading={addGroupIsLoading} confirmLabel={t('common:add')} onCancel={handleClose} />
      </form>
    </Dialog>
  )
}

export default CreateGroupModal
