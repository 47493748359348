import { ParsedAssetGridCell } from '../../modules/data-engine/data/asset-static-parsing'
import { formatDatetime } from '../../utils/dates'
import { formatFloat, formatInt, formatPercent } from '../../utils/numbers'
import { ParsedGridDataCell } from './grid-data-parsing'
import { DatapointType, DatapointValue, DatapointValueType } from './types/datapoint'

export function extractDatapointFormatAndValue(datapoint?: DatapointValue): [DatapointType | null, DatapointValueType] {
  if (datapoint === 'None') {
    return [null, null]
  }

  if (!datapoint || !Object.entries(datapoint).length) {
    return [null, null]
  }

  const [format, value] = Object.entries(datapoint)[0] as [DatapointType, any]
  return [format, value]
}

export function formatDatapoint(datapoint?: DatapointValue): string {
  const [format, value] = extractDatapointFormatAndValue(datapoint)

  return formatDatapointValue(format, value)
}

export function formatDatapointValue(format: DatapointType | null, value: any, decimalPlaces?: number): string {
  switch (format) {
    case 'Boolean':
      return value === true ? 'True' : value === false ? 'False' : ''
    case 'String':
      return value
    case 'Percent':
      return formatPercent(value, decimalPlaces)
    case 'Int':
      return formatInt(value)
    case 'Float':
      return formatFloat(value, decimalPlaces)
    case 'DateTime':
      return formatDatetime(value)
    case 'NaiveDate':
      return value
    case 'Error':
      return value.msg
    default:
      return value || ''
  }
}

/**
 * Format grid cell when copying (ctrl + c) values from grids.
 */
export function formatCellToCopy(cell: ParsedGridDataCell | ParsedAssetGridCell | undefined): string {
  if (!cell) {
    return ''
  }

  if (cell.displayAs) {
    return cell.displayAs
  }

  return formatValueToCopy(cell.value)
}

/**
 * Format datapoint value when copying (ctrl + c) from grids.
 */
export function formatValueToCopy(datapointValue: DatapointValue | undefined) {
  const [format, value] = extractDatapointFormatAndValue(datapointValue)

  switch (format) {
    case 'Percent':
      return formatPercent(value)
    case 'Int':
      return formatInt(value)
    case 'Float':
      return formatFloat(value)
    case 'DateTime':
      return formatDatetime(String(value))
    case 'NaiveDate':
      return String(value)
    case 'Error':
      return ''
    case 'DataDoc':
      return ''
    default:
      return String(value || '')
  }
}
