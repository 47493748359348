import { Search } from '@mui/icons-material'
import {
  Autocomplete,
  Chip,
  debounce,
  Dialog,
  Divider,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
} from '@mui/material'
import { ChangeEvent, FormEvent, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Alert from '../../../components/alert'
import ModalActions from '../../../components/modal-actions'
import ModalContent from '../../../components/modal-content'
import { ModalDetailRow } from '../../../components/modal-detail-row'
import ModalTitle from '../../../components/modal-title'
import SelectButton from '../../../components/select-button'
import { SecurityMoniker } from '../../../services/data/types/security'
import { Leg, TradeTicketResponse } from '../../../services/data/types/trade-ticket'
import useAssetMetaQuery from '../../portfolios/data/use-asset-meta-query'
import useSecurityDetailsQuery from '../../portfolios/data/use-security-details-query'
import useSecurityMonikerQuery from '../../portfolios/data/use-security-moniker-query'

type SelectSecurityModalProps = {
  open: boolean
  error?: string | null
  security?: Leg['security']
  onlyNoIdentifier?: boolean
  onlyExistingAssets?: boolean
  tradeTicket: TradeTicketResponse | null
  onClose: () => void
  onSave: (legSecurity: Leg['security']) => void
}

function SelectSecurityModal(props: SelectSecurityModalProps) {
  const { open, error, security, onlyNoIdentifier, onlyExistingAssets, tradeTicket, onClose, onSave } = props

  const { t } = useTranslation('tradeTicket')

  const identifierTypes = tradeTicket?.identifier_types
  const assetTypes = tradeTicket?.asset_types.filter((at) => at.allow_custom)

  const [tab, setTab] = useState<'existing' | 'new'>('existing')
  const isExistingTab = tab === 'existing'
  const isNewTab = tab === 'new'

  const [identifierType, setIdentifierType] = useState('')
  const [identifier, setIdentifier] = useState('')
  const [identifierForQuery, setIdentifierForQuery] = useState('')
  const debouncedSetIdentifier = useMemo(() => debounce(setIdentifierForQuery, 500), [])

  const existingMonikerQuery = useSecurityMonikerQuery(identifierType, identifierForQuery)
  const existingMoniker = existingMonikerQuery.data?.data
  const existingMonikerError = existingMonikerQuery.isError ? t('security_modal.get_security_detail_error') : ''
  const existingAssetRef = existingMoniker?.asset_ref || ''

  const existingSecurityQuery = useSecurityDetailsQuery(existingAssetRef)
  const existingSecurity = existingSecurityQuery.data?.data
  const existingAssetType = existingSecurity?.asset_type || ''
  const existingAssetTypeName = existingSecurity?.asset_type_name || ''
  const existingAssetName = existingSecurity?.asset_desc || ''

  const existingAssetMetaQuery = useAssetMetaQuery(existingAssetRef)
  const existingAssetMeta = existingAssetMetaQuery.data?.data
  const existingCurrencySymbol = existingAssetMeta?.currency_symbol || ''
  const existingCurrencyRef = existingAssetMeta?.currency_asset_ref || ''

  const [newAssetType, setNewAssetType] = useState('')
  const [newAssetName, setNewAssetName] = useState('')
  const [newAssetRef, setNewAssetRef] = useState('')

  const isFetching =
    existingMonikerQuery.isFetching || existingSecurityQuery.isFetching || existingAssetMetaQuery.isFetching
  const canSaveExisting = !!(isExistingTab && identifierType && identifier && existingAssetType && existingCurrencyRef)
  const canSaveNew = !!(isNewTab && newAssetType && newAssetName)
  const canSave = canSaveExisting || canSaveNew

  useEffect(() => {
    const firstIdentifierType = identifierTypes?.[0]?.identifier_type || ''
    setIdentifierType(security?.identifierType || firstIdentifierType)
  }, [open, identifierTypes])

  useEffect(() => {
    debouncedSetIdentifier(identifier)
  }, [identifier])

  useEffect(() => {
    if (open && security) {
      const isEditingExisting = !!security.identifier
      const firstIdentifierType = identifierTypes?.[0]?.identifier_type || ''

      setTab(isEditingExisting ? 'existing' : 'new')
      setIdentifierType(security.identifierType || firstIdentifierType)
      setIdentifier(security.identifier)

      if (isEditingExisting) {
        setNewAssetType('')
        setNewAssetName('')
        setNewAssetRef('')
      } else {
        setNewAssetType(security.assetType)
        setNewAssetName(security.assetName)
        setNewAssetRef(security.assetRef)
      }
    }

    if (!open) {
      setTab('existing')
      setIdentifierType('')
      setIdentifier('')
      setNewAssetType('')
      setNewAssetName('')
      setNewAssetRef('')
    }
  }, [open, security])

  function handleAssetTypeChange(newAssetTypeTag: string) {
    setNewAssetType(newAssetTypeTag)
    setNewAssetName('')
    setNewAssetRef('')
  }

  async function handleAssetNameChange(newAssetName: string) {
    const asset = assetTypes
      ?.find((asset_types) => asset_types.asset_type_tag === newAssetType)
      ?.custom_assets?.find((asset) => asset.description === newAssetName)

    setNewAssetRef(asset?.asset_ref || '')
    setNewAssetName(newAssetName)
  }

  function handleSubmit(event: FormEvent) {
    event.preventDefault()
    // prevents forms higher up to be submited as well
    event.stopPropagation()

    if (isExistingTab) {
      onSave({
        identifier,
        identifierType,
        assetRef: existingAssetRef,
        assetType: existingAssetType,
        assetTypeName: existingAssetTypeName,
        assetName: existingAssetName,
      })
    }

    if (isNewTab) {
      const assetType = assetTypes?.find((assetType) => assetType.asset_type_tag === newAssetType)
      const assetTypeName = assetType?.asset_type_name || ''

      onSave({
        identifier: '',
        identifierType: '',
        assetRef: newAssetRef,
        assetType: newAssetType,
        assetTypeName,
        assetName: newAssetName,
      })
    }
  }

  return (
    <Dialog
      open={open}
      PaperProps={{
        sx: {
          position: 'fixed',
          top: '10%',
          margin: 0,
        },
      }}
      // closeAfterTransition needed because:
      // https://github.com/mui/material-ui/issues/43106
      closeAfterTransition={false}
    >
      <form onSubmit={handleSubmit}>
        <ModalTitle title={t('security_modal.title')} onClose={onClose} />
        <ModalContent>
          <Alert severity="error" message={error} />

          {!onlyNoIdentifier && (
            <>
              <Stack direction="row" gap={2}>
                <SelectButton
                  label={t('security_modal.existing_identifier')}
                  selected={isExistingTab}
                  onClick={() => setTab('existing')}
                />
                <SelectButton
                  label={t('security_modal.no_identifier')}
                  selected={isNewTab}
                  onClick={() => setTab('new')}
                />
              </Stack>

              <Divider />
            </>
          )}

          {isExistingTab && (
            <ExistingIssueFields
              assetType={existingAssetType}
              currencySymbol={existingCurrencySymbol}
              identifierTypes={identifierTypes}
              identifierType={identifierType}
              identifier={identifier}
              securityMoniker={existingMoniker}
              securityMonikerError={existingMonikerError}
              onIdentifierTypeChange={setIdentifierType}
              onIdentifierChange={setIdentifier}
            />
          )}

          {isNewTab && (
            <NewIssueFields
              assetTypes={assetTypes}
              assetTypeTag={newAssetType}
              assetName={newAssetName}
              onlyExistingAssets={onlyExistingAssets}
              onAssetTypeChange={handleAssetTypeChange}
              onAssetNameChange={handleAssetNameChange}
            />
          )}
        </ModalContent>
        <ModalActions
          isLoading={isFetching}
          confirmDisabled={!canSave}
          confirmLabel={t('common:select')}
          onCancel={onClose}
        />
      </form>
    </Dialog>
  )
}

export default SelectSecurityModal

type ExistingIssueFieldsProps = {
  assetType?: string
  currencySymbol?: string
  identifierTypes?: TradeTicketResponse['identifier_types']
  identifierType: string
  identifier: string
  securityMoniker?: SecurityMoniker
  securityMonikerError?: string
  onIdentifierTypeChange: (identifierType: string) => void
  onIdentifierChange: (identifier: string) => void
}

function ExistingIssueFields(props: ExistingIssueFieldsProps) {
  const {
    assetType,
    currencySymbol,
    identifierTypes,
    identifierType,
    identifier,
    securityMoniker,
    securityMonikerError,
    onIdentifierTypeChange,
    onIdentifierChange,
  } = props

  const { t } = useTranslation('tradeTicket')

  function handleIdentifierTypeChange(event: SelectChangeEvent) {
    onIdentifierTypeChange(event.target.value)
  }

  function handleIdentifierChange(event: ChangeEvent<HTMLInputElement>) {
    onIdentifierChange(event.target.value)
  }

  return (
    <Stack gap={3}>
      <Stack direction="row" gap={2}>
        <Select
          required
          name="identifierType"
          value={identifierType}
          onChange={handleIdentifierTypeChange}
          sx={{ flex: 1 }}
        >
          {identifierTypes?.map((identifier) => (
            <MenuItem key={identifier.identifier_type} value={identifier.identifier_type}>
              {identifier.identifier_name}
            </MenuItem>
          ))}
        </Select>

        <TextField
          required
          name="identifier"
          value={identifier}
          error={!!securityMonikerError}
          label={t('security_modal.identifier')}
          autoComplete="off"
          onChange={handleIdentifierChange}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Search />
              </InputAdornment>
            ),
          }}
          sx={{ flex: 2 }}
        />
      </Stack>

      <Stack gap={2}>
        <ModalDetailRow
          label={t('security_modal.short_name')}
          value={securityMonikerError || securityMoniker?.short_name}
          valueColor={!!securityMonikerError ? 'error' : undefined}
        />

        <ModalDetailRow label={t('security_modal.asset_type')} value={assetType} />

        <ModalDetailRow label={t('common:currency')}>
          {currencySymbol && <Chip label={currencySymbol} size="small" />}
        </ModalDetailRow>
      </Stack>
    </Stack>
  )
}

type NewIssueFieldsProps = {
  assetTypes?: TradeTicketResponse['asset_types']
  assetTypeTag: string
  assetName: string
  onlyExistingAssets?: boolean
  onAssetTypeChange: (assetType: string) => void
  onAssetNameChange: (value: string) => void
}

function NewIssueFields(props: NewIssueFieldsProps) {
  const { assetTypes = [], assetTypeTag, assetName, onlyExistingAssets, onAssetTypeChange, onAssetNameChange } = props

  const { t } = useTranslation('tradeTicket')

  const filteredAssetTypes = assetTypes.filter((assetType) => {
    // when onlyExistingAssets only show asset types with options to be selected
    return onlyExistingAssets ? !!assetType.custom_assets?.length : true
  })
  const selectedAssetType = filteredAssetTypes.find((assetType) => assetType.asset_type_tag === assetTypeTag)
  const assets = selectedAssetType?.custom_assets || []
  const assetOptions = assets
    .filter((asset) => !!asset.description.trim())
    .map((asset) => ({ ...asset, label: asset.description }))

  const autocompleteDisabled = !selectedAssetType

  function handleAssetTypeChange(event: SelectChangeEvent) {
    onAssetTypeChange(event.target.value)
  }

  function handleAssetChange(_: any, value: { asset_ref: string; description: string } | string | null) {
    if (typeof value !== 'string') {
      onAssetNameChange(value?.description || '')
    }
  }

  function handleAssetInputChange(_: any, value: string | null) {
    onAssetNameChange(value || '')
  }

  function handleAssetBlur() {
    const asset = assets.find((asset) => asset.description.toLowerCase() === assetName.toLowerCase())

    if (asset) {
      onAssetNameChange(asset.description)
    }
  }

  return (
    <Stack gap={3}>
      <FormControl required>
        <InputLabel>{t('security_modal.asset_type')}</InputLabel>
        <Select
          name="assetTypeTag"
          label={t('security_modal.asset_type')}
          value={assetTypeTag}
          onChange={handleAssetTypeChange}
        >
          {filteredAssetTypes.map((assetType) => (
            <MenuItem key={assetType.asset_type_tag} value={assetType.asset_type_tag}>
              {assetType.asset_type_name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <Autocomplete
        freeSolo={!onlyExistingAssets}
        value={onlyExistingAssets ? undefined : assetName}
        inputValue={onlyExistingAssets ? assetName : undefined}
        isOptionEqualToValue={
          onlyExistingAssets
            ? (option, value) => {
                return option.asset_ref === value.asset_ref
              }
            : undefined
        }
        options={assetOptions}
        renderOption={(props, asset) => (
          <li {...props} key={asset.asset_ref}>
            {asset.description}
          </li>
        )}
        disabled={autocompleteDisabled}
        onChange={handleAssetChange}
        onInputChange={handleAssetInputChange}
        onBlur={handleAssetBlur}
        renderInput={(params) => (
          <TextField
            {...params}
            required
            label={onlyExistingAssets ? t('security_modal.asset') : t('security_modal.custom_description')}
            inputProps={{ ...params.inputProps, maxLength: 36 }}
          />
        )}
      />
    </Stack>
  )
}
