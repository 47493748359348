import { InfoOutlined, Upload } from '@mui/icons-material'
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  Divider,
  FormControlLabel,
  ListSubheader,
  MenuItem,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import { ChangeEvent, FormEvent, ReactNode, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ModalContent from '../../../components/modal-content'
import ModalTitle from '../../../components/modal-title'
import { StaticUploadParams } from '../../../services/data/types/asset-static-data'
import useStaticUploadCommitFileMutation from '../../portfolios/data/use-static-upload-commit-file-mutation'
import useStaticUploadGetOptionsQuery from '../../portfolios/data/use-static-upload-get-options-query'
import useStaticUploadInspectFileMutation from '../../portfolios/data/use-static-upload-inspect-file-mutation'
import useStaticUploadVerifyFileMutation from '../../portfolios/data/use-static-upload-verify-file-mutation'

type AssetStaticUploadModalProps = {
  open: boolean
  datasetRef?: string
  onSucess: () => void
  onClose: () => void
}

function AssetStaticUploadModal(props: AssetStaticUploadModalProps) {
  const { open, datasetRef, onSucess, onClose } = props

  const { t } = useTranslation('dataEngine')

  const [file, setFile] = useState<File>()
  const [useDate, setUseDate] = useState(false)
  const [createConstituents, setCreateConstituents] = useState(false)
  const [datapoints, setDatapoints] = useState<string[]>([])

  const optionsResponse = useStaticUploadGetOptionsQuery({ enabled: open, datasetRef })
  const options = optionsResponse.data?.data

  const inspectMutation = useStaticUploadInspectFileMutation()
  const verifyMutation = useStaticUploadVerifyFileMutation()
  const commitMutation = useStaticUploadCommitFileMutation()

  const inspection = inspectMutation.data?.data
  const verifySuccess = verifyMutation.isSuccess
  const fileErrors = inspectMutation.error || verifyMutation.error || commitMutation.error

  const showInspection = !!inspection
  const showTable = !!inspection && !!options
  const showOptions = !!inspection && inspection.headings.length > 1
  const canSubmit = !!file && isFormValid()
  const canVerify = canSubmit && inspectMutation.isSuccess
  const canCommit = canSubmit && verifyMutation.isSuccess

  function isFormValid() {
    if (!options || !inspection) {
      return false
    }

    const datapointsSelected = datapoints.filter(Boolean).length
    const datapointsThatShouldBeSelected = inspection.headings.length - 1 - Number(useDate)
    const missingDatapoints = datapointsSelected !== datapointsThatShouldBeSelected

    return !missingDatapoints
  }

  function handleFileSelected(file: File) {
    setFile(file)
    handleInspect(file)
  }

  function handleFileRemoved() {
    setFile(undefined)
    inspectMutation.reset()
    verifyMutation.reset()
    commitMutation.reset()
  }

  function setDatapointAtIndex(datapointRef: string, index: number) {
    const newDatapoints = [...datapoints]
    newDatapoints[index] = datapointRef
    setDatapoints(newDatapoints)
  }

  function handleInspect(file: File) {
    verifyMutation.reset()
    commitMutation.reset()

    inspectMutation.mutate(
      { file },
      {
        onSuccess: (data) => {
          if (datapoints.length) {
            const headingsLength = data.data.headings.length
            if (headingsLength < datapoints.length) {
              setDatapoints(datapoints.slice(0, headingsLength))
            }
          } else {
            const newDatapoints = data.data.headings.map((heading, index) => {
              // don't try to match a datapoint for the first columns
              const columnsToSkip = useDate ? 2 : 1
              if (!heading || index < columnsToSkip) {
                return ''
              }

              const principalColumn = options?.principal_columns.find((pc) => {
                return pc.display_as.toLowerCase() === heading.toLowerCase()
              })
              if (principalColumn) {
                return principalColumn.tag
              }

              const staticField = options?.static_fields.find((sf) => {
                return sf.datapoint_name.toLowerCase() === heading.toLowerCase()
              })
              if (staticField) {
                return staticField.datapoint_ref
              }
              return ''
            })
            setDatapoints(newDatapoints)
          }
        },
      }
    )
  }

  function handleVerify() {
    if (!canSubmit) {
      return
    }

    commitMutation.reset()

    const params: StaticUploadParams = {
      datapoints,
      useDate,
      createConstituents,
      datasetRef,
    }
    verifyMutation.mutate({ params, file })
  }

  function handleCommit() {
    if (!canSubmit) {
      return
    }

    verifyMutation.reset()

    const params: StaticUploadParams = {
      datapoints,
      useDate,
      createConstituents,
      datasetRef,
    }
    commitMutation.mutate(
      { params, file },
      {
        onSuccess: () => {
          onSucess()
          handleClose()
        },
      }
    )
  }

  function handleSubmit(event: FormEvent) {
    event.preventDefault()
    handleCommit()
  }

  function handleClose() {
    onClose()
    setFile(undefined)
    setDatapoints([])
    setUseDate(false)
    inspectMutation.reset()
    verifyMutation.reset()
    commitMutation.reset()
  }

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="md"
      PaperProps={{
        sx: {
          position: 'fixed',
          top: '5%',
          maxHeight: '90%',
          my: 0,
        },
      }}
      sx={{
        '& .MuiDialog-paper': {
          width: '100%',
        },
      }}
    >
      <form onSubmit={handleSubmit}>
        <ModalTitle title={t('asset_static_upload.modal_title')} onClose={handleClose} />
        <ModalContent>
          <FileInput
            file={file}
            isUploading={inspectMutation.isLoading}
            onSelect={handleFileSelected}
            onRemove={handleFileRemoved}
          />

          <Alert
            severity="info"
            variant="filled"
            sx={{
              p: 2,
              pr: 4,
              color: 'gray.700',
              background: 'rgba(255, 255, 255, 0.08)',
            }}
          >
            <AlertTitle>{t('asset_static_upload.explainer_title')}</AlertTitle>
            <div>{t('asset_static_upload.explainer_content_1')}</div>
            <div>{t('asset_static_upload.explainer_content_2')}</div>
          </Alert>

          {showInspection && <Divider />}

          {showOptions && (
            <Stack direction="row" gap={2}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={useDate}
                    onChange={(_event, checked) => {
                      setUseDate(checked)
                      setDatapointAtIndex('', 1)
                    }}
                  />
                }
                label={
                  <Stack direction="row" alignItems="center" gap={1}>
                    <span>{t('asset_static_upload.use_date_label')}</span>
                    <Tooltip
                      title={t('asset_static_upload.use_date_tooltip')}
                      placement="right"
                      arrow
                      disableInteractive
                    >
                      <InfoOutlined sx={{ color: 'gray.300' }} />
                    </Tooltip>
                  </Stack>
                }
                sx={{ alignSelf: 'start' }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={createConstituents}
                    onChange={(_event, checked) => {
                      setCreateConstituents(checked)
                    }}
                  />
                }
                label={
                  <Stack direction="row" alignItems="center" gap={1}>
                    <span>{t('asset_static_upload.create_constituents_label')}</span>
                    <Tooltip
                      title={t('asset_static_upload.create_constituents_tooltip')}
                      placement="right"
                      arrow
                      disableInteractive
                    >
                      <InfoOutlined sx={{ color: 'gray.300' }} />
                    </Tooltip>
                  </Stack>
                }
                sx={{ alignSelf: 'start' }}
              />
            </Stack>
          )}

          {showTable && (
            <TableContainer>
              <UploadTable>
                <TableBody>
                  <TableRow>
                    {inspection.headings.map((_heading, index) => {
                      if (index === 0) {
                        return (
                          <TableCell key={index} sx={{ minWidth: '150px' }}>
                            {t('identifier')}
                          </TableCell>
                        )
                      }

                      if (index === 1 && useDate) {
                        return (
                          <TableCell key={index} sx={{ minWidth: '174px' }}>
                            {t('date')}
                          </TableCell>
                        )
                      }

                      return (
                        <TableCell key={index}>
                          <Select
                            value={datapoints[index] || ''}
                            onChange={(event) => {
                              setDatapointAtIndex(String(event.target.value), index)
                            }}
                            sx={{ width: '100%', minWidth: '150px' }}
                          >
                            <MenuItem value="">---</MenuItem>

                            {options.principal_columns
                              .sort((a, b) => a.display_as.localeCompare(b.display_as))
                              .map((field) => (
                                <MenuItem key={field.tag} value={field.tag}>
                                  {field.display_as}
                                </MenuItem>
                              ))}

                            <ListSubheader>{t('asset_static_upload.static_fields')}</ListSubheader>
                            {options.static_fields
                              .sort((a, b) => a.datapoint_name.localeCompare(b.datapoint_name))
                              .map((field) => (
                                <MenuItem key={field.datapoint_ref} value={field.datapoint_ref}>
                                  {field.datapoint_name}
                                </MenuItem>
                              ))}
                          </Select>
                        </TableCell>
                      )
                    })}
                  </TableRow>

                  <TableRow>
                    {inspection.headings.map((heading, index) => (
                      <TableCell key={index}>{heading}</TableCell>
                    ))}
                  </TableRow>

                  {inspection.rows.map((row, rIndex) => (
                    <TableRow key={rIndex}>
                      {row.map((cell, cIndex) => (
                        <TableCell key={cIndex}>{cell}</TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </UploadTable>
            </TableContainer>
          )}

          {verifySuccess && (
            <Alert icon={false} variant="outlined">
              <AlertTitle color="rgba(194, 228, 195, 1.00)">{t('asset_static_upload.file_errors')}</AlertTitle>
              {t('asset_static_upload.verify_success_message')}
            </Alert>
          )}

          {!!fileErrors && (
            <Alert
              icon={false}
              variant="outlined"
              severity="error"
              sx={{
                borderColor: 'rgba(244, 67, 54, 1)',
                '& .MuiAlert-message': { width: '100%' },
              }}
            >
              <AlertTitle color="rgba(251, 180, 175, 1.00)">{t('asset_static_upload.file_errors')}</AlertTitle>
              <TextField
                value={fileErrors}
                InputProps={{ readOnly: true }}
                multiline
                sx={{
                  width: '100%',
                  '& fieldset': { border: '0 !important' },
                  '& textarea': { color: 'rgba(251, 180, 175, 1.00)' },
                  '& .MuiInputBase-root': { px: 0 },
                }}
              />
            </Alert>
          )}
        </ModalContent>

        <Divider />

        <DialogActions>
          <Button onClick={handleClose} sx={{ mr: 'auto' }}>
            {t('common:cancel')}
          </Button>
          <Button variant="outlined" color="primary" disabled={!canVerify} onClick={handleVerify}>
            {verifyMutation.isLoading && <CircularProgress size={24} color="inherit" sx={{ position: 'absolute' }} />}
            <Typography color="inherit" variant="inherit" sx={{ opacity: verifyMutation.isLoading ? 0 : 1 }}>
              {t('asset_static_upload.verify')}
            </Typography>
          </Button>
          <Button variant="contained" color="primary" type="submit" disabled={!canCommit}>
            {commitMutation.isLoading && <CircularProgress size={24} color="inherit" sx={{ position: 'absolute' }} />}
            <Typography color="inherit" variant="inherit" sx={{ opacity: commitMutation.isLoading ? 0 : 1 }}>
              {t('asset_static_upload.commit')}
            </Typography>
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default AssetStaticUploadModal

type FileInputProps = {
  file: File | undefined
  isUploading: boolean
  onSelect: (file: File) => void
  onRemove: () => void
}

function FileInput(props: FileInputProps) {
  const { file, isUploading, onSelect, onRemove } = props

  const { t } = useTranslation('dataEngine')

  const input = useRef<HTMLInputElement>(null)

  function handleFileChange(event: ChangeEvent<HTMLInputElement>) {
    const files = event.target.files
    const file = files?.[0]

    if (file) {
      onSelect(file)
    }

    input.current!.value = ''
  }

  function handleRemove() {
    input.current!.value = ''
    onRemove()
  }

  return (
    <Box
      sx={{
        height: '192px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        border: 'dashed',
        borderWidth: '1px',
        borderColor: 'gray.300',
        borderRadius: '4px',
        lineHeight: 1,
        marginRight: '16px',
        paddingLeft: '12px',
        overflow: 'hidden',
        transition: 'background 150ms',
        '&:hover': {
          borderColor: 'primary.main',
          backgroundColor: 'primary.light',
        },
      }}
    >
      <input
        ref={input}
        type="file"
        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        onChange={handleFileChange}
        style={{
          margin: 0,
          opacity: '0',
          fontSize: '0px',
          width: '100%',
          height: '100%',
          position: 'absolute',
          top: '0px',
          left: '0px',
          cursor: 'pointer',
        }}
      />
      <Stack alignItems="center" gap={1}>
        {isUploading && (
          <>
            <CircularProgress size="20px" />
            <Typography>{t('asset_static_upload.upload_in_progress')}</Typography>
          </>
        )}

        {!isUploading && (
          <>
            <Upload sx={{ color: 'gray.300' }} />
            <Typography>{t('asset_static_upload.select_file_message')}</Typography>
          </>
        )}

        {file && <Chip label={file.name} onDelete={handleRemove} />}

        {!file && !isUploading && <Typography color="gray.300">{t('asset_static_upload.file_types')}</Typography>}
      </Stack>
    </Box>
  )
}

type UploadTableProps = {
  children: ReactNode
}

function UploadTable(props: UploadTableProps) {
  return (
    <Table
      size="small"
      sx={{
        '& .MuiTableRow-root .MuiTableCell-root': {
          color: 'white',
          borderRadius: 0,
          borderBottom: '1px solid',
          borderRight: '1px solid',
          borderColor: 'divider',
        },
        '& .MuiTableRow-root .MuiTableCell-root:first-of-type': {
          borderLeft: '1px solid',
          borderColor: 'divider',
        },
        '& .MuiTableRow-root:first-of-type .MuiTableCell-root': {
          borderLeft: 'none',
          borderRight: 'none',
          borderBottom: 'none',
          pb: 2,
        },
        '& .MuiTableRow-root:nth-of-type(2) .MuiTableCell-root': {
          backgroundColor: 'gray.50',
          borderTop: '1px solid',
          borderColor: 'divider',
          p: 2,
        },
        '& .MuiTableRow-root:nth-of-type(2) .MuiTableCell-root:first-of-type': {
          borderTopLeftRadius: '4px',
        },
        '& .MuiTableRow-root:nth-of-type(2) .MuiTableCell-root:last-of-type': {
          borderTopRightRadius: '4px',
        },
        '& .MuiTableRow-root:last-of-type .MuiTableCell-root:first-of-type': {
          borderBottomLeftRadius: '4px',
        },
        '& .MuiTableRow-root:last-of-type .MuiTableCell-root:last-of-type': {
          borderBottomRightRadius: '4px',
        },
      }}
    >
      {props.children}
    </Table>
  )
}
