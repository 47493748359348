import { styled } from '@mui/material'
import { ReactNode } from 'react'

type NotcheFieldProps = {
  label: string
  children: ReactNode
}

function NotchedField(props: NotcheFieldProps) {
  return (
    <Wrapper>
      <Content>{props.children}</Content>
      <Fieldset>
        <Legend>
          <span>{props.label}</span>
        </Legend>
      </Fieldset>
    </Wrapper>
  )
}

export default NotchedField

const Wrapper = styled('div')`
  height: 56px;
  width: 100%;
  min-width: 120px;
  display: inline-flex;
  flex-direction: column;
  position: relative;
  padding: 0;
  margin: 0;
  border: 0;
  vertical-align: top;

  &:hover fieldset {
    border-color: white;
  }
`

const Content = styled('div')`
  padding: 16.5px 14px;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`

const Fieldset = styled('fieldset')`
  min-width: 0%;
  position: absolute;
  text-align: left;
  top: -6px;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 0 8px;
  pointer-events: none;
  border: 1px solid rgba(255, 255, 255, 0.23);
  border-radius: 4px;
`

const Legend = styled('legend')`
  padding: 0 4px;
  max-width: calc(133% - 32px);
  font-weight: 400;
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  user-select: none;
`
