import {
  Dialog,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  SelectChangeEvent,
  TextField,
  Typography,
} from '@mui/material'
import { ChangeEvent, FormEvent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Alert from '../../../components/alert'
import ModalActions from '../../../components/modal-actions'
import ModalContent from '../../../components/modal-content'
import ModalTitle from '../../../components/modal-title'
import { VectorShockBy, VectorShockType, isValidTenor } from '../../../services/data/types/grid-panels'
import { isValidNaiveDate } from '../../../utils/dates'
import { isValidNumber } from '../../../utils/numbers'

type EditShockModalProps = {
  open: boolean
  initialOptions: ShockOptions | null
  error?: string | null
  onSave: (options: ShockOptions | null) => void
  onClose: () => void
}

export type ShockOptions = {
  shockType: VectorShockType
  shockBy: VectorShockBy
  value: string
}

type ValueChangeEvent = ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<any>

function EditShockModal(props: EditShockModalProps) {
  const { open, initialOptions, error, onSave, onClose } = props

  const { t } = useTranslation('portfolio')

  const [shockType, setShockType] = useState<VectorShockType>('relative')
  const [shockBy, setShockBy] = useState<VectorShockBy>('date')
  const [value, setValue] = useState('')

  const [syntaxError, setSyntaxError] = useState('')

  const submitDisabled = !shockType || !shockBy || !value
  const errorMessage = error

  const helpText = shockBy
    ? shockBy === 'date'
      ? t('edit_shock_modal.shock_by_date_message')
      : t('edit_shock_modal.shock_by_tenor_message')
    : ' '

  useEffect(() => {
    if (!open) {
      setShockType('relative')
      setShockBy('date')
      setValue('')
      setSyntaxError('')
    } else {
      if (initialOptions) {
        setShockType(initialOptions.shockType)
        setShockBy(initialOptions.shockBy)
        setValue(initialOptions.value)
      }
    }
  }, [open, initialOptions])

  function handleChange(event: ValueChangeEvent) {
    setValue(event.target.value)
  }

  function validateValue(value: string) {
    if (!shockBy) {
      return
    }

    const isDateShock = shockBy === 'date'

    value.split('\n').forEach((line, idx) => {
      const index = idx + 1
      const parts = line
        .trim()
        .split(/\s+/)
        .filter(Boolean)
        .map((part) => part.trim())

      if (parts.length === 0) {
        return
      }

      if (parts.length !== 2) {
        if (isDateShock) {
          throw new Error(t('edit_shock_modal.invalid_date_line', { index, line }))
        } else {
          throw new Error(t('edit_shock_modal.invalid_tenor_line', { index, line }))
        }
      }

      const [part1, part2] = parts as [string, string]

      if (isDateShock) {
        if (!isValidNaiveDate(part1)) {
          throw new Error(t('edit_shock_modal.invalid_date', { index, value: part1 }))
        }
      } else {
        if (!isValidTenor(part1)) {
          throw new Error(t('edit_shock_modal.invalid_tenor', { index, value: part1 }))
        }
      }

      if (!isValidNumber(part2)) {
        throw new Error(t('edit_shock_modal.invalid_number', { index, value: part2 }))
      }
    })
  }

  function handleRemove() {
    onSave(null)
  }

  function handleSubmit(event: FormEvent) {
    event.preventDefault()
    event.stopPropagation()

    if (submitDisabled) {
      return
    }

    try {
      validateValue(value)

      const cleanValue = value
        .split('\n')
        .map((line) => line.trim())
        .filter(Boolean)
        .join('\n')

      onSave({ shockBy, shockType, value: cleanValue })
      setSyntaxError('')
    } catch (error: any) {
      setSyntaxError(error.message)
      return
    }
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      // closeAfterTransition needed because:
      // https://github.com/mui/material-ui/issues/43106
      closeAfterTransition={false}
    >
      <form onSubmit={handleSubmit}>
        <ModalTitle title={t('edit_shock_modal.title')} onClose={onClose} />
        <ModalContent>
          <Alert severity="error" message={errorMessage} />

          <FormControl>
            <FormLabel>{t('edit_shock_modal.shock_type')}</FormLabel>
            <RadioGroup
              row
              value={shockType}
              onChange={(_, newValue) => {
                setShockType(newValue as VectorShockType)
              }}
            >
              <FormControlLabel
                disabled
                value="absolute"
                control={<Radio />}
                label={
                  <Typography variant="body2" sx={{ textDecoration: 'line-through' }} title="Available soon">
                    {t('edit_shock_modal.shock_type_absolute')}
                  </Typography>
                }
              />
              <FormControlLabel
                value="relative"
                control={<Radio />}
                label={<Typography variant="body2">{t('edit_shock_modal.shock_type_relative')}</Typography>}
              />
              <FormControlLabel
                value="factor_shift"
                control={<Radio />}
                label={<Typography variant="body2">{t('edit_shock_modal.shock_type_factor_shift')}</Typography>}
              />
            </RadioGroup>
          </FormControl>

          <FormControl>
            <FormLabel>{t('edit_shock_modal.shock_by')}</FormLabel>
            <RadioGroup
              row
              value={shockBy}
              onChange={(_, newValue) => {
                setShockBy(newValue as VectorShockBy)
              }}
            >
              <FormControlLabel
                value="date"
                control={<Radio />}
                label={<Typography variant="body2">{t('edit_shock_modal.shock_by_date')}</Typography>}
              />
              <FormControlLabel
                value="tenor"
                control={<Radio />}
                label={<Typography variant="body2">{t('edit_shock_modal.shock_by_tenor')}</Typography>}
              />
            </RadioGroup>
          </FormControl>

          {/* <FormControl>
            <InputLabel shrink>{t('edit_shock_modal.shock_type')}</InputLabel>
            <Select
              notched
              autoFocus
              label={t('edit_shock_modal.shock_type')}
              value={shockType}
              onChange={(event) => setShockType(event.target.value as VectorShockType)}
            >
              <MenuItem value="absolute" disabled>
                {t('edit_shock_modal.shock_type_absolute')}
              </MenuItem>
              <MenuItem value="relative">{t('edit_shock_modal.shock_type_relative')}</MenuItem>
              <MenuItem value="factor_shift">{t('edit_shock_modal.shock_type_factor_shift')}</MenuItem>
            </Select>
          </FormControl>

          <FormControl required fullWidth>
            <InputLabel shrink>{t('edit_shock_modal.shock_by')}</InputLabel>
            <Select
              notched
              label={t('edit_shock_modal.shock_by')}
              value={shockBy}
              onChange={(event) => setShockBy(event.target.value as VectorShockBy)}
            >
              <MenuItem value="date">{t('edit_shock_modal.shock_by_date')}</MenuItem>
              <MenuItem value="tenor">{t('edit_shock_modal.shock_by_tenor')}</MenuItem>
            </Select>
          </FormControl> */}

          <TextField
            multiline
            autoFocus
            rows={15}
            name="datadoc"
            label={t('common:value')}
            helperText={helpText}
            value={value}
            onChange={handleChange}
            InputLabelProps={{ shrink: true }}
            sx={{
              textarea: {
                fontFamily: 'monospace',
                whiteSpace: 'pre',
              },
              '.MuiInputBase-root': {
                paddingRight: 1,
                paddingBottom: 1,
              },
            }}
          />

          <Alert severity="error" message={syntaxError} onClose={() => setSyntaxError('')} sx={{ whiteSpace: 'pre' }} />
        </ModalContent>
        <ModalActions
          confirmLabel={t('common:update')}
          confirmDisabled={submitDisabled}
          onCancel={onClose}
          onRemove={handleRemove}
        />
      </form>
    </Dialog>
  )
}

export default EditShockModal
