import { Divider, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import { Box } from '@mui/system'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Leg } from '../../../services/data/types/trade-ticket'
import { UserInTenant } from '../../../services/data/types/user'

type SecurityLegCommonParticularsProps = {
  leg: Leg
  users: UserInTenant[]
  onUpdate: (leg: Leg) => void
}

export function SecurityLegCommonParticulars(props: SecurityLegCommonParticularsProps) {
  const { leg, users, onUpdate } = props

  const { t } = useTranslation('tradeTicket')

  const filteredUsers = useMemo(() => {
    return users
      .filter((user) => !user.is_system_user)
      .sort((a, b) => `${a.firstname} ${a.lastname}`.localeCompare(`${b.firstname} ${b.lastname}`))
  }, [users])

  const showMissingUser =
    !!filteredUsers.length &&
    !!leg.commonParticulars.executor &&
    !filteredUsers.find((user) => user.user_ref === leg.commonParticulars.executor)

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: 'repeat(4, 1fr)',
        gridTemplateRows: 'auto',
        gap: 2,
      }}
    >
      <FormControl>
        <TextField
          name="venue"
          label={t('venue')}
          value={leg.commonParticulars.venue || ''}
          autoComplete="off"
          onChange={(event) =>
            onUpdate({ ...leg, commonParticulars: { ...leg.commonParticulars, venue: event.target.value } })
          }
        />
      </FormControl>

      <FormControl>
        <TextField
          name="unique_txn_identifier"
          label={t('unique_txn_identifier')}
          value={leg.commonParticulars.unique_txn_identifier || ''}
          autoComplete="off"
          onChange={(event) =>
            onUpdate({
              ...leg,
              commonParticulars: { ...leg.commonParticulars, unique_txn_identifier: event.target.value },
            })
          }
        />
      </FormControl>

      <FormControl>
        <TextField
          name="cci_number"
          label={t('cci_number')}
          value={leg.commonParticulars.cci_number || ''}
          autoComplete="off"
          onChange={(event) =>
            onUpdate({ ...leg, commonParticulars: { ...leg.commonParticulars, cci_number: event.target.value } })
          }
        />
      </FormControl>

      <FormControl>
        <InputLabel>{t('executor')}</InputLabel>
        <Select
          name="executor"
          label={t('executor')}
          value={leg.commonParticulars.executor || ''}
          onChange={(event) =>
            onUpdate({
              ...leg,
              commonParticulars: { ...leg.commonParticulars, executor: event.target.value },
            })
          }
        >
          {showMissingUser && (
            <MenuItem value={leg.commonParticulars.executor || ''} disabled>
              {t('deleted_user', { userRef: leg.commonParticulars.executor })}
            </MenuItem>
          )}
          {showMissingUser && <Divider />}
          {filteredUsers.map((user) => (
            <MenuItem key={user.user_ref} value={user.user_ref}>
              {`${user.firstname} ${user.lastname}`}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  )
}
