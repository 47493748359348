import { Dialog, FormControl, TextField } from '@mui/material'
import { FormEvent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Alert from '../../../components/alert'
import ModalActions from '../../../components/modal-actions'
import ModalContent from '../../../components/modal-content'
import ModalTitle from '../../../components/modal-title'
import { StaticOptionsResponse } from '../../../services/data/types/asset-static-data'
import { ViewAssetTypeFormFields, useViewAssetTypesState } from './view-new-modal'

type ViewSaveAsModalProps = {
  open: boolean
  initialName: string
  initialAssetTypes: string[] | undefined
  assetTypes: StaticOptionsResponse['asset_types'] | undefined
  error?: string | null
  isLoading: boolean
  onSubmit: (params: ViewSaveAsParams) => void
  onClose: () => void
}

export type ViewSaveAsParams = {
  name: string
  assetTypes: string[] | undefined
}

function ViewSaveAsModal(props: ViewSaveAsModalProps) {
  const { open, initialName, initialAssetTypes, assetTypes, error, isLoading, onSubmit, onClose } = props

  const { t } = useTranslation('portfolio')

  const [name, setName] = useState('')
  const assetTypeState = useViewAssetTypesState(initialAssetTypes)

  const canSubmit = !isLoading && !!name && assetTypeState.isValid

  useEffect(() => setName(initialName), [initialName])

  function handleSubmit(event: FormEvent) {
    event.preventDefault()

    if (canSubmit) {
      onSubmit({ name, assetTypes: assetTypeState.selected })
    }
  }

  function handleClose() {
    onClose()
    setName('')
    assetTypeState.reset()
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <form onSubmit={handleSubmit}>
        <ModalTitle title={t('common:save_as')} onClose={handleClose} />
        <ModalContent>
          <Alert severity="error" message={error} />

          <FormControl>
            <TextField
              required
              autoFocus
              autoComplete="off"
              label={t('common:name')}
              value={name}
              onChange={(event) => setName(event.target.value)}
              inputProps={{ maxLength: 24 }}
            />
          </FormControl>

          <ViewAssetTypeFormFields
            mode={assetTypeState.mode}
            selected={assetTypeState.selected}
            options={assetTypes}
            onModeChange={assetTypeState.onModeChange}
            onSelectedChange={assetTypeState.onSelectedChange}
          />
        </ModalContent>
        <ModalActions
          isLoading={isLoading}
          confirmDisabled={!canSubmit}
          confirmLabel={t('common:create')}
          onCancel={handleClose}
        />
      </form>
    </Dialog>
  )
}

export default ViewSaveAsModal
