import CodeIcon from '@mui/icons-material/Code'
import { Tooltip, Typography } from '@mui/material'
import { formatDatapointValue } from '../../services/data/datapoint-formatting'
import { DataDocType } from '../../services/data/types/asset-static-data'
import { DatapointType, DatapointValueType } from '../../services/data/types/datapoint'

type DatapointContentProps = {
  format: DatapointType | null
  value: DatapointValueType
  displayAs?: string | null
  datadocType?: DataDocType | null
  decimalPlaces?: number
  isIncomplete?: boolean
}

function DatapointContent(props: DatapointContentProps): JSX.Element {
  const { format, value, displayAs, datadocType, decimalPlaces, isIncomplete } = props

  if (format === 'DataDoc' && typeof value === 'string') {
    return <Datadoc value={value} displayAs={displayAs} datadocType={datadocType} />
  }

  if (displayAs) {
    return <>{displayAs}</>
  }

  return (
    <span style={{ color: isIncomplete ? '#ffa500' : undefined }}>
      {formatDatapointValue(format, value, decimalPlaces)}
    </span>
  )
}

export default DatapointContent

type DatadocProps = {
  value: string
  displayAs?: string | null
  datadocType?: DataDocType | 'shock' | null
}

export function Datadoc(props: DatadocProps) {
  const { value, displayAs, datadocType } = props

  return (
    <Tooltip
      title={
        <Typography variant="inherit" sx={{ fontFamily: 'monospace', whiteSpace: 'pre' }}>
          {displayAs || parseDataDocValue(value, datadocType)}
        </Typography>
      }
      enterTouchDelay={10}
      placement="bottom"
      componentsProps={{
        tooltip: {
          sx: {
            backgroundColor: 'gray.main',
            border: '1px solid rgba(255, 255, 255, 0.23)',
            alignItems: 'center',
            maxWidth: '600px',
            maxHeight: '400px',
            overflow: 'auto',
            p: 1,
          },
        },
      }}
    >
      <CodeIcon sx={{ color: 'gray.300', fontSize: 16 }} />
    </Tooltip>
  )
}

function parseDataDocValue(value: string, dataDocType?: DataDocType | 'shock' | null) {
  try {
    if (dataDocType === 'date_schedule') {
      const dates = JSON.parse(value) as string[]

      return dates.join('\n')
    }

    if (dataDocType === 'shock') {
      return value
    }

    return JSON.stringify(JSON.parse(value), null, 2)
  } catch (error) {
    return '<failed to parse>'
  }
}
