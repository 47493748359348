import { useMutation } from '@tanstack/react-query'
import { AxiosError, AxiosResponse } from 'axios'
import api from '../../../services/data/api'
import parseError from '../../../services/data/parse-error'
import { RevealApiKeyResponse } from '../../../services/data/types/api-key'

type Params = {
  userRef: string
  apiKeyId: number
}

export function useRevealApiKeyMutation() {
  const response = useMutation<AxiosResponse<RevealApiKeyResponse>, AxiosError, Params>({
    mutationFn: ({ userRef, apiKeyId }) => {
      return api.revealApiKey(userRef, apiKeyId)
    },
    cacheTime: 0,
  })

  return { ...response, error: parseError(response.error) }
}
