import { Clear } from '@mui/icons-material'
import { IconButton, Stack, TextField } from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'
import NumericField from '../../../components/fields/numeric-field'
import { dateFormat, datePlaceholder } from '../../../utils/dates'
import { defaultInputDecimalPlaces } from '../../../utils/numbers'

type ClassificationValueFormProps = {
  type?: 'add' | 'edit'
  values: ClassificationValueFormValues
  isBucketed?: boolean
  onChange: (name: string, value: string) => void
}

export type ClassificationValueFormValues = {
  tag: string
  description: string
  bucket_value: string
  expiry: string
}

function ClassificationValueForm(props: ClassificationValueFormProps) {
  const { type, values, isBucketed, onChange } = props
  const { t } = useTranslation('dataEngine')

  function handleChange(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    const { name, value } = event.target
    onChange(name, value)
  }

  function handleBucketChange(value: string): void {
    onChange('bucket_value', value)
  }

  function handleDateChange(value: string | null) {
    onChange('expiry', value || '')
  }

  function handleClearDate() {
    handleDateChange(null)
  }

  return (
    <>
      <Stack direction="row" gap={2}>
        <TextField
          fullWidth
          required
          name="tag"
          label={t('classification_values_table.form.tag')}
          value={values.tag}
          disabled={type === 'edit'}
          onChange={handleChange}
        />
        <TextField
          fullWidth
          required
          name="description"
          label={t('classification_values_table.form.description')}
          value={values.description}
          onChange={handleChange}
        />
      </Stack>
      {isBucketed && (
        <NumericField
          name="bucket_value"
          label={t('classification_values_table.form.bucket_start_value')}
          value={values.bucket_value}
          decimalPlaces={defaultInputDecimalPlaces}
          onValueChange={handleBucketChange}
        />
      )}
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          value={values.expiry || null}
          inputFormat={dateFormat}
          onChange={handleDateChange}
          renderInput={(params) => (
            <TextField
              {...params}
              label={t('classification_values_table.form.expiry')}
              autoComplete="off"
              inputProps={{
                ...params.inputProps,
                placeholder: datePlaceholder,
              }}
              InputProps={{
                endAdornment: (
                  <Stack direction="row" alignItems="center">
                    {values.expiry && (
                      <IconButton size="small" onClick={handleClearDate} sx={{ mr: -1 }}>
                        <Clear fontSize="small" sx={{ color: 'gray.300' }} />
                      </IconButton>
                    )}
                    {params.InputProps?.endAdornment}
                  </Stack>
                ),
              }}
            />
          )}
        />
      </LocalizationProvider>
    </>
  )
}

export default ClassificationValueForm
