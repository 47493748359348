import { Edit } from '@mui/icons-material'
import { FormControl, IconButton, InputAdornment, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import { Box, Stack } from '@mui/system'
import { useTranslation } from 'react-i18next'
import NumericField from '../../../../components/fields/numeric-field'
import { TradeDirectionField } from '../../../../components/fields/trade-direction-field'
import { Leg, TradeDirection, TradeTicketResponse } from '../../../../services/data/types/trade-ticket'
import useOpenState from '../../../../utils/hooks/use-open-state'
import { defaultInputDecimalPlaces } from '../../../../utils/numbers'
import useSecurityDetailsQuery from '../../../portfolios/data/use-security-details-query'
import SelectSecurityModal from '../select-security-modal'
import { CurrencyAdorment } from './shared/fields/adorments'
import DateSelector from './shared/fields/date-selector'
import { ProfileOperator } from './shared/profile-operator'
import { ProfileRow } from './shared/profile-row'

type CashflowProfileProps = {
  leg: Leg
  currencySymbol: string
  cashflowTypes: TradeTicketResponse['cashflow_types']
  tradeTicket: TradeTicketResponse | null
  onUpdateLeg: (leg: Leg) => void
  onTradeDirectionChange: (tradeDirection: TradeDirection) => void
}

export function CashflowProfile(props: CashflowProfileProps) {
  const { leg, currencySymbol, cashflowTypes, tradeTicket, onUpdateLeg, onTradeDirectionChange } = props

  const { t } = useTranslation('tradeTicket')

  const securityModal = useOpenState()

  const assetRef = leg.relatedAsset
  const securityDetailsQuery = useSecurityDetailsQuery(assetRef)
  const securityDetails = securityDetailsQuery.data?.data || null
  const securityName = securityDetails?.asset_desc || ''

  function handleSaveSecurity(legSecurity: Leg['security']) {
    onUpdateLeg({ ...leg, relatedAsset: legSecurity.assetRef })
    securityModal.close()
  }

  return (
    <Stack gap={3}>
      <ProfileRow>
        <TradeDirectionField
          value={leg.tradeDirection}
          buyLabel="CR"
          sellLabel="DR"
          onChange={onTradeDirectionChange}
          sx={{ maxWidth: '150px', mr: 2 }}
        />

        <FormControl required>
          <InputLabel shrink>{t('common:cashflow_type')}</InputLabel>
          <Select
            notched
            displayEmpty
            name="cashflow_type"
            label={t('common:cashflow_type')}
            value={leg.cashflowType}
            onChange={(event) => onUpdateLeg({ ...leg, cashflowType: event.target.value })}
          >
            {cashflowTypes.map((cashflowType) => (
              <MenuItem key={cashflowType.tag} value={cashflowType.tag}>
                {cashflowType.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <ProfileOperator />
        <NumericField
          required
          fixedLabel
          name="amount"
          label={t('common:amount')}
          value={leg.quantity}
          decimalPlaces={defaultInputDecimalPlaces}
          onValueChange={(quantity) => onUpdateLeg({ ...leg, quantity })}
          endAdornment={<CurrencyAdorment symbol={currencySymbol} />}
        />
      </ProfileRow>
      <ProfileRow>
        <Box sx={{ maxWidth: '150px', mr: 2 }} />

        <TextField
          required
          label={t('related_asset')}
          value={securityName}
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            autoComplete: 'off',
            endAdornment: (
              <InputAdornment position="end">
                <IconButton edge="end" onClick={securityModal.open}>
                  <Edit />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <ProfileOperator />
        <DateSelector
          required
          fixedLabel
          label={t('common:settlement_date')}
          value={leg.settlementDate}
          onChange={(settlementDate) => onUpdateLeg({ ...leg, settlementDate })}
        />
      </ProfileRow>

      <SelectSecurityModal
        open={securityModal.isOpen}
        onlyExistingAssets
        tradeTicket={tradeTicket}
        onClose={securityModal.close}
        onSave={handleSaveSecurity}
      />
    </Stack>
  )
}
