import { Checkbox, Dialog, FormControlLabel } from '@mui/material'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import NumericField from '../../../components/fields/numeric-field'
import ModalActions from '../../../components/modal-actions'
import ModalContent from '../../../components/modal-content'
import ModalTitle from '../../../components/modal-title'
import { extractDatapointFormatAndValue } from '../../../services/data/datapoint-formatting'
import { GridDataModellingOption } from '../../../services/data/types/grid-data'
import { defaultInputDecimalPlaces } from '../../../utils/numbers'

type ModellingOptionsModalProps = {
  open: boolean
  options: GridDataModellingOption[]
  onChange: (options: GridDataModellingOption[]) => void
  onClose: () => void
}

function ModellingOptionsModal(props: ModellingOptionsModalProps) {
  const { open, options, onChange, onClose } = props
  const { t } = useTranslation()

  const [modellingOptions, setModellingOptions] = useState<GridDataModellingOption[]>([])
  const floatOptions = modellingOptions.filter((option) => option.default.hasOwnProperty('Float'))
  const booleanOptions = modellingOptions.filter((option) => option.default.hasOwnProperty('Boolean'))

  useEffect(() => {
    setModellingOptions(options || [])
  }, [options])

  function handleFloatOptionChange(optionId: number, value: string) {
    setModellingOptions((options) => {
      const newOptions: GridDataModellingOption[] = options.map((option) => {
        if (option.option_id === optionId) {
          return { ...option, default: { Float: Number(value) } }
        }

        return option
      })

      return newOptions
    })
  }

  function handleBooleanOptionChange(optionId: number, checked: boolean) {
    setModellingOptions((options) => {
      const newOptions: GridDataModellingOption[] = options.map((option) => {
        if (option.option_id === optionId) {
          return { ...option, default: { Boolean: checked } }
        }

        return option
      })

      return newOptions
    })
  }

  function handleSave() {
    onChange(modellingOptions)
  }

  function handleClose() {
    setModellingOptions(options)
    onClose()
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <ModalTitle title={t('dataTable:modelling_options')} onClose={handleClose} />
      <ModalContent>
        {floatOptions.map((option) => (
          <NumericField
            key={option.option_id}
            label={option.text}
            value={getNumberValue(option.default)}
            decimalPlaces={defaultInputDecimalPlaces}
            onValueChange={(value) => handleFloatOptionChange(option.option_id, value)}
          />
        ))}
        {booleanOptions.map((option) => (
          <FormControlLabel
            key={option.option_id}
            label={option.text}
            checked={getBooleanValue(option.default)}
            control={<Checkbox />}
            onChange={(_event, checked) => handleBooleanOptionChange(option.option_id, checked)}
          />
        ))}
      </ModalContent>
      <ModalActions confirmLabel={t('common:save')} onCancel={handleClose} onConfirm={handleSave} />
    </Dialog>
  )
}

export default ModellingOptionsModal

function getNumberValue(datapoint: GridDataModellingOption['default']) {
  const [_, value] = extractDatapointFormatAndValue(datapoint)
  if (typeof value === 'string') {
    return value
  }
  if (typeof value === 'number') {
    return String(value)
  }
  return ''
}

function getBooleanValue(datapoint: GridDataModellingOption['default']) {
  const [_, value] = extractDatapointFormatAndValue(datapoint)
  if (typeof value === 'boolean') {
    return value
  }
  return false
}
