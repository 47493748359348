import { Dialog, FormControl } from '@mui/material'
import { FormEvent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { defaultFormatDecimalPlaces } from '../../utils/numbers'
import NumericField from '../fields/numeric-field'
import ModalActions from '../modal-actions'
import ModalContent from '../modal-content'
import ModalTitle from '../modal-title'

type ColumnHeaderEditDecimalsModalProps = {
  open: boolean
  decimalPlaces: number | undefined
  onSave: (decimalPlaces: number | undefined) => void
  onClose: () => void
}

function ColumnHeaderEditDecimalsModal(props: ColumnHeaderEditDecimalsModalProps) {
  const { open, decimalPlaces, onSave, onClose } = props

  const { t } = useTranslation('dataTable')

  const [decimals, setDecimals] = useState('')

  useEffect(() => {
    if (open) {
      setDecimals(String(decimalPlaces ?? ''))
    } else {
      setDecimals('')
    }
  }, [open, decimalPlaces])

  function handleSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault()

    if (decimals) {
      const decimalPlaces = Number(decimals)
      if (decimalPlaces >= 0) {
        onSave(decimalPlaces)
      } else {
        // prevent users from saving something like -1
        onSave(0)
      }
    } else {
      onSave(undefined)
    }

    onClose()
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      // closeAfterTransition needed because:
      // https://github.com/mui/material-ui/issues/43106
      closeAfterTransition={false}
    >
      <form onSubmit={handleSubmit}>
        <ModalTitle title={t('header_modal.edit_decimals_title')} onClose={onClose} />
        <ModalContent>
          <FormControl>
            <NumericField
              autoFocus
              fixedLabel
              name="decimalPlaces"
              label={t('header_modal.decimal_places')}
              value={decimals}
              onValueChange={setDecimals}
              placeholder={String(defaultFormatDecimalPlaces)}
              decimalPlaces={0}
            />
          </FormControl>
        </ModalContent>
        <ModalActions confirmLabel={t('common:save')} onCancel={onClose} />
      </form>
    </Dialog>
  )
}

export default ColumnHeaderEditDecimalsModal
