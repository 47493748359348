/** Default decimal places used when formatting numbers */
export const defaultFormatDecimalPlaces = 2

/** Default decimal places used for Float and Percent inputs */
export const defaultInputDecimalPlaces = 6

/** Decimal places used for pricing inputs */
export const priceInputDecimalPlaces = 9

export function roundDecimals(n: number, decimalPlaces: number = defaultInputDecimalPlaces) {
  return Number(n.toFixed(decimalPlaces))
}

export function isValidNumber(value: unknown): boolean {
  if (typeof value !== 'string') {
    return false
  }
  return !isNaN(Number(value))
}

export function isSafeNumber(number: number) {
  const isBiggerOrEqualThanMinSafeInt = number >= Number.MIN_SAFE_INTEGER
  const isSmallerOrEqualThanMaxSafeInt = number <= Number.MAX_SAFE_INTEGER

  return isBiggerOrEqualThanMinSafeInt && isSmallerOrEqualThanMaxSafeInt
}

const intFormatter = new Intl.NumberFormat('en-GB', {
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
})

const floatFormatter = new Intl.NumberFormat('en-GB', {
  minimumFractionDigits: defaultFormatDecimalPlaces,
  maximumFractionDigits: defaultFormatDecimalPlaces,
})

const percentFormatter = new Intl.NumberFormat('en-GB', {
  style: 'percent',
  minimumFractionDigits: defaultFormatDecimalPlaces,
  maximumFractionDigits: defaultFormatDecimalPlaces,
})

export function formatInt(value: unknown) {
  if (typeof value === 'number') {
    return intFormatter.format(value)
  }
  return ''
}

export function formatFloat(value: unknown, decimalPlaces?: number) {
  if (typeof value !== 'number') {
    return ''
  }

  if (decimalPlaces !== undefined) {
    const formatter = new Intl.NumberFormat('en-GB', {
      minimumFractionDigits: decimalPlaces,
      maximumFractionDigits: decimalPlaces,
    })
    return formatter.format(value)
  }

  return floatFormatter.format(value)
}

export function formatPercent(value: unknown, decimalPlaces?: number) {
  if (typeof value !== 'number') {
    return ''
  }

  // `value + 0` is used to avoid -0%, so we always get 0%
  const localValue = value + 0

  if (decimalPlaces !== undefined) {
    const formatter = new Intl.NumberFormat('en-GB', {
      style: 'percent',
      minimumFractionDigits: decimalPlaces,
      maximumFractionDigits: decimalPlaces,
    })
    return formatter.format(localValue)
  }

  return percentFormatter.format(localValue)
}
