import { Lock } from '@mui/icons-material'
import { Stack } from '@mui/material'
import { StackBaseProps } from '@mui/system'
import { MouseEvent } from 'react'
import { extractDatapointFormatAndValue, formatDatapointValue } from '../../services/data/datapoint-formatting'
import { DataDocType } from '../../services/data/types/asset-static-data'
import { DatapointType, DatapointValue } from '../../services/data/types/datapoint'
import { CellExplainer, GridAlert, GridAlertLevel } from '../../services/data/types/grid-data'
import AlertIcon, { AlertSeverity } from '../alert-icon'
import DatapointContent from './datapoint-content'
import { DatapointExplainer } from './datapoint-explainer'

type DatapointCellProps = {
  displayAs?: string | null
  datadocType?: DataDocType
  datapoint?: DatapointValue
  alert?: GridAlert | null
  explainer?: CellExplainer | null
  isIncomplete?: boolean
  isLocked?: boolean
  decimalPlaces?: number
  onContextMenuOpen?: (event: MouseEvent) => void
}

function DatapointCell(props: DatapointCellProps) {
  const {
    displayAs,
    datadocType,
    datapoint,
    alert,
    explainer,
    isIncomplete,
    isLocked,
    decimalPlaces,
    onContextMenuOpen,
  } = props

  const [format, value] = extractDatapointFormatAndValue(datapoint)

  if (format === 'Error') {
    return (
      <AlertIcon
        severity="basic"
        tooltipPlacement="left"
        tooltipOffset={[0, -20]}
        message={formatDatapointValue(format, value)}
      />
    )
  }

  return (
    <Stack
      onContextMenu={onContextMenuOpen}
      direction="row"
      sx={{
        flex: 1,
        gap: 2,
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <Stack
        direction={getCellDirection(format)}
        sx={{
          flex: 1,
          gap: 1,
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <DatapointContent
          format={format}
          value={value}
          displayAs={displayAs}
          datadocType={datadocType}
          isIncomplete={isIncomplete}
          decimalPlaces={decimalPlaces}
        />

        {alert && <AlertIcon severity={getAlertIconSeverity(alert.level)} message={alert.message} />}

        {explainer && <DatapointExplainer explainer={explainer} />}
      </Stack>
      {isLocked && <Lock sx={{ fontSize: 16, color: 'gray.300' }} />}
    </Stack>
  )
}

export default DatapointCell

function getAlertIconSeverity(level: GridAlertLevel): AlertSeverity {
  const levelMap: { [key in GridAlertLevel]: AlertSeverity } = {
    breach: 'error',
    warning: 'warning',
  }

  return levelMap[level] || 'info'
}

function getCellDirection(datapointType: DatapointType | null): StackBaseProps['direction'] {
  if (datapointType === 'Int' || datapointType === 'Float' || datapointType === 'Percent') {
    return 'row-reverse'
  }
  if (datapointType === 'DataDoc') {
    return 'column'
  }
  return 'row'
}
