import { Paper, TableContainer, Typography } from '@mui/material'
import { Box, Stack } from '@mui/system'
import { ColDef, ICellRendererParams } from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'
import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ReloadButton } from '../../../components/buttons/reload-button'
import AppLayout from '../../../components/layouts/app-layout'
import PageHeaderContainer from '../../../components/page-header-container'
import { formatDatapoint } from '../../../services/data/datapoint-formatting'
import { AssetCategory, DataDocType } from '../../../services/data/types/asset-static-data'
import { DatapointType, DatapointValue } from '../../../services/data/types/datapoint'
import { DatapointHistory } from '../../../services/data/types/datapoint-history'
import { DateTimeIso } from '../../../utils/dates'
import useTradeTicketQuery from '../../trade-ticket/data/use-trade-ticket-query'
import useDatapointHistoryQuery from '../data/use-datapoint-history-query'
import { DatapointCell } from './asset-static-grid'
import DatapointDetailsContextMenu from './datapoint-details-context-menu'
import EditAssetStaticModals from './edit-asset-static-modals'

type DatapointDetailsProps = {
  assetRef: string
  datapointRef: string
  datasetRef: string
  decimalPlaces: number | undefined
}

type AgCellData = {
  cellValue: string
  value: DatapointValue
  displayAs?: string
  asOnDate?: Date
  shadowedValue?: DatapointValue
  shadowedDisplayAs?: string
  modifiedAt?: DateTimeIso
  modifiedBy?: string
  modifiedByDisplay?: string
  assetRef: string
  assetDesc: string
  assetCategory: AssetCategory | null
  datapointRef: string
  datapointName: string
  datapointType: DatapointType
  datadocType: DataDocType | null
  classificationId: number | null
  datasetRef: string
  sourceDatasetName: string
  shadowedDatasetRef: string | null
  shadowedDatasetName: string | null
  canEdit: boolean
}

type AgCellValue = AgCellData['asOnDate'] | AgCellData['value']

type ContextMenuState = {
  coordinates: { mouseX: number; mouseY: number }
}

function DatapointDetails(props: DatapointDetailsProps) {
  const { assetRef, datapointRef, datasetRef, decimalPlaces } = props

  const { t } = useTranslation('dataEngine')

  const gridRef = useRef<AgGridReact>(null)

  const [activeDatapointCell, setActiveDatapointCell] = useState<AgCellData>()
  const [contextMenu, setContextMenu] = useState<ContextMenuState | null>(null)

  const datapointHistoryQuery = useDatapointHistoryQuery(assetRef, datapointRef, datasetRef)
  const data = datapointHistoryQuery.data?.data

  // todo: replace with useAssetsSummaryQuery
  const tradeTicketResponse = useTradeTicketQuery({ enabled: true })
  const tradeTicket = tradeTicketResponse.data?.data || null
  const currencies = tradeTicket?.currencies || {}
  const indexes = tradeTicket?.indexes || {}
  const refRates = tradeTicket?.ref_rates || {}
  const regularCurves = tradeTicket?.regular_curves || {}

  const isLoading = datapointHistoryQuery.isFetching

  const cols: ColDef[] = [
    {
      field: 'asOnDate',
      headerName: t('datapoint_details.date'),
      flex: 1,
      minWidth: 110,
      maxWidth: 110,
    },
    {
      field: 'cellValue', // used for copying
      headerName: t('datapoint_details.value'),
      flex: 1,
    },
  ]

  const rows: AgCellData[] | null = parseEntriesIntoRows(data)

  function handleEditAssetStaticOpen(datapointCell: AgCellData) {
    setActiveDatapointCell(datapointCell)
  }

  function handleResetActiveDatapoint() {
    setActiveDatapointCell(undefined)
  }

  return (
    <AppLayout>
      <PageHeaderContainer>
        <Typography variant="h6" noWrap>
          {t('datapoint_details.title')}
        </Typography>
        <ReloadButton loading={isLoading} onClick={datapointHistoryQuery.refetch} sx={{ mr: 'auto' }} />
      </PageHeaderContainer>

      <Box p={2} height="100%">
        {!!data && (
          <TableContainer
            component={Paper}
            sx={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              '&.MuiPaper-root': {
                padding: '0',
              },
            }}
          >
            <Stack p={2}>
              <Typography variant="subtitle2" fontSize="18px" fontWeight="500">
                {data.datapoint_name}
              </Typography>
              <div>
                <Typography display="inline" fontSize="12px" fontWeight="500">
                  {`${t('datapoint_details.asset')}: `}
                </Typography>
                <Typography display="inline" fontSize="12px" color="gray.700">
                  {data.asset_desc}
                </Typography>
              </div>
              <div>
                <Typography display="inline" fontSize="12px" fontWeight="500">
                  {`${t('datapoint_details.dataset')}: `}
                </Typography>
                <Typography display="inline" fontSize="12px" color="gray.700">
                  {data.dataset_name}
                </Typography>
              </div>
              {data.shadowed_dataset_name && (
                <div>
                  <Typography display="inline" fontSize="12px" fontWeight="500">
                    {`${t('datapoint_details.source')}: `}
                  </Typography>
                  <Typography display="inline" fontSize="12px" color="gray.700">
                    {data.shadowed_dataset_name}
                  </Typography>
                </div>
              )}
            </Stack>

            <div style={{ flexGrow: 1 }}>
              <AgGridReact
                ref={gridRef}
                className="ag-theme-alpine-dark"
                columnDefs={cols}
                rowData={rows}
                rowHeight={32}
                rowSelection="single"
                animateRows
                enableRangeSelection
                suppressRowHoverHighlight
                getContextMenuItems={() => {
                  // disable context menu by giving it no items,
                  // as setting suppressContextMenu prevents row selection on right click
                  return []
                }}
                defaultColDef={{
                  resizable: false,
                  suppressMovable: true,
                  suppressMenu: true,
                  valueParser: () => {
                    // used when editing cell, but we don't need it as we have our own component
                    // but there was a warning on the console when not provided
                    // https://www.ag-grid.com/react-data-grid/value-parsers/#value-parser
                  },
                  cellRenderer: (props: ICellRendererParams<AgCellData, AgCellValue>) => {
                    const data = props.data
                    const value = props.value

                    if (!value || !data) {
                      return <div style={{ height: '100%' }}>-</div>
                    }

                    if (props.colDef?.field === 'asOnDate') {
                      return <div style={{ height: '100%' }}>{String(value)}</div>
                    }

                    return (
                      <div
                        style={{ height: '100%' }}
                        onContextMenu={(event) => {
                          event.preventDefault()
                          setContextMenu({
                            coordinates: {
                              mouseX: event.clientX,
                              mouseY: event.clientY,
                            },
                          })
                        }}
                      >
                        <DatapointCell
                          value={data.value}
                          displayAs={data.displayAs}
                          asOnDate={data.asOnDate}
                          shadowedValue={data.shadowedValue}
                          shadowedDisplayAs={data.shadowedDisplayAs}
                          modifiedAt={data.modifiedAt}
                          modifiedBy={data.modifiedBy}
                          modifiedByDisplay={data.modifiedByDisplay}
                          datapointName={data.datapointName}
                          datasetName={data.sourceDatasetName}
                          canEdit={data.canEdit}
                          datadocType={data.datadocType}
                          hideAsOnDate
                          decimalPlaces={decimalPlaces}
                          onEdit={() => handleEditAssetStaticOpen(data)}
                        />
                      </div>
                    )
                  },
                }}
              />
            </div>
          </TableContainer>
        )}
      </Box>

      <DatapointDetailsContextMenu
        coordinates={contextMenu?.coordinates}
        onClose={() => setContextMenu(null)}
        onCopy={() => gridRef.current?.api.copySelectedRangeToClipboard()}
      />

      {activeDatapointCell && (
        <EditAssetStaticModals
          isAssetActive={!!activeDatapointCell}
          assetRef={activeDatapointCell.assetRef}
          assetCategory={activeDatapointCell.assetCategory}
          assetDescription={activeDatapointCell.assetDesc}
          datapointRef={activeDatapointCell.datapointRef}
          datapointName={activeDatapointCell.datapointName}
          datapointValue={activeDatapointCell.value}
          datapointType={activeDatapointCell.datapointType}
          datadocType={activeDatapointCell.datadocType}
          classificationId={activeDatapointCell.classificationId}
          datasetRef={activeDatapointCell.datasetRef}
          asOnDate={activeDatapointCell.asOnDate}
          modifiedAt={activeDatapointCell.modifiedAt}
          modifiedBy={activeDatapointCell.modifiedByDisplay}
          currencies={currencies}
          indexes={indexes}
          refRates={refRates}
          regularCurves={regularCurves}
          tradeTicket={tradeTicket}
          resetActiveAsset={handleResetActiveDatapoint}
        />
      )}
    </AppLayout>
  )
}

export default DatapointDetails

function parseEntriesIntoRows(data?: DatapointHistory): AgCellData[] | null {
  if (!data) {
    return null
  }

  return data.entries.map((entry) => {
    const cellData: AgCellData = {
      cellValue: entry.display_as || formatDatapoint(entry.value),
      value: entry.value,
      displayAs: entry.display_as,
      asOnDate: entry.as_on_date,
      shadowedValue: entry.shadowed_value,
      shadowedDisplayAs: entry.shadowed_display_as,
      modifiedAt: entry.modified_at,
      modifiedBy: entry.modified_by,
      modifiedByDisplay: entry.modified_by_display,
      assetRef: data.asset_ref,
      assetDesc: data.asset_desc,
      assetCategory: data.asset_category,
      datapointRef: data.datapoint_ref,
      datapointName: data.datapoint_name,
      datapointType: data.datapoint_type,
      datadocType: data.datadoc_type,
      classificationId: data.classification_id,
      datasetRef: data.dataset_ref,
      sourceDatasetName: data.dataset_name,
      shadowedDatasetRef: data.shadowed_dataset_ref,
      shadowedDatasetName: data.shadowed_dataset_name,
      canEdit: data.can_edit,
    }
    return cellData
  })
}
