import { Dialog } from '@mui/material'
import { FormEvent, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ModalActions from '../../../components/modal-actions'
import ModalContent from '../../../components/modal-content'
import ModalTitle from '../../../components/modal-title'
import { queryClient } from '../../../services/data/react-query'
import { UserInTenant } from '../../../services/data/types/user'
import useEditUserMutation from '../data/use-edit-user-mutation'
import UserForm from './user-form'

type EditUserModalProps = {
  user: UserInTenant
  open: boolean
  onClose: () => void
}

function EditUserModal(props: EditUserModalProps) {
  const { user, open, onClose } = props
  const { t } = useTranslation('settings')

  const editUserMutation = useEditUserMutation()

  const initialFormValues = useMemo(() => {
    return {
      username: user.username,
      firstname: user.firstname,
      lastname: user.lastname,
      email: user.email,
      timezone: user.timezone,
    }
  }, [user])

  const [formValues, setFormValues] = useState(initialFormValues)

  const isFormDirty = Object.entries(formValues).some(([key, value]) => {
    const fieldKey = key as keyof typeof initialFormValues
    return initialFormValues[fieldKey] !== value
  })

  function handleChange(name: string, value: string) {
    setFormValues({ ...formValues, [name]: value })
  }

  function handleSubmit(event: FormEvent) {
    event.preventDefault()

    const editUserProps = {
      userRef: user.user_ref,
      body: formValues,
    }

    editUserMutation.mutate(editUserProps, {
      onSuccess: () => {
        queryClient.invalidateQueries(['tenant', 'current', 'user'])
        queryClient.invalidateQueries(['user', user.user_ref])
        queryClient.invalidateQueries(['tenant', 'users'])
        queryClient.invalidateQueries(['userInfo'])
        handleClose()
      },
    })
  }

  function handleClose() {
    setFormValues(initialFormValues)
    editUserMutation.reset()
    onClose()
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      // closeAfterTransition needed because:
      // https://github.com/mui/material-ui/issues/43106
      closeAfterTransition={false}
    >
      <form onSubmit={handleSubmit}>
        <ModalTitle title={t('user_form.edit_user')} onClose={handleClose} />
        <ModalContent>
          <UserForm type="edit" values={formValues} error={editUserMutation.error} onChange={handleChange} />
        </ModalContent>
        <ModalActions confirmLabel={t('common:save')} confirmDisabled={!isFormDirty} onCancel={handleClose} />
      </form>
    </Dialog>
  )
}

export default EditUserModal
