import { AssetCategory, DataDocType } from './asset-static-data'

export type Datapoint = {
  datapoint_ref: string
  datapoint_name: string
  variety: Variety
  data_type: DatapointType
  asset_category: AssetCategory | null
  datadoc_type: DataDocType | null
  aggregation_type: AggregationType | null
  primary_dataset_ref: string
  primary_dataset_name: string
  calc_name: string | null
  calc_input_map: CalculatedInputMap[] | null
  classi_values: string[] | null
  classi_static_name: string | null
  weight_datapoint_name: string | null
  active_alias_tag: string | null
}

// todo: review this type
export type DatapointDetails = {
  datapoint_ref: string
  datapoint_name: string
  variety: Variety
  data_type: DatapointType
  asset_category: AssetCategory | null
  datadoc_type: DataDocType | null
  candidate_alias_id: number | null
  classification_id: number | null
  calc_id: number | null
  parent_class_id: number | null
  aggregation: {
    agg_type: AggregationType
    onlyall: boolean
    weight_dpref: string | null
    custom_fn_id: number | null
  } | null
  calc_inputs: {
    [key: string]: string
  } | null
}

// UamDatapointType on the backend
export type DatapointType = (typeof datapointTypes)[number]

export const datapointTypes = [
  'Int',
  'Float',
  'Percent',
  'Boolean',
  'String',
  'DateTime',
  'NaiveDate',
  'Classification',
  'MultiSelect',
  'DataDoc',
  'AssetRef',
  'UserRef',
  'LEI',
  'Error',
] as const

export const newDatapointTypes: DatapointType[] = [
  'Int',
  'Float',
  'Percent',
  'Boolean',
  'String',
  'DateTime',
  'NaiveDate',
  'Classification',
  'MultiSelect',
  'DataDoc',
  'AssetRef',
] as const

/** Returns when a given datapoint type is a numeric type */
export function isNumbericType(datapointType: DatapointType): boolean {
  return datapointType === 'Int' || datapointType === 'Float' || datapointType === 'Percent'
}

/** Returns when a given datapoint type contains decimal places */
export function typeHasDecimalPlaces(datapointType: DatapointType): boolean {
  return datapointType === 'Float' || datapointType === 'Percent'
}

export type DatapointValue =
  | { Int: number }
  | { Float: number }
  | { Percent: number }
  | { Boolean: boolean }
  | { String: string }
  | { DateTime: string }
  | { NaiveDate: string }
  | { Classification: string }
  | { MultiSelect: string }
  | { AssetRef: string }
  | { UserRef: string }
  | { LEI: string }
  | { DataDoc: string }
  | {
      Error: {
        code: number
        msg: string
      }
    }
  // `"value": "None"` is sent back when a value is empty
  | 'None'

// `"value": { "None": null }` needs to be used to remove a value from the datapoint
export type EditDatapointValue = DatapointValue | { None: null }

export function isEditDatapointValue(v: DatapointValue | EditDatapointValue): v is EditDatapointValue {
  return Object.keys(v).includes('None')
}

export function isDatapointValue(v: DatapointValue | EditDatapointValue): v is DatapointValue {
  return !isEditDatapointValue(v)
}

export type DatapointValueType = string | number | boolean | { code: number; msg: string } | null

// todo: make this standalone
export type DatapointPayload = Omit<DatapointDetails, 'datapoint_ref'>

type CalculatedInputMap = {
  input_label: string
  datapoint_name: string
}

export const varietyOptions = ['asset_static', 'class_static', 'calculated', 'internal', 'realtime'] as const

export type Variety = (typeof varietyOptions)[number]

export const aggregationOptions = ['sum', 'average', 'wavg', 'min', 'max', 'unique', 'custom'] as const

export type AggregationType = (typeof aggregationOptions)[number]
