import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Stack, TextField } from '@mui/material'
import { ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'
import Alert from '../../../components/alert'

type UserFormProps = {
  type: 'add' | 'edit'
  values: UserFormvValues
  error?: string | null
  onChange: (name: string, value: string) => void
}

type UserFormvValues = {
  username: string
  password?: string
  firstname: string
  lastname: string
  email: string
  timezone: string
}

const timezones = {
  'Europe/London': 'Europe/London (GMT)',
  'Europe/Budapest': 'Europe/Budapest (CET +01:00)',
}

function UserForm(props: UserFormProps) {
  const { type, values, error, onChange } = props
  const { t } = useTranslation('settings')

  const isEditForm = type === 'edit'

  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    const { name, value } = event.target
    onChange(name, value)
  }

  function handleSelectChange(event: SelectChangeEvent) {
    const { name, value } = event.target
    onChange(name, value)
  }

  return (
    <>
      <Alert severity="error" message={error} />
      <TextField
        required
        name="username"
        label={t('user_form.username')}
        value={values.username}
        onChange={handleChange}
      />
      {!isEditForm && (
        <TextField
          required
          type="password"
          name="password"
          label={t('user_form.password')}
          value={values.password}
          onChange={handleChange}
          autoComplete="new-password"
        />
      )}
      <Stack direction="row" gap={2}>
        <TextField
          fullWidth
          required
          name="firstname"
          label={t('user_form.first_name')}
          value={values.firstname}
          onChange={handleChange}
        />
        <TextField
          fullWidth
          required
          name="lastname"
          label={t('user_form.last_name')}
          value={values.lastname}
          onChange={handleChange}
        />
      </Stack>
      <TextField required name="email" label={t('user_form.email')} value={values.email} onChange={handleChange} />
      <FormControl sx={{ flex: 1 }}>
        <InputLabel required>{t('user_form.timezone')}</InputLabel>
        <Select
          required
          displayEmpty
          name="timezone"
          label={t('user_form.timezone')}
          value={values.timezone}
          onChange={handleSelectChange}
        >
          {Object.entries(timezones).map(([value, label]) => (
            <MenuItem key={value} value={value}>
              {label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  )
}

export default UserForm
