import { Percent } from '@mui/icons-material'
import {
  Button,
  Dialog,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Alert as MuiAlert,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { DatePicker, DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { isValid, parse } from 'date-fns'
import { ChangeEvent, FormEvent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Alert from '../../../components/alert'
import NumericField from '../../../components/fields/numeric-field'
import ModalActions from '../../../components/modal-actions'
import ModalContent from '../../../components/modal-content'
import { ModalDetailRow } from '../../../components/modal-detail-row'
import ModalTitle from '../../../components/modal-title'
import { extractDatapointFormatAndValue, formatDatapointValue } from '../../../services/data/datapoint-formatting'
import { DataDocType } from '../../../services/data/types/asset-static-data'
import { DatapointType, DatapointValue } from '../../../services/data/types/datapoint'
import {
  dateFormat,
  datePlaceholder,
  datetimeFormat,
  DateTimeIso,
  datetimePlaceholder,
  datetimeWithSeconds,
  formatNaiveDate,
  parseNaiveDate,
} from '../../../utils/dates'
import { defaultInputDecimalPlaces } from '../../../utils/numbers'
import { ParsedAssetGridCell } from '../data/asset-static-parsing'
import useClassificationValues from '../data/use-classification-values'
import { EditAssetParams } from '../data/use-edit-asset-datapoint-mutation'
import useGenerateDatadocMutation from '../data/use-generate-datatoc-mutation'

type EditAssetStaticModalProps = {
  open: boolean
  error?: string | null
  assetRef?: string
  assetDescription: string
  datapointRef?: string
  datapointType?: DatapointType
  datapointName: string
  datasetRef: string | null
  datapointValue: ParsedAssetGridCell['value']
  classificationId?: number | null
  datadocType?: DataDocType | null
  canAutogenerate?: boolean
  asOnDate?: Date
  modifiedAt?: DateTimeIso
  modifiedBy?: string
  onSave: (params: EditAssetParams) => void
  onClose: () => void
}

type ValueChangeEvent = ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<any>

function EditAssetStaticModal(props: EditAssetStaticModalProps) {
  const {
    open,
    error,
    assetRef,
    assetDescription,
    datapointRef,
    datapointType,
    datapointName,
    datasetRef,
    datapointValue,
    classificationId,
    datadocType,
    canAutogenerate,
    asOnDate,
    modifiedAt,
    modifiedBy,
    onSave,
    onClose,
  } = props
  const { t } = useTranslation('dataEngine')

  const [value, setValue] = useState('')
  const [hasFieldError, setHasFieldError] = useState(true)
  const [syntaxError, setSyntaxError] = useState('')

  const classificationValuesQuery = useClassificationValues(classificationId)
  const classificationValues = classificationValuesQuery.data?.data

  const generateDatadocMutation = useGenerateDatadocMutation()
  const [generateDatadocError, setGenerateDatadocError] = useState('')

  const isTextType = datapointType === 'String'
  const isDateTimeType = datapointType === 'DateTime'
  const isNaiveDateType = datapointType === 'NaiveDate'
  const isDateType = isDateTimeType || isNaiveDateType
  const isBooleanType = datapointType === 'Boolean'
  const isPercentType = datapointType === 'Percent'
  const isIntType = datapointType === 'Int'
  const isNumberType = isIntType || datapointType === 'Float'
  const isSelectType = datapointType === 'Classification' || datapointType === 'MultiSelect'
  const isDataDocType = datapointType === 'DataDoc'
  const isDateSchedule = isDataDocType && datadocType === 'date_schedule'

  const submitDisabled = !datapointRef || !datapointType || !hasFieldError || !!syntaxError
  const errorMessage = error || classificationValuesQuery.error || generateDatadocMutation.error || generateDatadocError

  useEffect(() => {
    if (!open) {
      setValue('')
      setHasFieldError(true)
      setSyntaxError('')
      setGenerateDatadocError('')
      generateDatadocMutation.reset()
    }
  }, [open])

  useEffect(() => {
    const [_, value] = extractDatapointFormatAndValue(datapointValue)

    if (typeof value === 'string') {
      if (isDateSchedule) {
        setValue(formatDateSchedule(value))
      } else if (isDataDocType) {
        setValue(formatJson(value))
      } else if (isNaiveDateType) {
        const dt = parseNaiveDate(value)
        setValue(dt ? dt.toString() : '')
      } else {
        setValue(value)
      }
    } else if (typeof value === 'number') {
      const multiplier = isPercentType ? 100 : 1
      setValue(String(value * multiplier))
    } else if (typeof value === 'boolean') {
      setValue(String(value))
    } else {
      setValue('')
    }
  }, [datapointValue])

  function handleChange(event: ValueChangeEvent) {
    setValue(event.target.value)
  }

  function handleValueChange(newValue: string) {
    setValue(newValue)
  }

  function handleGenerateDatadoc() {
    if (!datasetRef || !assetRef || !datapointRef) {
      return
    }

    const params = { datasetRef, assetRef, datapointRef }

    generateDatadocMutation.mutate(params, {
      onSuccess: (data) => {
        const datadoc = data.data[0]
        if (!datadoc) {
          return
        }

        const [format, value] = extractDatapointFormatAndValue(datadoc.value)

        if (format === 'Error') {
          setGenerateDatadocError(formatDatapointValue(format, value))
        } else {
          setValue(formatDateSchedule(String(value)))
        }
      },
    })
  }

  function handleFormatJson() {
    try {
      setValue(formatJson(value))
      setSyntaxError('')
    } catch (error: any) {
      setSyntaxError(error.message)
    }
  }

  function handleOrderDates() {
    try {
      validateDatesValue(value)

      const dates = value
        .split('\n')
        .map((line) => line.trim())
        .filter((line) => !!line)
        .sort((a, b) => a.localeCompare(b))
        .join('\n')

      setValue(dates)
      setSyntaxError('')
    } catch (error: any) {
      setSyntaxError(error.message)
    }
  }

  function validateDatesValue(value: string) {
    value
      .split('\n')
      .map((line) => line.trim())
      .filter((line) => !!line)
      .forEach((line) => {
        const date = parse(line, 'yyyy-mm-dd', new Date())

        if (!isValid(date)) {
          throw new Error(t('invalid_date_message', { date: line }))
        }
      })
  }

  function handleSubmit(event: FormEvent) {
    event.preventDefault()

    if (submitDisabled) {
      return
    }

    const params: EditAssetParams = {
      assetRef: assetRef || '',
      datapointRef,
      // this will remove the value from the datapoint
      value: { None: null },
      asOnDate: asOnDate,
    }

    let datapointValue: string | number | boolean = value.trim()

    if (datapointValue) {
      if (isDateTimeType) {
        const date = new Date(datapointValue)
        date.setMilliseconds(0)

        datapointValue = date.toISOString()
      }

      if (isNaiveDateType) {
        datapointValue = formatNaiveDate(datapointValue)
      }

      if (isNumberType) {
        datapointValue = Number(datapointValue)
      }

      if (isPercentType) {
        datapointValue = Number(datapointValue) / 100
      }

      if (isBooleanType) {
        datapointValue = datapointValue === 'true'
      }

      if (isDateSchedule) {
        try {
          validateDatesValue(value)

          const dates = value
            .split('\n')
            .map((line) => line.trim())
            .filter((line) => !!line)
            .sort((a, b) => a.localeCompare(b))

          datapointValue = JSON.stringify(dates)
          setSyntaxError('')
        } catch (error: any) {
          setSyntaxError(error.message)
          return
        }
      }

      params.value = {
        [datapointType]: datapointValue,
      } as DatapointValue
    }

    onSave(params)
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <form onSubmit={handleSubmit}>
        <ModalTitle title={t('edit_asset_static_modal.title')} onClose={onClose} />
        <ModalContent>
          <Alert severity="error" message={errorMessage} />

          <Stack gap={2}>
            <ModalDetailRow label={t('edit_asset_static_modal.datapoint')} value={datapointName} />
            <ModalDetailRow label={t('edit_asset_static_modal.asset')} value={assetDescription} />
            {!!asOnDate && (
              <ModalDetailRow
                label={t('datapoint_details.as_on_date')}
                value={formatDatapointValue('NaiveDate', asOnDate)}
              />
            )}
            {!!modifiedAt && (
              <ModalDetailRow
                label={t('datapoint_details.modified_at')}
                value={datetimeWithSeconds(modifiedAt.toString())}
              />
            )}
            {!!modifiedBy && <ModalDetailRow label={t('datapoint_details.modified_by')} value={modifiedBy} />}
          </Stack>

          {isTextType && (
            <TextField autoFocus label={t('edit_asset_static_modal.value')} value={value} onChange={handleChange} />
          )}

          {isNumberType && (
            <NumericField
              autoFocus
              label={t('edit_asset_static_modal.value')}
              value={value}
              decimalPlaces={isIntType ? 0 : defaultInputDecimalPlaces}
              onValueChange={handleValueChange}
            />
          )}

          {isPercentType && (
            <NumericField
              autoFocus
              label={t('edit_asset_static_modal.percent')}
              value={value}
              decimalPlaces={defaultInputDecimalPlaces}
              endAdornment={<Percent fontSize="small" sx={{ ml: 1, color: 'gray.300' }} />}
              onValueChange={handleValueChange}
            />
          )}

          {isBooleanType && (
            <RadioGroup row value={value} onChange={(_, newValue) => handleValueChange(newValue)}>
              <FormControlLabel
                value="true"
                control={<Radio />}
                label={<Typography variant="body2">True</Typography>}
              />
              <FormControlLabel
                value="false"
                control={<Radio />}
                label={<Typography variant="body2">False</Typography>}
              />
            </RadioGroup>
          )}

          {isSelectType && (
            <FormControl required fullWidth>
              <InputLabel>{t('edit_asset_static_modal.value')}</InputLabel>
              <Select
                autoFocus
                label={t('edit_asset_static_modal.value')}
                value={value}
                onChange={handleChange}
                MenuProps={{ sx: { maxHeight: 300 } }}
              >
                {classificationValues?.map((classificationValue) => (
                  <MenuItem key={classificationValue.tag} value={classificationValue.tag}>
                    {classificationValue.description}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}

          {isDateType && (
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              {isNaiveDateType ? (
                <DatePicker
                  value={value || null}
                  inputFormat={dateFormat}
                  onChange={(value) => {
                    // for some reason value is a Date instead of string,
                    // so make sure setValue gets a string.
                    setValue(value ? value.toString() : '')
                  }}
                  onError={(error) => setHasFieldError(!error)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      autoFocus
                      fullWidth
                      autoComplete="off"
                      label={t('edit_asset_static_modal.date')}
                      inputProps={{
                        ...params.inputProps,
                        placeholder: datePlaceholder,
                      }}
                    />
                  )}
                />
              ) : (
                <DateTimePicker
                  value={value || null}
                  inputFormat={datetimeFormat}
                  onChange={(value) => {
                    // for some reason value is a Date instead of string,
                    // so make sure setValue gets a string.
                    setValue(value ? value.toString() : '')
                  }}
                  onError={(error) => setHasFieldError(!error)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      autoFocus
                      fullWidth
                      autoComplete="off"
                      label={t('edit_asset_static_modal.date')}
                      inputProps={{
                        ...params.inputProps,
                        placeholder: datetimePlaceholder,
                      }}
                    />
                  )}
                />
              )}
            </LocalizationProvider>
          )}

          {isDataDocType && (
            <>
              <TextField
                multiline
                autoFocus
                rows={15}
                name="datadoc"
                label={t('common:value')}
                helperText={isDateSchedule ? t('valid_date_schedule_message') : t('valid_json_message')}
                value={value}
                onChange={handleChange}
                sx={{
                  textarea: {
                    fontFamily: 'monospace',
                    whiteSpace: 'pre',
                  },
                  '.MuiInputBase-root': {
                    paddingRight: 1,
                    paddingBottom: 1,
                  },
                }}
              />
              <Stack direction="row">
                {canAutogenerate && (
                  <Button variant="text" onClick={handleGenerateDatadoc}>
                    {t('auto_generate')}
                  </Button>
                )}

                {isDateSchedule ? (
                  <Button variant="text" onClick={handleOrderDates} sx={{ ml: 'auto' }}>
                    {t('order_dates')}
                  </Button>
                ) : (
                  <Button variant="text" onClick={handleFormatJson} sx={{ ml: 'auto' }}>
                    {t('format_json')}
                  </Button>
                )}
              </Stack>

              {canAutogenerate && (
                <MuiAlert
                  severity="info"
                  variant="filled"
                  sx={{
                    pr: 4,
                    color: 'gray.700',
                    background: 'rgba(255, 255, 255, 0.08)',
                  }}
                >
                  {t('autogenerate_data_message')}
                </MuiAlert>
              )}

              <Alert severity="error" message={syntaxError} onClose={() => setSyntaxError('')} />
            </>
          )}
        </ModalContent>
        <ModalActions confirmLabel={t('common:update')} confirmDisabled={submitDisabled} onCancel={onClose} />
      </form>
    </Dialog>
  )
}

export default EditAssetStaticModal

function formatJson(value: string) {
  return JSON.stringify(JSON.parse(value), null, 2)
}

function formatDateSchedule(value: string) {
  return JSON.parse(value).join('\n')
}
