import { Add, RemoveCircleOutline } from '@mui/icons-material'
import {
  Avatar,
  Box,
  Chip,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material'
import { MouseEvent, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import ConfirmationModal from '../../../components/confirmation-modal'
import TableContainerHeader from '../../../components/table-container-header'
import { UserInTenant } from '../../../services/data/types/user'
import useOpenState from '../../../utils/hooks/use-open-state'
import useDeleteUserMutation from '../data/use-delete-user-mutation'
import useUsersQuery from '../data/use-users-query'
import AddUserModal from './add-user-modal'
import { useSettingsUpdateLoadingState } from './use-settings-set-loading'

type UsersProps = {
  onUserClick: (userRef: string) => void
}

function Users({ onUserClick }: UsersProps) {
  const { t } = useTranslation('settings')

  const removeUserModal = useOpenState()
  const addUserModal = useOpenState()

  const [selectedUserRef, setSelectedUserRef] = useState<string>()

  const { mutate: removeUser, error: deleteUserError, reset: deleteUserReset } = useDeleteUserMutation()

  const usersResponse = useUsersQuery()
  const users = usersResponse.data?.data
  const selectedUser = users?.find((user) => user.user_ref === selectedUserRef)
  const isLoading = usersResponse.isLoading

  useSettingsUpdateLoadingState(isLoading)

  function getUserRole(user: UserInTenant) {
    if (user.is_super_user) {
      return t('users_list_table.user_role.super_user')
    }

    if (user.is_tenant_admin) {
      return t('users_list_table.user_role.tenant_admin')
    }

    return t('users_list_table.user_role.user')
  }

  function handleAddUserClick() {
    addUserModal.open()
  }

  function handleConfirmationModalOpen(event: MouseEvent<HTMLButtonElement>, user: UserInTenant) {
    event.stopPropagation()
    removeUserModal.open()
    setSelectedUserRef(user.user_ref)
  }

  function handleRemoveUserModalClose() {
    deleteUserReset()
    removeUserModal.close()
  }

  async function handleRemoveUser() {
    if (selectedUser!.user_ref) {
      const userRef = selectedUser!.user_ref
      removeUser(
        { userRef },
        {
          onSuccess: () => {
            usersResponse.refetch()
            handleRemoveUserModalClose()
          },
        }
      )
    }
  }

  if (isLoading) {
    return null
  }

  return (
    <>
      <Box p={2}>
        <TableContainer component={Paper}>
          <TableContainerHeader
            title={t('users_list_table.title')}
            action={
              <Tooltip title={t('users_list_table.add_user')} arrow disableInteractive>
                <IconButton onClick={handleAddUserClick}>
                  <Add />
                </IconButton>
              </Tooltip>
            }
          />
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t('users_list_table.user')}</TableCell>
                <TableCell>{t('users_list_table.email')}</TableCell>
                <TableCell>{t('users_list_table.role')}</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {users?.map((user) => {
                const fullName = `${user.firstname} ${user.lastname}`

                return (
                  <TableRow
                    hover
                    key={user.user_ref}
                    onClick={() => onUserClick(user.user_ref)}
                    sx={{ cursor: 'pointer' }}
                  >
                    <TableCell>
                      <Chip
                        avatar={<Avatar>{fullName[0]}</Avatar>}
                        label={fullName}
                        sx={{
                          color: 'inherit',
                          cursor: 'inherit',
                          '& .MuiChip-avatar': {
                            color: 'gray.light',
                            backgroundColor: 'gray.400',
                          },
                        }}
                      />
                    </TableCell>
                    <TableCell>{user.email}</TableCell>
                    <TableCell>{getUserRole(user)}</TableCell>
                    <TableCell className="action-cell" align="right">
                      <Tooltip title={t('remove')} arrow disableInteractive>
                        <IconButton onClick={(event) => handleConfirmationModalOpen(event, user)}>
                          <RemoveCircleOutline />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <AddUserModal open={addUserModal.isOpen} onClose={addUserModal.close} />
      <ConfirmationModal
        error={deleteUserError}
        title={t('settings:remove_user')}
        confirmButtonText={t('common:remove')}
        open={removeUserModal.isOpen}
        onCloseButtonClick={handleRemoveUserModalClose}
        onConfirmButtonClick={handleRemoveUser}
      >
        <Typography variant="body1">
          <Trans
            t={t}
            i18nKey={'common:are_you_sure_to_remove'}
            values={{ subject: `${selectedUser?.firstname} ${selectedUser?.lastname}` || '' }}
            components={{ bold: <strong /> }}
          />
        </Typography>
      </ConfirmationModal>
    </>
  )
}

export default Users
