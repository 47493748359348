import { useQuery } from '@tanstack/react-query'
import api from '../../../services/data/api'

export function useListApiKeysQuery(userRef: string) {
  return useQuery({
    queryKey: ['users', userRef, 'api-keys'],
    queryFn: () => {
      return api.getApiKeys(userRef)
    },
  })
}
