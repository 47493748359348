export type UamPanelMode = SliceBy | TimeSeries | ScenarioAnalysis

export type SliceBy = {
  slice_by: string
}

export type TimeSeries = {
  time_series: {
    period_unit: TimePeriodUnit
    period_length: number
    use_calendar_periods: boolean
    number_of_periods: number
  }
}

export type TimePeriodUnit = 'day' | 'week' | 'month' | 'year'

export type ScenarioAnalysis = {
  scenario_analysis: Array<Scenario>
}

export type Scenario = {
  name: string | null
  fx_shock: number | null
  price_shock: number | null
  yield_shock: number | null

  fxfwd_vector_shock: VectorShock | null
  ndf_vector_shock: VectorShock | null

  is_yield_shock_relative?: boolean
}

export type VectorShock = {
  shock_type: VectorShockType
  point_shocks: VectorPointShocks
}

export type VectorShockType = 'absolute' | 'relative' | 'factor_shift'

export type VectorPointShocks = {
  [dateOrTenor: string]: number
}

export type VectorShockBy = 'date' | 'tenor'

// Should be 0, or number plus D, W, M, or Y (e.g. 0, 1D, 5Y)
const tenorRegex = new RegExp('^[1-9][0-9]*[DWMY]$')

export function isValidTenor(tenor: string): boolean {
  if (tenor === '0') {
    return true
  }
  return tenorRegex.test(tenor)
}
