import { useMutation } from '@tanstack/react-query'
import { AxiosError, AxiosResponse } from 'axios'
import api from '../../../services/data/api'
import parseError from '../../../services/data/parse-error'
import { EditUserBody } from '../../../services/data/types/user'

function useEditAccoutMutation() {
  const response = useMutation<AxiosResponse, AxiosError, EditUserBody>({
    mutationFn: api.editAccount,
  })

  return { ...response, error: parseError(response.error) }
}

export default useEditAccoutMutation
