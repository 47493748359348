import { useMutation } from '@tanstack/react-query'
import { AxiosError, AxiosResponse } from 'axios'
import api from '../../../services/data/api'
import parseError from '../../../services/data/parse-error'
import { EditUserBody } from '../../../services/data/types/user'

function useEditUserMutation() {
  const response = useMutation<AxiosResponse, AxiosError, EditUserParams>({
    mutationFn: ({ userRef, body }) => api.editUser(userRef, body),
  })

  return { ...response, error: parseError(response.error) }
}

type EditUserParams = {
  userRef: string
  body: EditUserBody
}

export default useEditUserMutation
