import { Dialog, Divider, Stack, Typography } from '@mui/material'
import { FormEvent, ReactNode, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Alert from '../../../components/alert'
import NumericField from '../../../components/fields/numeric-field'
import ModalActions from '../../../components/modal-actions'
import ModalContent from '../../../components/modal-content'
import { ModalDetailRow } from '../../../components/modal-detail-row'
import ModalTitle from '../../../components/modal-title'
import { ReconBankTxn, ReconMatch, ReconRecord, ReconTxn } from '../../../services/data/types/reconciliation'
import { formatDatetime } from '../../../utils/dates'
import { defaultInputDecimalPlaces, formatFloat } from '../../../utils/numbers'
import { SelectedTxns } from '../data/reconciliation-data'

type MatchModalProps = {
  open: boolean
  record: ReconRecord | null
  transactions: SelectedTxns
  matches: Array<ReconMatch>
  onSave: (match: ReconMatch) => void
  onClose: () => void
}

type TxnAmounts = {
  [extSegmentRef: string]: Match
}

type Match = {
  extSegmentRef: string
  amount: string
  portfolioRef: string
  bankTxnRef: string | null
  cpartyId: number | null
  description: string | null
}

function MatchModal(props: MatchModalProps) {
  const { open, record, transactions, matches, onSave, onClose } = props

  const { t } = useTranslation('bankAccounts')

  const formRef = useRef<HTMLFormElement>(null)
  const [amounts, setAmounts] = useState<TxnAmounts>({})

  const txns = useMemo(() => Object.values(transactions), [transactions])

  const recAmtToRecon = record?.amount_to_reconcile || 0
  const txnAmtToRecon = txns.reduce((acc, t) => acc + t.quantity_to_reconcile, 0)
  const isPossibleToMatch = recAmtToRecon < 0 ? recAmtToRecon >= txnAmtToRecon : recAmtToRecon <= txnAmtToRecon

  const error = !record || isPossibleToMatch ? '' : t('match_modal.not_possible_to_match')

  const total = Object.values(amounts).reduce((acc, item) => acc + (Number(item.amount) || 0), 0)
  let canMatch = record && total != 0 && record.amount_to_reconcile === total

  useEffect(() => {
    if (!open) {
      setAmounts({})
    } else if (record) {
      setAmounts(getInitialAmounts(record, txns, matches))
    }
  }, [open, record])

  function handleAmountUpdate(txn: ReconTxn, bankTxn: ReconBankTxn | null, value: string) {
    const extSegmentRef = bankTxn?.ext_segment_ref || txn.ext_segment_ref
    const match = createMatch(txn, bankTxn, value)
    setAmounts((prev) => ({ ...prev, [extSegmentRef]: match }))
  }

  function handleClose() {
    onClose()
  }

  function handleSubmit(event: FormEvent) {
    event.preventDefault()

    if (!canMatch || !record) {
      return
    }

    const reconMatch: ReconMatch = {
      record_id: record.record_id,
      txns: [],
    }

    Object.entries(amounts).forEach(([extSegmentRef, item]) => {
      const numberAmount = Number(item.amount)

      if (!!numberAmount) {
        reconMatch.txns.push({
          ext_segment_ref: extSegmentRef,
          amount: numberAmount,
          portfolio_ref: item.portfolioRef,
          bank_txn_ref: item.bankTxnRef,
          cparty_id: item.cpartyId,
          note: item.description,
        })
      }
    })

    onSave(reconMatch)
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      // closeAfterTransition needed because:
      // https://github.com/mui/material-ui/issues/43106
      closeAfterTransition={false}
    >
      <form ref={formRef} onSubmit={handleSubmit}>
        <ModalTitle title={t('match_modal.title')} onClose={handleClose} />
        <ModalContent>
          <Alert severity="error" message={error} />

          <Typography variant="subtitle1">{t('match_modal.bank_record')}</Typography>

          <ModalDetailRow label={t('match_modal.date')} value={record?.date} />
          <ModalDetailRow label={t('match_modal.description')} value={record?.description} />
          <ModalDetailRow label={t('match_modal.amount')} value={formatFloat(record?.amount)} />

          <Divider />

          <Typography variant="subtitle1">{t('match_modal.transactions')}</Typography>

          {txns.map((txn) => {
            const rows: ReactNode[] = []

            // consider trade's bank txns, but skip when root bank txn as they are the same
            if (!txn.is_bank_txn) {
              for (const bank_txn of txn.bank_txns) {
                if (bank_txn.is_reconciled) {
                  continue
                }

                const amountStr = amounts[bank_txn.ext_segment_ref]?.amount || ''
                const amountNum = Number(amountStr)
                let fieldError = ''
                if (amountNum !== 0 && amountNum !== bank_txn.amount) {
                  canMatch = false
                  fieldError = t('match_modal.existing_txn_need_to_fully_match')
                }

                const label = `${formatDatetime(bank_txn.txn_datetime)}  |  ${formatFloat(bank_txn.amount)}`

                rows.push(
                  <FormRow
                    key={bank_txn.ext_segment_ref}
                    label={
                      <div>
                        <div style={{ color: 'white' }}>{label}</div>
                        <div>{bank_txn.description}</div>
                      </div>
                    }
                  >
                    <NumericField
                      disabled
                      fixedLabel
                      size="small"
                      label={t('common:amount')}
                      value={amountStr}
                      error={fieldError}
                      decimalPlaces={defaultInputDecimalPlaces}
                      onValueChange={(value) => handleAmountUpdate(txn, bank_txn, value)}
                    />
                  </FormRow>
                )
              }
            }

            let bankTxn: ReconBankTxn | null
            if (txn.is_bank_txn) {
              // when root txn is bank txn, a single bank txn is expected
              bankTxn = txn.bank_txns[0] || null
            }

            const label = `${txn.settlement_date}  |  ${formatFloat(txn.quantity)}`

            rows.push(
              <FormRow
                key={txn.ext_segment_ref}
                label={
                  <div>
                    <div style={{ color: 'white' }}>{label}</div>
                    <div>{txn.description}</div>
                  </div>
                }
              >
                <NumericField
                  disabled={txn.is_bank_txn}
                  fixedLabel
                  size="small"
                  label={t('common:amount')}
                  value={amounts[txn.ext_segment_ref]?.amount || ''}
                  decimalPlaces={defaultInputDecimalPlaces}
                  onValueChange={(value) => handleAmountUpdate(txn, bankTxn, value)}
                />
              </FormRow>
            )

            return rows
          })}

          <ModalDetailRow
            label={t('match_modal.total')}
            value={`${formatFloat(total)} / ${formatFloat(record?.amount_to_reconcile)}`}
            valueColor={canMatch ? 'success.main' : 'error'}
          />
        </ModalContent>

        <ModalActions
          confirmLabel={t('match_modal.confirm_match')}
          confirmDisabled={!canMatch}
          onCancel={handleClose}
        />
      </form>
    </Dialog>
  )
}

export default MatchModal

type FormRowProps = {
  label: string | ReactNode
  children: ReactNode
}

export function FormRow(props: FormRowProps) {
  const { label, children } = props

  return (
    <Stack gap={1}>
      <Typography variant="caption" fontSize="small" color="gray.700" whiteSpace="pre-wrap">
        {label}
      </Typography>

      {children}
    </Stack>
  )
}

function getInitialAmounts(record: ReconRecord, txns: ReconTxn[], matches: ReconMatch[]) {
  const initialAmounts: TxnAmounts = {}

  let recordAmtToReconcile = record.amount_to_reconcile

  for (const txn of txns) {
    if (txn.is_bank_txn) {
      const bankTxn = txn.bank_txns[0] || null
      const amount = getAmount(recordAmtToReconcile, txn.quantity_to_reconcile)
      if (amount) {
        initialAmounts[txn.ext_segment_ref] = createMatch(txn, bankTxn, String(amount))
      }
      recordAmtToReconcile -= amount

      if (recordAmtToReconcile === 0) {
        break
      }
    } else {
      let amtUsed = 0

      for (const bankTxn of txn.bank_txns) {
        if (bankTxn.is_reconciled) {
          continue
        }

        const matchedAmount = getMatchedAmount(matches, bankTxn.ext_segment_ref)
        const amount = getAmount(recordAmtToReconcile, bankTxn.amount - matchedAmount)
        if (amount) {
          initialAmounts[bankTxn.ext_segment_ref] = createMatch(txn, bankTxn, String(amount))
        }

        amtUsed += amount
        recordAmtToReconcile -= amount

        if (recordAmtToReconcile === 0) {
          break
        }
      }

      const amount = getAmount(recordAmtToReconcile, txn.quantity_to_reconcile - amtUsed)
      if (amount) {
        initialAmounts[txn.ext_segment_ref] = createMatch(txn, null, String(amount))
      }
      amtUsed += amount
      recordAmtToReconcile -= amount

      if (recordAmtToReconcile === 0) {
        break
      }
    }
  }

  return initialAmounts
}

// Gets the matched amount for the given extSegmentRef,
// that being from a trade or bank payment, the caller decides which ref to use.
export function getMatchedAmount(matches: ReconMatch[], extSegmentRef: string): number {
  let matchedAmount = 0
  for (const match of matches) {
    for (const txn of match.txns) {
      if (txn.ext_segment_ref === extSegmentRef) {
        matchedAmount += txn.amount
      }
    }
  }
  return matchedAmount
}

// Gets the appropriate max or min amount, depending on the record amount sign.
function getAmount(recordAmt: number, txnAmt: number) {
  if (recordAmt < 0) {
    return Math.max(recordAmt, txnAmt)
  } else {
    return Math.min(recordAmt, txnAmt)
  }
}

function createMatch(txn: ReconTxn, bankTxn: ReconBankTxn | null, value: string) {
  const extSegmentRef = bankTxn?.ext_segment_ref || txn.ext_segment_ref
  const match: Match = {
    extSegmentRef,
    amount: value,
    portfolioRef: txn.portfolio_ref,
    bankTxnRef: bankTxn?.txn_ref || null,
    cpartyId: txn.cparty_id,
    description: bankTxn?.description || txn.description,
  }
  return match
}
