import { AddCircle, RemoveCircle } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import {
  ColDef,
  ColGroupDef,
  EditableCallbackParams,
  ICellEditorParams,
  ICellRendererParams,
  IHeaderGroupParams,
  IHeaderParams,
} from 'ag-grid-community'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'
import { AgGridReact } from 'ag-grid-react'
import { MouseEvent, RefObject, forwardRef, useEffect, useRef } from 'react'
import { DeleteFilterPayload } from '../../modules/portfolios/data/use-view-config-state'
import { extractDatapointFormatAndValue, formatCellToCopy } from '../../services/data/datapoint-formatting'
import { GridDataFilterOption, ParsedFilter } from '../../services/data/filter-parsing'
import {
  PanelType,
  ParsedGridData,
  ParsedGridDataCell,
  ParsedGridDataHeading,
  ParsedGridDataModellerHeading,
  ParsedGridDataPanelHeading,
  ParsedGridDataRow,
} from '../../services/data/grid-data-parsing'
import { GridDataViewFilters, GridDataViewSortBy } from '../../services/data/types/grid-data-view'
import { ColumnHeader } from './column-header'
import DatapointCell from './datapoint-cell'
import { DatapointInput, DatapointInputValue } from './datapoint-input'
import { StatusBar } from './status-bar'

type DataTableProps = {
  data: ParsedGridData | null
  sortBy: GridDataViewSortBy | null
  aggregations?: string[]
  hiddenRowGroups?: { [group: string]: boolean }
  filterOptions: GridDataFilterOption[]
  selectedFilters: ParsedFilter[]
  isGridsOnlyUser: boolean
  isCellLocked?: (cellReference?: CellReference) => boolean
  onSortChange: (sortBy: GridDataViewSortBy | null) => void
  onMoveColumn: (fromDatapointRef: string, toDatapointRef: string) => void
  onToggleRowGroupVisibility?: (group: string) => void
  onCellClick?: (rowRef: string) => void
  onCellUpdate?: (cellReference: CellReference, cellValue: DatapointInputValue) => void
  onContextMenuOpen?: (event: MouseEvent, row: ParsedGridDataRow, cell?: ParsedGridDataCell) => void
  onHideColumn: (datapointRef: string) => void
  onMoveToPanel?: (datapointRef: string) => void
  onRemoveFromPanel?: (datapointRef: string) => void
  onSetColumnWidth?: (datapointRef: string, width: number) => void
  onUpdateColumn?: (datapointRef: string, name: string | undefined, decimalPlaces: number | undefined) => void
  onGroupBy?: (datapointRef: string) => void
  onAddFilters: (filters: GridDataViewFilters) => void
  onDeleteFilter: (payload: DeleteFilterPayload) => void
  onForwardGridRef: (ref: RefObject<AgGridReact>) => void
}

export type CellReference = {
  rowRef: string
  datapointRef: string
  panelIndex: number | null
  panelType: PanelType | null
}

type AgHeaderComponentParams = {
  heading: ParsedGridDataHeading
  sortBy: GridDataViewSortBy | null
  aggregations: string[]
  filterOptions: GridDataFilterOption[]
  selectedFilters: ParsedFilter[]
  isGridsOnlyUser: boolean
  onSortChange: (sortBy: GridDataViewSortBy | null) => void
  onMoveColumn: (fromDatapointRef: string, toDatapointRef: string) => void
  onHideColumn: (datapointRef: string) => void
  onMoveToPanel?: (datapointRef: string) => void
  onRemoveFromPanel?: (datapointRef: string) => void
  onUpdateColumn?: (datapointRef: string, name: string | undefined, decimalPlaces: number | undefined) => void
  onGroupBy: ((datapointRef: string) => void) | undefined
  onAddFilters: (filters: GridDataViewFilters) => void
  onDeleteFilter: (payload: DeleteFilterPayload) => void
}

type AgCellEditorParams = {
  heading: ParsedGridDataHeading
  onCellUpdate?: (cellReference: CellReference, cellValue: DatapointInputValue) => void
}

export type AgCellData = {
  row: ParsedGridDataRow
  datapoints: {
    [datapointRef: string]: AgCellValue
  }
}

type AgCellValue = {
  index: number
  cell: ParsedGridDataCell
}

function DataTable(props: DataTableProps) {
  const {
    data,
    sortBy,
    filterOptions,
    selectedFilters,
    isGridsOnlyUser,
    isCellLocked,
    onSortChange,
    onMoveColumn,
    onToggleRowGroupVisibility,
    onCellClick,
    onContextMenuOpen,
    onCellUpdate,
    onHideColumn,
    onMoveToPanel,
    onRemoveFromPanel,
    onSetColumnWidth,
    onUpdateColumn,
    onGroupBy,
    onAddFilters,
    onDeleteFilter,
    onForwardGridRef,
  } = props
  const aggregations = props.aggregations || []
  const hiddenRowGroups = props.hiddenRowGroups || {}

  const gridRef = useRef<AgGridReact>(null)

  const visibleRows =
    data?.rows.filter((row) => {
      const path = row.type === 'aggregate' ? removeLastGroup(row.aggregatePath) : row.aggregatePath
      return !isRowGroupHidden(hiddenRowGroups, path)
    }) || []

  const rows = visibleRows.map((row) => {
    return row.cells.reduce<AgCellData>(
      (acc, cell, index) => {
        acc.datapoints[cell.headingKey] = { index, cell }
        return acc
      },
      { row, datapoints: {} }
    )
  })

  let cols: ColDef[] =
    data?.visibleHeadings.map((heading, index) => {
      const headerComponentParams: AgHeaderComponentParams = {
        heading,
        sortBy,
        aggregations,
        filterOptions,
        selectedFilters,
        isGridsOnlyUser,
        onSortChange,
        onHideColumn,
        onMoveColumn,
        onMoveToPanel,
        onRemoveFromPanel,
        onUpdateColumn,
        onGroupBy,
        onAddFilters,
        onDeleteFilter,
      }
      const cellEditorParams: AgCellEditorParams = {
        heading,
        onCellUpdate,
      }
      const isOnMainGrid = heading.meta.position === 'maingrid'

      return {
        colId: heading.key,
        field: `datapoints.${heading.key}`,
        headerName: heading.title || undefined,
        lockPinned: isOnMainGrid && index > 0,
        pinned: isOnMainGrid && index === 0,
        initialWidth: heading.width,
        headerComponentParams,
        cellEditorParams,
      } satisfies ColDef
    }) || []

  cols = assembleColumnGroups(cols, data)

  useEffect(() => {
    // fix for when columns were moved in/out of panels
    // and the cells wouldn't re-render
    gridRef.current?.api?.refreshCells()

    onForwardGridRef(gridRef)
  }, [data])

  if (!data) {
    return null
  }

  return (
    <div style={{ height: '100%' }}>
      <AgGridReact
        ref={gridRef}
        className="ag-theme-alpine-dark"
        columnDefs={cols}
        rowData={rows}
        rowHeight={32}
        rowSelection="single"
        animateRows
        singleClickEdit
        enableRangeSelection
        suppressColumnVirtualisation
        suppressRowHoverHighlight
        suppressCutToClipboard
        suppressClipboardPaste
        processCellForClipboard={(params) => {
          const cell: ParsedGridDataCell | undefined = params.value?.cell
          return formatCellToCopy(cell)
        }}
        getContextMenuItems={() => {
          // disable context menu by giving it no items,
          // as setting suppressContextMenu prevents row selection on right click
          return []
        }}
        onCellContextMenu={(event) => {
          // select row on right click
          event.node.setSelected(true)
        }}
        onColumnResized={(event) => {
          if (onSetColumnWidth && event.finished && event.column && event.source === 'uiColumnResized') {
            const colDef = event.column.getColDef()
            const headerComponentParams = colDef.headerComponentParams as AgHeaderComponentParams
            onSetColumnWidth(headerComponentParams.heading.meta.datapoint_ref, event.column.getActualWidth())
          }
        }}
        isRowSelectable={(params) => {
          if (!params.data) {
            return false
          }
          return params.data.row.aggregateLevel === null
        }}
        getRowId={(params) => {
          return params.data.row.key
        }}
        getRowClass={(params) => {
          if (params.data) {
            return groupLevelClass(params.data.row.aggregateLevel)
          }
        }}
        components={{
          agCellEditor: AgCellEditor,
          agColumnHeader: AgColumnHeader,
          agColumnGroupHeader: AgColumnGroupHeader,
        }}
        statusBar={{
          statusPanels: [{ statusPanel: StatusBar }],
        }}
        defaultColDef={{
          resizable: true,
          suppressMovable: true,
          suppressKeyboardEvent: (params) => {
            // prevent backspace of clearing editable cell value
            // prevent enter of stoping editing before saving value on our component
            return params.event.key === 'Backspace' || params.event.key === 'Enter'
          },
          editable: (params: EditableCallbackParams<AgCellData>) => {
            if (!params.data || !isCellLocked) {
              return false
            }

            const row = params.data.row
            const headerComponentParams = params.colDef.headerComponentParams as AgHeaderComponentParams
            const headingKey = headerComponentParams.heading.key
            const cellValue = params.data.datapoints[headingKey]

            if (!cellValue) {
              return false
            }

            const cell = cellValue.cell
            const canEdit = !!cell.meta?.can_edit

            const isLocked = isCellLocked({
              rowRef: row.rowRef,
              datapointRef: cell.datapointRef,
              panelIndex: cell.panelIndex,
              panelType: cell.panelType,
            })

            return canEdit && !isLocked
          },
          valueParser: () => {
            // used when editing cell, but we don't need it as we have our own component
            // but there was a warning on the console when not provided
            // https://www.ag-grid.com/react-data-grid/value-parsers/#value-parser
          },
          headerClass: (params) => {
            const headerComponentParams = (params.colDef as any).headerComponentParams as AgHeaderComponentParams
            return groupHeadingClass(headerComponentParams.heading)
          },
          cellClass: (params) => {
            return cellClass(params.value?.cell)
          },
          cellRenderer: (props: ICellRendererParams<AgCellData, AgCellValue>) => {
            const row = props.data?.row
            const value = props.value

            if (!row) {
              // shouldn't happen
              return null
            }

            if (!value) {
              return (
                <div
                  style={{ height: '100%' }}
                  onClick={() => onCellClick?.(row.rowRef)}
                  onContextMenu={(event) => onContextMenuOpen?.(event, row)}
                />
              )
            }

            const cell = value.cell
            const isLocked = !!isCellLocked?.({
              rowRef: row.rowRef,
              datapointRef: cell.datapointRef,
              panelIndex: cell.panelIndex,
              panelType: cell.panelType,
            })

            return (
              <div
                style={{
                  display: 'flex',
                  height: '100%',
                  gap: 8,
                }}
                onClick={() => onCellClick?.(row.rowRef)}
                onContextMenu={(event) => onContextMenuOpen?.(event, row, cell)}
              >
                {value.index === 0 && row.type === 'aggregate' && (
                  <div>
                    <IconButton
                      onClick={() => onToggleRowGroupVisibility?.(row.aggregatePath)}
                      sx={{ p: 0, color: 'rgba(255, 255, 255, 0.56)' }}
                    >
                      {hiddenRowGroups[row.aggregatePath] ? (
                        <AddCircle fontSize="small" />
                      ) : (
                        <RemoveCircle fontSize="small" />
                      )}
                    </IconButton>
                  </div>
                )}

                <DatapointCell
                  displayAs={cell.displayAs}
                  datadocType={cell.datadoc_type}
                  datapoint={cell.value}
                  alert={cell.alert}
                  explainer={cell.meta?.explainer}
                  isIncomplete={!!cell.meta?.is_incomplete}
                  isLocked={!!isLocked}
                  decimalPlaces={cell.decimalPlaces}
                />
              </div>
            )
          },
        }}
      />
    </div>
  )
}

export default DataTable

function AgColumnGroupHeader(params: IHeaderGroupParams) {
  return <div style={{ width: '100%', textAlign: 'center', fontWeight: '500' }}>{params.displayName}</div>
}

function AgColumnHeader(params: IHeaderParams) {
  const colDef = params.column.getUserProvidedColDef()
  const headerComponentParams = colDef!.headerComponentParams as AgHeaderComponentParams

  return (
    <ColumnHeader
      heading={headerComponentParams.heading}
      aggregations={headerComponentParams.aggregations}
      sortBy={headerComponentParams.sortBy}
      filterOptions={headerComponentParams.filterOptions}
      selectedFilters={headerComponentParams.selectedFilters}
      isGridsOnlyUser={headerComponentParams.isGridsOnlyUser}
      onSortChange={headerComponentParams.onSortChange}
      onMoveColumn={headerComponentParams.onMoveColumn}
      onHideColumn={headerComponentParams.onHideColumn}
      onMoveToPanel={headerComponentParams.onMoveToPanel}
      onRemoveFromPanel={headerComponentParams.onRemoveFromPanel}
      onUpdateColumn={headerComponentParams.onUpdateColumn}
      onGroupBy={headerComponentParams.onGroupBy}
      onAddFilters={headerComponentParams.onAddFilters}
      onDeleteFilter={headerComponentParams.onDeleteFilter}
    />
  )
}

const AgCellEditor = forwardRef(
  (props: ICellEditorParams<any, { cell: ParsedGridDataCell }> & AgCellEditorParams, _ref) => {
    if (!props.value) {
      return null
    }

    const cell = props.value.cell
    const cellReference: CellReference = {
      datapointRef: cell.datapointRef,
      panelIndex: cell.panelIndex,
      panelType: cell.panelType,
      rowRef: cell.rowRef,
    }

    return (
      <DatapointInput
        value={parseCellValue(cell)}
        onChange={(value) => {
          props.onCellUpdate?.(cellReference, value)
          props.stopEditing()
        }}
      />
    )
  }
)

function isRowGroupHidden(hiddenRowGroups: { [rowGroup: string]: boolean }, group: string) {
  const hasHiddenLevels = Object.entries(hiddenRowGroups).some(([key, value]) => {
    return group.startsWith(key) && !!value
  })
  return hasHiddenLevels
}

function removeLastGroup(group: string) {
  return group.replace(/\w*\/$/, '')
}

function groupLevelClass(aggregateLevel: number | null) {
  return aggregateLevel ? `level level-${aggregateLevel}` : ''
}

function groupHeadingClass(
  heading:
    | Pick<ParsedGridDataPanelHeading, 'leftDivider'>
    | Pick<ParsedGridDataModellerHeading, 'leftDivider' | 'panelType'>
) {
  const classes = ['column-header-cell']

  if ('panelType' in heading && heading.panelType === 'change') {
    classes.push('change-cell')
  }

  return classes.join(' ')
}

export function cellClass(cell?: ParsedGridDataCell | null) {
  const classes = []

  if (cell?.leftDivider) {
    classes.push('cell-left-divider')
  }

  if (cell?.panelType === 'change') {
    classes.push('change-cell')
  }

  return classes.join(' ')
}

function parseCellValue(cell: ParsedGridDataCell): DatapointInputValue {
  const [_, value] = extractDatapointFormatAndValue(cell.value)

  return {
    datapointType: cell.datapointType,
    value: value || '',
    isDirty: false,
  }
}

function assembleColumnGroups(cols: ColDef[], data: ParsedGridData | null) {
  if (!data) {
    return cols
  }

  let finalCols: ColDef[] | ColGroupDef[] = cols

  // only modeller
  if (data.modellerHeadings && !data.panelHeadings) {
    let offset = 0
    finalCols = data.modellerHeadings.map((heading) => {
      const child: ColGroupDef = {
        headerName: heading.title || '',
        children: cols.slice(offset, offset + heading.colSpan),
      }

      offset += heading.colSpan

      return child
    })
  }

  // only panels
  if (!data.modellerHeadings && data.panelHeadings) {
    let offset = 0
    finalCols = data.panelHeadings.map((heading) => {
      const child: ColGroupDef = {
        headerName: heading.title || '',
        children: cols.slice(offset, offset + heading.colSpan),
      }

      offset += heading.colSpan

      return child
    })
  }

  // both modeller and panels
  if (data.modellerHeadings && data.panelHeadings) {
    finalCols = []

    let mhOffset = 0
    let colsOffset = 0

    for (const panelHeading of data.panelHeadings) {
      const children: ColGroupDef[] = []

      let mhColSpanSum = 0
      for (const modelerHeading of data.modellerHeadings.slice(mhOffset)) {
        children.push({
          headerName: modelerHeading.title || '',
          children: cols.slice(colsOffset, colsOffset + modelerHeading.colSpan),
        })

        mhOffset += 1
        colsOffset += modelerHeading.colSpan
        mhColSpanSum += modelerHeading.colSpan

        if (panelHeading.colSpan === mhColSpanSum) {
          break
        }
      }

      finalCols.push({
        headerName: panelHeading.title || '',
        children,
      })
    }
  }

  return finalCols
}
