import { useMutation } from '@tanstack/react-query'
import { AxiosError, AxiosResponse } from 'axios'
import api from '../../../services/data/api'
import parseError from '../../../services/data/parse-error'
import { CreateApiKeyPayload } from '../../../services/data/types/api-key'

type Params = {
  userRef: string
  payload: CreateApiKeyPayload
}

export function useCreateApiKeyMutation() {
  const response = useMutation<AxiosResponse, AxiosError, Params>({
    mutationFn: ({ userRef, payload }) => {
      return api.createApiKey(userRef, payload)
    },
  })

  return { ...response, error: parseError(response.error) }
}
