import { useQuery } from '@tanstack/react-query'
import api from '../../../services/data/api'

function useStaticUploadGetOptionsQuery(options: { enabled: boolean; datasetRef?: string }) {
  return useQuery({
    queryKey: ['static_upload', 'options', options.datasetRef],
    queryFn: () => {
      return api.staticUploadGetOptions(options.datasetRef)
    },
    enabled: options.enabled,
  })
}

export default useStaticUploadGetOptionsQuery
