import { useTranslation } from 'react-i18next'
import { queryClient } from '../../../services/data/react-query'
import { AssetCategory, DataDocType } from '../../../services/data/types/asset-static-data'
import { DatapointType, DatapointValue } from '../../../services/data/types/datapoint'
import { Leg, TradeTicketResponse } from '../../../services/data/types/trade-ticket'
import { DateTimeIso } from '../../../utils/dates'
import SelectCurrencyModal from '../../trade-ticket/components/select-currency-modal'
import SelectItemModal from '../../trade-ticket/components/select-item-modal'
import SelectSecurityModal from '../../trade-ticket/components/select-security-modal'
import { EditAssetParams, useEditAssetDatapointMutation } from '../data/use-edit-asset-datapoint-mutation'
import EditAssetStaticModal from './edit-asset-static-modal'

type EditAssetStaticModalsProps = {
  isAssetActive: boolean
  assetRef: string
  assetCategory: AssetCategory | undefined | null
  assetDescription: string
  datapointRef?: string
  datapointName: string
  datapointValue: DatapointValue | undefined
  datapointType?: DatapointType
  datadocType?: DataDocType | null
  classificationId?: number | null
  canAutogenerate?: boolean
  asOnDate?: Date
  modifiedAt?: DateTimeIso
  modifiedBy?: string
  datasetRef: string | null
  currencies: TradeTicketResponse['currencies']
  indexes: TradeTicketResponse['indexes']
  refRates: TradeTicketResponse['ref_rates']
  regularCurves: TradeTicketResponse['regular_curves']
  tradeTicket: TradeTicketResponse | null
  resetActiveAsset: () => void
}

function EditAssetStaticModals(params: EditAssetStaticModalsProps) {
  const {
    isAssetActive,
    assetRef,
    assetCategory,
    assetDescription,
    datapointRef,
    datapointName,
    datapointValue,
    datapointType,
    datadocType,
    classificationId,
    canAutogenerate,
    asOnDate,
    modifiedAt,
    modifiedBy,
    datasetRef,
    currencies,
    indexes,
    refRates,
    regularCurves,
    tradeTicket,
    resetActiveAsset,
  } = params

  const { t } = useTranslation('dataEngine')

  const editAssetDatapoint = useEditAssetDatapointMutation()

  async function handleEditAssetStaticSave(params: EditAssetParams): Promise<void> {
    return new Promise((resolve, reject) => {
      editAssetDatapoint.mutate(params, {
        onSuccess: () => {
          handleEditAssetStaticClose()
          queryClient.invalidateQueries(['assetType'])
          queryClient.invalidateQueries(['asset'])
          resolve()
        },
        onError: reject,
      })
    })
  }

  async function handleSaveCurrency(currencyRef: string) {
    await handleEditAssetStaticSave({
      assetRef: assetRef,
      datapointRef: datapointRef || '',
      value: currencyRef
        ? {
            AssetRef: currencyRef,
          }
        : { None: null },
    })
  }

  async function handleSaveAssetRef(valueAssetRef: string) {
    await handleEditAssetStaticSave({
      assetRef: assetRef,
      datapointRef: datapointRef || '',
      value: valueAssetRef
        ? {
            AssetRef: valueAssetRef,
          }
        : { None: null },
    })
  }

  async function handleSaveSecurity(legSecurity: Leg['security']) {
    await handleEditAssetStaticSave({
      assetRef: assetRef,
      datapointRef: datapointRef || '',
      value: {
        AssetRef: legSecurity.assetRef,
      },
    })
  }

  function handleEditAssetStaticClose() {
    editAssetDatapoint.reset()
    resetActiveAsset()
  }

  return (
    <>
      <EditAssetStaticModal
        open={isAssetActive && !assetCategory}
        error={editAssetDatapoint.error}
        assetRef={assetRef}
        assetDescription={assetDescription || ''}
        datapointRef={datapointRef}
        datapointType={datapointType}
        datapointName={datapointName || ''}
        datapointValue={datapointValue}
        classificationId={classificationId}
        datadocType={datadocType}
        canAutogenerate={canAutogenerate}
        datasetRef={datasetRef}
        asOnDate={asOnDate}
        modifiedAt={modifiedAt}
        modifiedBy={modifiedBy}
        onSave={handleEditAssetStaticSave}
        onClose={handleEditAssetStaticClose}
      />
      <SelectCurrencyModal
        open={assetCategory === 'currency'}
        currencyRef={(datapointValue as any)?.['AssetRef']}
        currencies={currencies}
        onSave={handleSaveCurrency}
        onClose={handleEditAssetStaticClose}
      />
      <SelectItemModal
        open={assetCategory === 'index'}
        title={t('index_modal.title')}
        inputLabel={t('index_modal.input_label')}
        selectedId={(datapointValue as any)?.['AssetRef']}
        options={Object.entries(indexes).map(([id, label]) => ({ id, label }))}
        onSave={handleSaveAssetRef}
        onClose={handleEditAssetStaticClose}
      />
      <SelectItemModal
        open={assetCategory === 'ref_rate'}
        title={t('ref_rate_modal.title')}
        inputLabel={t('ref_rate_modal.input_label')}
        selectedId={(datapointValue as any)?.['AssetRef']}
        options={Object.entries(refRates).map(([id, label]) => ({ id, label }))}
        onSave={handleSaveAssetRef}
        onClose={handleEditAssetStaticClose}
      />
      <SelectItemModal
        open={assetCategory === 'curve'}
        title={t('regular_curve_modal.title')}
        inputLabel={t('regular_curve_modal.input_label')}
        selectedId={(datapointValue as any)?.['AssetRef']}
        options={Object.entries(regularCurves).map(([id, label]) => ({ id, label }))}
        onSave={handleSaveAssetRef}
        onClose={handleEditAssetStaticClose}
      />
      <SelectSecurityModal
        open={assetCategory === 'security'}
        onlyNoIdentifier
        tradeTicket={tradeTicket}
        onSave={handleSaveSecurity}
        onClose={handleEditAssetStaticClose}
      />
    </>
  )
}

export default EditAssetStaticModals
