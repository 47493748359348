import { useParams } from 'react-router-dom'
import { openView } from '../../../../services/openfin/fin'
import AssetStaticDetails from '../../components/asset-static-details'

function AssetStaticDetailsView() {
  const { assetTag } = useParams()

  function handleDatapointDetailsOpen(
    assetRef: string,
    datapointRef: string,
    datasetRef: string,
    decimalPlaces: number | undefined
  ) {
    let path = `openfin/views/data-engine/datasets/${datasetRef}/datapoints/${datapointRef}/assets/${assetRef}`
    if (decimalPlaces !== undefined) {
      path += `?decimalPlaces=${decimalPlaces}`
    }
    openView({
      viewPath: path,
    })
  }

  if (!assetTag) {
    return null
  }

  return <AssetStaticDetails assetTag={assetTag} onDatapointDetailsOpen={handleDatapointDetailsOpen} />
}

export default AssetStaticDetailsView
