import { ContentCopy, Delete, Key } from '@mui/icons-material'
import { Button, Stack, Typography } from '@mui/material'
import { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import ConfirmationModal from '../../../components/confirmation-modal'
import { copyToClipboard } from '../../../utils/clipboard'
import useOpenState from '../../../utils/hooks/use-open-state'
import { useCreateApiKeyMutation } from '../data/use-create-api-key-mutation'
import { useDeleteApiKeyMutation } from '../data/use-delete-api-key-mutation'
import { useListApiKeysQuery } from '../data/use-list-api-keys-query'
import { useRevealApiKeyMutation } from '../data/use-reveal-api-key-mutation'

type ApiKeyRowContentProps = {
  userRef: string
}

export function ApiKeyRowContent(props: ApiKeyRowContentProps) {
  const { userRef } = props

  const { t } = useTranslation('account')

  const deleteModal = useOpenState()
  const copied = useOpenState()
  const copiedRef = useRef<NodeJS.Timeout | null>(null)

  const createMutation = useCreateApiKeyMutation()
  const revealMutation = useRevealApiKeyMutation()
  const deleteMutation = useDeleteApiKeyMutation()

  const keysQuery = useListApiKeysQuery(userRef)
  const keys = keysQuery.data?.data || []
  const key = keys[0]
  const apiKeyId = key?.id

  function createKey() {
    if (!userRef) {
      return
    }

    const today = new Date()
    const suffix = `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`
    const name = `Key ${suffix}`

    createMutation.mutate(
      {
        userRef,
        payload: {
          name,
          expires_at: null,
        },
      },
      {
        onSuccess: () => {
          keysQuery.refetch()
        },
      }
    )
  }

  function revealKey() {
    if (!userRef || !apiKeyId) {
      return
    }

    revealMutation.mutate(
      {
        userRef,
        apiKeyId,
      },
      {
        onSuccess: async (data) => {
          const token = data.data.token
          await copyToClipboard(token)

          copied.open()
          if (copiedRef.current) {
            clearTimeout(copiedRef.current)
          }
          copiedRef.current = setTimeout(copied.close, 3000)
        },
      }
    )
  }

  function deleteKey() {
    if (!userRef || !apiKeyId) {
      return
    }

    deleteMutation.mutate(
      {
        userRef,
        apiKeyId,
      },
      {
        onSuccess: () => {
          keysQuery.refetch()
          deleteModal.close()
        },
      }
    )
  }

  return (
    <>
      {key && (
        <Stack direction="row" gap={2}>
          <Button size="small" startIcon={<ContentCopy />} onClick={revealKey}>
            {copied.isOpen ? t('common:copied') : t('common:copy')}
          </Button>
          <Button size="small" color="error" startIcon={<Delete />} onClick={deleteModal.open}>
            {t('common:delete')}
          </Button>
        </Stack>
      )}

      {!key && (
        <Button size="small" startIcon={<Key />} disabled={createMutation.isLoading} onClick={createKey}>
          {t('create_api_key')}
        </Button>
      )}

      <ConfirmationModal
        open={deleteModal.isOpen}
        title={t('delete_api_key_title')}
        confirmButtonText={t('common:delete')}
        error={deleteMutation.error}
        isLoading={deleteMutation.isLoading}
        onCloseButtonClick={deleteModal.close}
        onConfirmButtonClick={deleteKey}
      >
        <Typography variant="body1" sx={{ whiteSpace: 'pre-wrap' }}>
          {t('delete_api_key_message')}
        </Typography>{' '}
      </ConfirmationModal>
    </>
  )
}
