import { useMutation } from '@tanstack/react-query'
import { AxiosError, AxiosResponse } from 'axios'
import api from '../../../services/data/api'
import parseError from '../../../services/data/parse-error'

type Params = {
  userRef: string
  apiKeyId: number
}

export function useDeleteApiKeyMutation() {
  const response = useMutation<AxiosResponse, AxiosError, Params>({
    mutationFn: ({ userRef, apiKeyId }) => {
      return api.deleteApiKey(userRef, apiKeyId)
    },
  })

  return { ...response, error: parseError(response.error) }
}
