import { Box, Stack } from '@mui/system'
import { useTranslation } from 'react-i18next'
import NumericField from '../../../../components/fields/numeric-field'
import { TradeDirectionField } from '../../../../components/fields/trade-direction-field'
import { Leg } from '../../../../services/data/types/trade-ticket'
import { defaultInputDecimalPlaces, priceInputDecimalPlaces } from '../../../../utils/numbers'
import { CurrencyAdorment, PercentAdorment } from './shared/fields/adorments'
import BooleanSelector from './shared/fields/boolean-selector'
import DateSelector from './shared/fields/date-selector'
import { ProfileOperator } from './shared/profile-operator'
import { ProfileRow } from './shared/profile-row'

type CloseNdfProfileProps = {
  leg: Leg
  currencySymbol: string
  onUpdateLeg: (leg: Leg) => void
  onVerifyCloseOut: () => void
}

export function CloseNdfProfile(props: CloseNdfProfileProps) {
  const { leg, currencySymbol, onUpdateLeg, onVerifyCloseOut } = props

  const { t } = useTranslation('tradeTicket')

  function verifyCloseOut() {
    if (!leg.fixingRate) {
      return
    }
    onVerifyCloseOut()
  }

  return (
    <Stack gap={3}>
      <ProfileRow>
        <TradeDirectionField value={leg.tradeDirection} disabled sx={{ maxWidth: '150px', mr: 2 }} />

        <NumericField
          disabled
          fixedLabel
          name="notional"
          label={t('common:notional')}
          value={leg.notional}
          decimalPlaces={defaultInputDecimalPlaces}
          endAdornment={<CurrencyAdorment symbol={currencySymbol} />}
        />
        <ProfileOperator operator="x" />
        <NumericField
          disabled
          fixedLabel
          name="ndf_rate"
          label={t('common:ndf_rate')}
          value={leg.ndfRate}
          decimalPlaces={priceInputDecimalPlaces}
        />
        <ProfileOperator operator="=" />
        <NumericField
          disabled
          fixedLabel
          name="quote_amount"
          label={t('common:quote_amount')}
          value={leg.quoteAmount}
          decimalPlaces={defaultInputDecimalPlaces}
          endAdornment={<CurrencyAdorment symbol={currencySymbol} />}
        />
      </ProfileRow>

      <ProfileRow>
        <Box sx={{ maxWidth: '150px', mr: 2 }} />

        <DateSelector disabled label={t('common:fixing_date')} value={leg.fixingDate} />
        <ProfileOperator />
        <DateSelector disabled label={t('common:settlement_date')} value={leg.settlementDate} />
        <ProfileOperator />
        <BooleanSelector disabled label={t('common:settle_on_quote')} value={leg.settleOnQuote} />
      </ProfileRow>

      <ProfileRow>
        <Box sx={{ maxWidth: '150px', mr: 2 }} />

        <NumericField
          disabled
          fixedLabel
          name="initial_margin_rate"
          label={t('common:initial_margin_rate')}
          value={leg.initialMarginRate}
          decimalPlaces={defaultInputDecimalPlaces}
          endAdornment={<PercentAdorment />}
        />
        <ProfileOperator />
        <BooleanSelector disabled label={t('common:is_give_up')} value={leg.isGiveUp} />
        <ProfileOperator />
        <BooleanSelector disabled label={t('common:margin_in_quote_currency')} value={leg.marginInQuoteCurrency} />
      </ProfileRow>
      <ProfileRow>
        <Box sx={{ maxWidth: '150px', mr: 2 }} />

        <NumericField
          required
          fixedLabel
          name="fixing_rate"
          label={t('common:fixing_rate')}
          value={leg.fixingRate}
          decimalPlaces={defaultInputDecimalPlaces}
          onValueChange={(fixingRate) => onUpdateLeg({ ...leg, fixingRate })}
          onBlur={verifyCloseOut}
          onEnterDown={verifyCloseOut}
        />
        <ProfileOperator />
        <NumericField
          required
          disabled
          fixedLabel
          name="settlement_amount"
          label={t('common:settlement_amount')}
          value={leg.settlementAmount}
          decimalPlaces={defaultInputDecimalPlaces}
        />
        <ProfileOperator />
        <Box />
      </ProfileRow>
    </Stack>
  )
}
