import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import NumericField from '../../../../components/fields/numeric-field'
import { TradeDirectionField } from '../../../../components/fields/trade-direction-field'
import { PricingPayload } from '../../../../services/data/types/pricing'
import { Leg, TradeDirection } from '../../../../services/data/types/trade-ticket'
import { defaultInputDecimalPlaces, priceInputDecimalPlaces } from '../../../../utils/numbers'
import { CurrencyAdorment } from './shared/fields/adorments'
import { ProfileOperator } from './shared/profile-operator'
import { ProfileRow } from './shared/profile-row'

type StandardProfileProps = {
  leg: Leg
  currencySymbol: string
  isTradeConfirmation: boolean
  isCloseOutPosition: boolean
  onUpdateLeg: (leg: Leg) => void
  onUpdatePricing: (payload: PricingPayload) => void
  onTradeDirectionChange: (tradeDirection: TradeDirection) => void
}

export function StandardProfile(props: StandardProfileProps) {
  const {
    leg,
    currencySymbol,
    isTradeConfirmation,
    isCloseOutPosition,
    onUpdateLeg,
    onUpdatePricing,
    onTradeDirectionChange,
  } = props

  const { t } = useTranslation('tradeTicket')

  useEffect(() => {
    handleValueBlur()
  }, [leg.tradeProfile])

  function handleValueBlur() {
    const pricingPayload = createPricingPayload()

    if (!pricingPayload || !leg.value) {
      return
    }

    pricingPayload.consideration = Number(leg.value)

    if (isCloseOutPosition && leg.numberOf) {
      pricingPayload.quantity = Number(leg.numberOf)
    } else if (!isCloseOutPosition && leg.price) {
      pricingPayload.price = Number(leg.price)
    } else {
      return
    }

    onUpdatePricing(pricingPayload)
  }

  function handlePriceBlur() {
    const pricingPayload = createPricingPayload()

    if (!pricingPayload || !leg.price) {
      return
    }

    pricingPayload.price = Number(leg.price)

    if (isCloseOutPosition && leg.numberOf) {
      pricingPayload.quantity = Number(leg.numberOf)
    } else if (!isCloseOutPosition && leg.value) {
      pricingPayload.consideration = Number(leg.value)
    } else {
      return
    }

    onUpdatePricing(pricingPayload)
  }

  function handleNumberOfBlur() {
    const pricingPayload = createPricingPayload()

    if (!pricingPayload || !leg.numberOf || !leg.price) {
      return
    }

    pricingPayload.quantity = Number(leg.numberOf)
    pricingPayload.price = Number(leg.price)

    onUpdatePricing(pricingPayload)
  }

  function createPricingPayload() {
    if (!leg.tradeAssetType) {
      return
    }

    const payload: PricingPayload = {
      asset_type: leg.tradeAssetType.asset_type_tag,
      quantity_type: 'number_of',
      quantity: undefined,
      price: undefined,
      consideration: undefined,
      static_data: {},
      proposed_terms: {},
    }

    return payload
  }

  return (
    <ProfileRow>
      <TradeDirectionField
        value={leg.tradeDirection}
        disabled={isTradeConfirmation}
        onChange={onTradeDirectionChange}
        sx={{ maxWidth: '150px', mr: 2 }}
      />

      <NumericField
        required
        fixedLabel
        name="value"
        label={t('common:value')}
        value={leg.value}
        decimalPlaces={defaultInputDecimalPlaces}
        onValueChange={(value) => onUpdateLeg({ ...leg, value })}
        onBlur={handleValueBlur}
        onEnterDown={handleValueBlur}
        endAdornment={<CurrencyAdorment symbol={currencySymbol} />}
      />
      <ProfileOperator operator="=" />
      <NumericField
        required
        fixedLabel
        name="price"
        label={t('common:price')}
        value={leg.price}
        decimalPlaces={priceInputDecimalPlaces}
        onValueChange={(price) => onUpdateLeg({ ...leg, price })}
        onBlur={handlePriceBlur}
        onEnterDown={handlePriceBlur}
        endAdornment={<CurrencyAdorment symbol={currencySymbol} />}
      />
      <ProfileOperator operator="x" />
      <NumericField
        required
        fixedLabel
        name="numberOf"
        label={t('common:num_securities')}
        value={leg.numberOf}
        decimalPlaces={defaultInputDecimalPlaces}
        onValueChange={(numberOf) => onUpdateLeg({ ...leg, numberOf })}
        onBlur={handleNumberOfBlur}
        onEnterDown={handleNumberOfBlur}
      />
    </ProfileRow>
  )
}
