import { Pin } from '@mui/icons-material'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import { ListItemIcon, ListItemText, Menu, MenuItem, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

type AssetStaticColumnHeaderMenuProps = {
  anchorEl: HTMLElement | null
  onHideColumn?: () => void
  onUpdateDecimals?: () => void
  onCloseMenu: () => void
}

function AssetStaticColumnHeaderMenu(props: AssetStaticColumnHeaderMenuProps) {
  const { anchorEl, onHideColumn, onUpdateDecimals, onCloseMenu } = props

  const { t } = useTranslation('dataTable')

  return (
    <Menu
      className="context-menu"
      transitionDuration={0}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      open={!!anchorEl}
      onClose={onCloseMenu}
      onContextMenu={(event) => {
        event.preventDefault()
        onCloseMenu()
      }}
      // closeAfterTransition needed because:
      // https://github.com/mui/material-ui/issues/43106
      closeAfterTransition={false}
    >
      {onHideColumn && (
        <MenuItem onClick={onHideColumn}>
          <ListItemIcon>
            <VisibilityOffIcon fontSize="small" sx={{ color: 'gray.300' }} />
          </ListItemIcon>
          <ListItemText>
            <Typography fontSize="14px">{t('column_menu.hide_column')}</Typography>
          </ListItemText>
        </MenuItem>
      )}

      {onUpdateDecimals && (
        <MenuItem onClick={onUpdateDecimals}>
          <ListItemIcon>
            <Pin fontSize="small" sx={{ color: 'gray.300' }} />
          </ListItemIcon>
          <ListItemText>
            <Typography fontSize="14px">{t('column_menu.set_decimal_places')}</Typography>
          </ListItemText>
        </MenuItem>
      )}
    </Menu>
  )
}

export default AssetStaticColumnHeaderMenu
