import {
  Autocomplete,
  Dialog,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from '@mui/material'
import { Stack } from '@mui/system'
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { FormEvent, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Alert from '../../../components/alert'
import NumericField from '../../../components/fields/numeric-field'
import ModalActions from '../../../components/modal-actions'
import ModalContent from '../../../components/modal-content'
import { ModalDetailRow } from '../../../components/modal-detail-row'
import ModalTitle from '../../../components/modal-title'
import { CounterpartyAccountHeader } from '../../../services/data/types/bank-accounts'
import { datetimeFormat, datetimePlaceholder, formatDatetime } from '../../../utils/dates'
import { defaultInputDecimalPlaces, formatFloat } from '../../../utils/numbers'
import useBankAccountsCounterpartiesQuery from '../data/use-bank-accounts-counterparties-query'
import useCorrectCounterpartySettlementMutation from '../data/use-correct-counterparty-settlement-mutation'
import useCounterpartySettlementQuery from '../data/use-counterparty-settlement-query'
import useCounterpartyUnsettledDealsQuery from '../data/use-counterparty-unsettled-deals-query'
import useInsertCounterpartySettlementMutation from '../data/use-insert-counterparty-settlement-mutation'

type EnterCounterpatySettlementModalProps = {
  open: boolean
  bankAccountId: string
  txnRef: string | null
  onSaved: () => void
  onClose: () => void
}

function EnterCounterpatySettlementModal(props: EnterCounterpatySettlementModalProps) {
  const { open, bankAccountId, txnRef, onSaved, onClose } = props
  const { t } = useTranslation('bankAccounts')

  const formRef = useRef<HTMLFormElement>(null)

  const [counterparty, setCounterparty] = useState<CounterpartyAccountHeader | null>(null)
  const [segmentRef, setSegmentRef] = useState('')
  const [amount, setAmount] = useState('')
  const [date, setDate] = useState<Date | null>(null)

  const insertMutation = useInsertCounterpartySettlementMutation()
  const correctMutation = useCorrectCounterpartySettlementMutation()

  const transactionQuery = useCounterpartySettlementQuery(bankAccountId, txnRef)
  const transaction = transactionQuery.data?.data || null

  const counterpartiesQuery = useBankAccountsCounterpartiesQuery()
  const counterparties = counterpartiesQuery.data?.data || []

  const dealsQuery = useCounterpartyUnsettledDealsQuery(bankAccountId, counterparty)
  const deals = dealsQuery.data?.data || []

  const isEditing = !!txnRef
  const isLoading = transactionQuery.isFetching || counterpartiesQuery.isFetching || dealsQuery.isFetching
  const isLoadingDeals = dealsQuery.isFetching
  const isSaving = insertMutation.isLoading || correctMutation.isLoading

  const canSave = !isLoading && !!counterparty && !!segmentRef && !!date && !!amount

  const error =
    insertMutation.error ||
    correctMutation.error ||
    transactionQuery.error ||
    counterpartiesQuery.error ||
    dealsQuery.error

  const showDealMessage = (isLoadingDeals && !deals.length) || !counterparty || !deals.length
  const showLoadingDealsMessage = isLoadingDeals && !deals.length
  const showSelectCounterpartyMessage = !isLoadingDeals && !counterparty
  const showNoDealsAvailableMessage = !isLoadingDeals && !!counterparty && !deals.length
  const showMissingDealOption = !!transaction && !deals.find((d) => d.segment_ref === transaction.segment_ref)
  const showDeals = !!deals.length

  const modalTitle = isEditing
    ? t('create_cparty_settlement_modal.edit_title')
    : t('create_cparty_settlement_modal.new_title')

  useEffect(() => {
    if (open) {
      setDate(new Date())
    }
  }, [open])

  useEffect(() => {
    if (transaction) {
      setCounterparty(counterparties.find((c) => c.account_id === transaction.counterparty_acc_id) || null)
      setSegmentRef(transaction.segment_ref)
      setAmount(String(transaction.amount))
      setDate(new Date(transaction.txn_datetime))

      // @ts-ignore
      formRef.current?.elements['amount']?.focus()
    }
  }, [transaction])

  function handleClose() {
    dealsQuery.remove()
    transactionQuery.remove()
    insertMutation.reset()
    correctMutation.reset()

    onClose()

    setCounterparty(null)
    setSegmentRef('')
    setAmount('')
    setDate(null)
  }

  function handleSubmit(event: FormEvent) {
    event.preventDefault()

    insertMutation.reset()
    correctMutation.reset()

    if (isEditing) {
      correctSettlement()
    } else {
      createSettlement()
    }
  }

  function createSettlement() {
    if (!canSave) {
      return
    }

    insertMutation.mutate(
      {
        bankAccountId,
        counterpartyId: counterparty.account_id,
        payload: {
          deal_leg_ref: segmentRef,
          datetime: date.toISOString(),
          quantity: Number(amount),
        },
      },
      {
        onSuccess: handleSubmitSuccess,
      }
    )
  }

  function correctSettlement() {
    if (!canSave || !transaction) {
      return
    }

    correctMutation.mutate(
      {
        bankAccountId,
        txnRef: transaction.txn_ref,
        payload: {
          datetime: date.toISOString(),
          quantity: Number(amount),
        },
      },
      {
        onSuccess: handleSubmitSuccess,
      }
    )
  }

  function handleSubmitSuccess() {
    onSaved()

    if (isEditing) {
      handleClose()
    } else {
      setCounterparty(null)
      setSegmentRef('')
      setAmount('')
      setDate(new Date())

      // @ts-ignore
      formRef.current?.elements['counterparty']?.focus()
    }
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      // closeAfterTransition needed because:
      // https://github.com/mui/material-ui/issues/43106
      closeAfterTransition={false}
    >
      <form ref={formRef} onSubmit={handleSubmit}>
        <ModalTitle title={modalTitle} onClose={handleClose} />

        <ModalContent>
          <Alert severity="error" message={error} />

          {txnRef && <ModalDetailRow label={t('transaction')} value={txnRef} />}

          <Autocomplete
            disabled={isEditing}
            value={counterparty}
            options={counterparties}
            getOptionLabel={(counterparty) => counterparty.account_name}
            isOptionEqualToValue={(option, value) => option.account_id === value.account_id}
            onChange={(_, counterparty) => {
              setCounterparty(counterparty)
              setSegmentRef('')
              setAmount('')
            }}
            renderInput={(props) => (
              <TextField {...props} autoFocus required name="counterparty" label={t('counterparty')} />
            )}
          />

          <FormControl required disabled={isEditing}>
            <InputLabel>{t('transaction')}</InputLabel>
            <Select
              name="deal"
              label={t('transaction')}
              value={showDealMessage ? 'message' : segmentRef}
              onChange={(event: SelectChangeEvent) => {
                const selectedDeal = deals.find((d) => d.segment_ref === event.target.value)
                if (selectedDeal) {
                  setAmount(String(selectedDeal.quantity_to_settle - selectedDeal.quantity_settled))
                  setSegmentRef(selectedDeal.segment_ref)
                }
              }}
              MenuProps={{
                sx: { maxHeight: 300 },
              }}
              sx={{
                color: showDealMessage ? 'gray.300' : undefined,
              }}
            >
              {showSelectCounterpartyMessage && (
                <MenuItem value="message" disabled sx={{ fontFamily: 'monospace', fontSize: '14px' }}>
                  {t('create_cparty_settlement_modal.select_counterparty_first')}
                </MenuItem>
              )}

              {showNoDealsAvailableMessage && (
                <MenuItem value="message" disabled sx={{ fontFamily: 'monospace', fontSize: '14px' }}>
                  {t('create_cparty_settlement_modal.no_transactions_available')}
                </MenuItem>
              )}

              {showLoadingDealsMessage && (
                <MenuItem value="message" disabled sx={{ fontFamily: 'monospace', fontSize: '14px' }}>
                  {t('create_cparty_settlement_modal.loading_transactions')}
                </MenuItem>
              )}

              {showMissingDealOption && (
                <MenuItem value={transaction.segment_ref} disabled sx={{ fontFamily: 'monospace', fontSize: '14px' }}>
                  {transaction.segment_ref}
                </MenuItem>
              )}

              {showDeals &&
                deals.map((deal) => {
                  const datetime = formatDatetime(deal.txn_datetime)
                  const settled = formatFloat(deal.quantity_settled)
                  const toSettle = formatFloat(deal.quantity_to_settle)
                  const label = `${datetime} | ${deal.segment_ref} | ${settled} / ${toSettle}`

                  return (
                    <MenuItem
                      key={deal.segment_ref}
                      value={deal.segment_ref}
                      sx={{ fontFamily: 'monospace', fontSize: '14px' }}
                    >
                      {label}
                    </MenuItem>
                  )
                })}
            </Select>
          </FormControl>

          <Stack direction="row" gap={2}>
            <FormControl sx={{ flex: 1 }}>
              <NumericField
                required
                name="amount"
                label={t('common:amount')}
                value={amount}
                decimalPlaces={defaultInputDecimalPlaces}
                onValueChange={setAmount}
              />
            </FormControl>

            <FormControl required sx={{ flex: 1 }}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateTimePicker
                  label={t('common:date')}
                  value={date}
                  inputFormat={datetimeFormat}
                  onChange={setDate}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      fullWidth
                      error={false}
                      autoComplete="off"
                      inputProps={{
                        ...params.inputProps,
                        placeholder: datetimePlaceholder,
                      }}
                    />
                  )}
                />
              </LocalizationProvider>
            </FormControl>
          </Stack>
        </ModalContent>

        <ModalActions
          confirmLabel={t('common:post')}
          confirmDisabled={!canSave}
          isLoading={isSaving}
          onCancel={handleClose}
        />
      </form>
    </Dialog>
  )
}

export default EnterCounterpatySettlementModal
