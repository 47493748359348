import { Dialog, FormControl, TextField } from '@mui/material'
import { FormEvent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ModalActions from '../modal-actions'
import ModalContent from '../modal-content'
import ModalTitle from '../modal-title'

type ColumnHeaderEditNameModalProps = {
  open: boolean
  title: string | null
  datapointName: string
  onSave: (name: string) => void
  onClose: () => void
}

function ColumnHeaderEditNameModal(props: ColumnHeaderEditNameModalProps) {
  const { open, title, datapointName, onSave, onClose } = props

  const { t } = useTranslation('dataTable')

  const [name, setName] = useState('')

  useEffect(() => {
    if (open && title && title !== datapointName) {
      setName(title)
    } else {
      setName('')
    }
  }, [open, title, datapointName])

  function handleSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault()
    onSave(name)
    onClose()
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      // closeAfterTransition needed because:
      // https://github.com/mui/material-ui/issues/43106
      closeAfterTransition={false}
    >
      <form onSubmit={handleSubmit}>
        <ModalTitle title={t('header_modal.edit_name_title')} onClose={onClose} />
        <ModalContent>
          <FormControl>
            <TextField
              autoFocus
              name="columnName"
              label={t('header_modal.name')}
              value={name}
              placeholder={datapointName}
              onChange={(event) => setName(event.target.value)}
              autoComplete="off"
              InputLabelProps={{ shrink: true }}
            />
          </FormControl>
        </ModalContent>
        <ModalActions confirmLabel={t('common:save')} onCancel={onClose} />
      </form>
    </Dialog>
  )
}

export default ColumnHeaderEditNameModal
