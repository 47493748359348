import { useMutation } from '@tanstack/react-query'
import { AxiosError, AxiosResponse } from 'axios'
import api from '../../../services/data/api'
import parseError from '../../../services/data/parse-error'
import { CreateUserBody } from '../../../services/data/types/user'

function useCreateUserMutation() {
  const response = useMutation<AxiosResponse, AxiosError, CreateUserBody>({
    mutationFn: api.createUser,
  })

  return { ...response, error: parseError(response.error) }
}

export default useCreateUserMutation
