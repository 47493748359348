import { Stack } from '@mui/material'
import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import ErrorSnackbar from '../../../components/error-snackbar'
import AppLayout from '../../../components/layouts/app-layout'
import {
  ParsedGridData,
  getPanelColumns,
  mergeUpdatedCells,
  parseGridData,
} from '../../../services/data/grid-data-parsing'
import { DatapointValue } from '../../../services/data/types/datapoint'
import { GridCellOption, GridDataModellingOption, UpdatedGridCell } from '../../../services/data/types/grid-data'
import { GridDataViewCreateBody, GridDataViewUpdateBody } from '../../../services/data/types/grid-data-view'
import { ExportPortfolioParams } from '../../../services/data/types/portfolio'
import { UpdateWhatIfPayload } from '../../../services/data/types/what-if'
import { downloadBlobFile } from '../../../utils/download'
import useOpenState from '../../../utils/hooks/use-open-state'
import useUserInfo from '../../auth/data/use-user-info'
import usePortfolioGridCompliaceQuery from '../../compliance/data/use-portfolio-grid-compliance-query'
import useCreateWhatIfMutation from '../data/use-create-what-if-mutation'
import useExportPortfolioMutation from '../data/use-export-portfolio-mutation'
import { useFilterOptions } from '../data/use-filter-options'
import usePortfolioGridOptionsQuery from '../data/use-portfolio-grid-options-query'
import usePortfolioQuery from '../data/use-portfolio-query'
import useUpdateGridDataViewMutation from '../data/use-update-grid-data-view-mutation'
import useUpdateWhatIfMutation from '../data/use-update-what-if-mutation'
import useViewConfigState, { initColumnOrder } from '../data/use-view-config-state'
import ModellerSendToTradingModal from './modeller-send-to-trading-modal'
import ModellingOptionsModal from './modelling-options-modal'
import MultipanelSettingsModal from './multipanel-settings-modal'
import PortfolioGridHeader from './portfolio-grid-header'
import PortfolioGridNav from './portfolio-grid-nav'
import PortfolioTable, { CellReference, CellValue } from './portfolio-table'
import PortfolioUploadModal from './portfolio-upload-modal'
import { ViewEditParams } from './view-edit-modal'
import ViewOptionsMenu from './view-options-menu'
import { ViewSaveAsParams } from './view-save-as-modal'

type PortfolioGridProps = {
  portfolioRef?: string
  onPortfolioSelect: (portfolioRef: string) => void
  onSecuritySelect: (assetRef: string) => void
  onLiquiditySelect: (assetRef: string) => void
  onNewTrade: () => void
  onNewCashflow: () => void
  onClosePosition: (yRef: string, aggregations: string[], datasetRef: string | null) => void
  onOpenCompliance: () => void
  onTabNameUpdate?: (name: string | undefined) => void
}

function PortfolioGrid(props: PortfolioGridProps) {
  const {
    portfolioRef,
    onPortfolioSelect,
    onSecuritySelect,
    onLiquiditySelect,
    onNewTrade,
    onNewCashflow,
    onClosePosition,
    onOpenCompliance,
    onTabNameUpdate,
  } = props

  const [searchParams, setSearchParams] = useSearchParams()

  const { user, isGridsOnlyUser } = useUserInfo()

  const nav = useOpenState({ open: true })
  const multipanelModal = useOpenState()
  const modellingOptionsModal = useOpenState()
  const sendToTradingModal = useOpenState()
  const uploadModal = useOpenState()
  const viewsMenu = useOpenState()

  const updateView = useUpdateGridDataViewMutation()
  const exportPortfolio = useExportPortfolioMutation()
  const createWhatIf = useCreateWhatIfMutation()
  const updateWhatIf = useUpdateWhatIfMutation()

  // todo: reset whatif state and disable modeller when portfolioRef changes

  // When tableKey changes, the PortfolioTable component is recreated.
  // This is needed so AgGrid doesn't animate when the whole grid data changes,
  // making changing between portfolios or views instant, but other interations still animate.
  const [tableKey, setTableKey] = useState('')
  const [header, setHeader] = useState({ title: '', currency: '' })

  const [parsedGridData, setParsedGridData] = useState<ParsedGridData | null>(null)
  const [updatedCells, setUpdatedCells] = useState<UpdatedGridCell[]>([])
  const [modellingOptions, setModellingOptions] = useState<GridDataModellingOption[]>([])

  const mergedGridData = mergeUpdatedCells(parsedGridData, updatedCells)
  const columnsOptions = mergedGridData?.columnsOptions || []
  const shownColumnsCount = mergedGridData?.shownColumnsCount
  const isModellerOn = !!mergedGridData?.modellerHeadings?.length

  const gridOptionsResponse = usePortfolioGridOptionsQuery(portfolioRef)
  const gridOptions = gridOptionsResponse.data?.data
  const defaultModellingOptions = gridOptionsResponse.data?.data.modelling_opts

  const whatIfRef = createWhatIf.data?.data?.whatif_ref || searchParams.get('whatifRef')

  const {
    viewConfigState,
    setAggregations,
    hideAllColumns,
    unhideAllColumns,
    toggleHiddenColumn,
    moveColumn,
    moveColumnToPanel,
    removeColumnFromPanel,
    setColumnWidth,
    updateColumn,
    setPanelMode,
    setFilters,
    addFilters,
    deleteFilter,
    setSortBy,
    setAsOfDate,
    setView,
    setDataset,
    resetState,
    isStateReady,
  } = useViewConfigState(gridOptions)

  const { datasetRef, viewRef, aggregations, panelMode, filters, asOfDate, sortBy, columns, isViewDirty } =
    viewConfigState

  // panelColumns could be on the parsedGridData, but this is needed because
  // it makes sure there is a single grid request on a page reload
  const panelColumns = getPanelColumns(columns) || []

  const portfolioResponse = usePortfolioQuery(
    portfolioRef,
    datasetRef,
    whatIfRef,
    aggregations,
    panelMode,
    filters,
    panelColumns,
    columns,
    asOfDate,
    { enabled: isStateReady }
  )

  const data = portfolioResponse.data?.data || null

  const currentView = gridOptions?.views?.find((view) => view.view_ref === viewRef)
  const currentViewName = currentView?.view_name
  const filterOptions = useFilterOptions(parsedGridData?.columnsOptions, datasetRef)

  const complianceQuery = usePortfolioGridCompliaceQuery(portfolioRef, asOfDate)
  const compliance = complianceQuery.data?.data || null

  const showLoading = portfolioResponse.isFetching || createWhatIf.isLoading

  useEffect(() => {
    if (!portfolioResponse.isFetching && !portfolioResponse.isError) {
      const title = [data?.portfolio_name, currentViewName].filter(Boolean).join(' / ')
      const currency = data?.base_currency || ''

      setHeader({ title, currency })
      onTabNameUpdate?.(currentViewName)
      setTableKey(`portfolio-${portfolioRef}_view-${viewRef}`)
      setParsedGridData(parseGridData(data, columns, sortBy, compliance))
    }
  }, [data, columns, sortBy, compliance, currentViewName, portfolioResponse.isFetching, portfolioResponse.isError])

  useEffect(() => {
    if (defaultModellingOptions) {
      setModellingOptions(defaultModellingOptions)
    }
  }, [defaultModellingOptions])

  function handlePortfolioClick(portfolioref: string) {
    resetState()
    onPortfolioSelect(portfolioref)
  }

  function handleToggleHiddenColumn(datapointRef: string) {
    toggleHiddenColumn(datapointRef, parsedGridData?.originalHeadings!)
  }

  function handleMoveColumn(fromDatapointRef: string, toDatapointRef: string) {
    moveColumn(fromDatapointRef, toDatapointRef, parsedGridData?.originalHeadings!)
  }

  function handleMoveColumnToPanel(datapointRef: string) {
    moveColumnToPanel(datapointRef, parsedGridData?.originalHeadings!)
  }

  function handleRemoveColumnFromPanel(datapointRef: string) {
    removeColumnFromPanel(datapointRef, parsedGridData?.originalHeadings!)
  }

  function handleSetColumnWidth(datapointRef: string, width: number) {
    setColumnWidth(datapointRef, width, parsedGridData?.originalHeadings!)
  }

  function handleUpdateColumn(datapointRef: string, name: string | undefined, decimalPlaces: number | undefined) {
    updateColumn(datapointRef, name, decimalPlaces, parsedGridData?.originalHeadings!)
  }

  function createEditViewBody(params: ViewEditParams): GridDataViewUpdateBody | undefined {
    if (!gridOptions || !parsedGridData) {
      return
    }

    const body: GridDataViewUpdateBody = {
      view_name: params.viewName,
      owner_group_ref: params.ownerGroupRef,
    }
    return body
  }

  function createSaveViewBody(): GridDataViewUpdateBody | undefined {
    if (!parsedGridData) {
      return
    }

    const body: GridDataViewUpdateBody = {
      view_options: {
        aggregation: aggregations,
        sort_by: sortBy,
        filters,
        panel_mode: panelMode,
        columns: initColumnOrder(columns, parsedGridData.originalHeadings),
        asset_types: null,
        as_of_date: asOfDate,
      },
    }

    return body
  }

  function createSaveAsViewBody(params: ViewSaveAsParams): GridDataViewCreateBody | undefined {
    if (!data?.dataset) {
      return
    }

    const body: GridDataViewCreateBody = {
      view_name: params.name,
      dataset_ref: datasetRef || data?.dataset,
      view_type: 'positions_grid',
      view_options: {
        aggregation: aggregations,
        sort_by: sortBy,
        filters,
        panel_mode: panelMode,
        columns: initColumnOrder(columns, parsedGridData?.originalHeadings || []),
        asset_types: null,
        as_of_date: asOfDate,
      },
    }

    return body
  }

  function handleViewSaveAsSuccess(data: any) {
    setSearchParams({ viewRef: data.view_ref })
    gridOptionsResponse.refetch()
    viewsMenu.close()
  }

  async function handleViewDeleteSuccess(deletedViewRef: string) {
    if (deletedViewRef === viewRef) {
      viewsMenu.close()

      await gridOptionsResponse.refetch()
      setSearchParams({})
      resetState()
      return
    }

    await gridOptionsResponse.refetch()
  }

  function handleModellerSwitch(enabled: boolean) {
    if (enabled && portfolioRef) {
      createWhatIf.mutate(
        { portfolioRef, payload: { aggregation: aggregations }, datasetRef, asOfDate },
        {
          onSuccess: (response) => {
            const whatifRef = response.data.whatif_ref
            searchParams.set('whatifRef', whatifRef)
            setSearchParams(searchParams)
          },
        }
      )
    } else {
      searchParams.delete('whatifRef')
      setSearchParams(searchParams)
      createWhatIf.reset()
      setUpdatedCells([])
      setModellingOptions(defaultModellingOptions || [])
    }
  }

  function handleModellingOptionsChange(options: GridDataModellingOption[]) {
    setModellingOptions(options)
    modellingOptionsModal.close()
  }

  function handleUpdateCell(cell: CellReference, value: CellValue, cellOptions: GridCellOption[]) {
    if (!portfolioRef || !whatIfRef) {
      return
    }

    let updatedValue = Number(value.value)
    if (value.datapointType === 'Percent') {
      updatedValue /= 100
    }

    const updatedCell: UpdatedGridCell = {
      y_ref: cell.rowRef,
      datapoint_ref: cell.datapointRef,
      panel_index: cell.panelIndex!,
      panel_is_new: cell.panelType == 'new',
      datapoint_value: {
        [value.datapointType]: updatedValue,
      } as DatapointValue,
    }

    const safeStateOfUpdatedCells = updatedCells.map((updatedCell) => ({ ...updatedCell }))
    setUpdatedCells((prevCells) => {
      const filteredCells = prevCells.filter((cell) => !checkIfSameUpdatedCell(cell, updatedCell))
      return [...filteredCells, updatedCell]
    })

    const payload: UpdateWhatIfPayload = {
      y_ref: updatedCell.y_ref,
      datapoint_ref: updatedCell.datapoint_ref,
      aggregation: aggregations,
      panel_idx: updatedCell.panel_index,
      target_value: updatedValue,
      cell_options: cellOptions,
      general_options: modellingOptions.map((option) => {
        return { option_id: option.option_id, value: option.default }
      }),
    }

    updateWhatIf.mutate(
      { portfolioRef, whatifRef: whatIfRef, payload, asOfDate },
      {
        onSuccess(response) {
          const newUpdatedCells = response.data

          setUpdatedCells((prevCells) => {
            const filteredCells = prevCells.filter((cell) => {
              return !newUpdatedCells.find((newCell) => checkIfSameUpdatedCell(newCell, cell))
            })
            return [...filteredCells, ...newUpdatedCells]
          })
        },
        onError: () => {
          setUpdatedCells(safeStateOfUpdatedCells)
        },
      }
    )
  }

  function handleModellerReset() {
    if (!portfolioRef) {
      return
    }

    createWhatIf.mutate(
      { portfolioRef, payload: { aggregation: aggregations } },
      {
        onSuccess: (response) => {
          const whatifRef = response.data.whatif_ref
          searchParams.set('whatifRef', whatifRef)
          setSearchParams(searchParams)
          setUpdatedCells([])
          setModellingOptions(defaultModellingOptions || [])
        },
      }
    )
  }

  function handleSaveErrorSnackbarClose(_event?: React.SyntheticEvent | Event, reason?: string) {
    if (reason === 'clickaway') {
      return
    }
    updateView.reset()
  }

  function handleExportPortfolioPositions() {
    if (!portfolioRef || !data) {
      return
    }

    const params: ExportPortfolioParams = {
      portfolioRef,
      portfolioName: data.portfolio_name,
      viewRef,
      viewName: currentView?.view_name,
      exportTransactions: false,
      asOfDate: asOfDate,
    }

    exportPortfolio.mutate(params, {
      onSuccess: (response) => {
        downloadBlobFile(response.data.blob, response.data.filename)
      },
    })
  }

  function handleClosePosition(yRef: string) {
    onClosePosition(yRef, aggregations, datasetRef)
  }

  function handlePortfolioUploadSuccess() {
    reloadData()
    uploadModal.close()
  }

  function reloadData() {
    gridOptionsResponse.refetch()
    portfolioResponse.refetch()
    complianceQuery.refetch()
  }

  return (
    <>
      <AppLayout direction="row">
        <PortfolioGridNav
          open={nav.isOpen}
          selectedPortfolioRef={portfolioRef}
          onCloseClick={nav.close}
          onPortfolioSelect={handlePortfolioClick}
        />
        <Stack flex={1} overflow="hidden">
          <PortfolioGridHeader
            portfolioTitle={header.title}
            currency={header.currency}
            showLoading={showLoading}
            isNavOpen={nav.isOpen}
            isViewDirty={isViewDirty}
            isGridsOnlyUser={isGridsOnlyUser}
            compliance={compliance}
            isComplianceLoading={complianceQuery.isFetching}
            onOpenNavClick={nav.open}
            onNewTradeClick={onNewTrade}
            onNewCashflowClick={onNewCashflow}
            onViewOptionsClick={viewsMenu.open}
            onUploadClick={uploadModal.open}
            onExportPortfolio={handleExportPortfolioPositions}
            onReloadData={reloadData}
            onOpenCompliance={onOpenCompliance}
          />

          <Stack sx={{ height: '100%', px: 2, pb: 2, overflow: 'hidden' }}>
            <PortfolioTable
              key={tableKey}
              data={mergedGridData}
              aggregations={aggregations}
              filters={filters}
              filterOptions={filterOptions}
              columnsOptions={columnsOptions}
              shownColumnsCount={shownColumnsCount}
              sortBy={sortBy}
              modellerOn={isModellerOn}
              hideLockCellButton={!isModellerOn}
              isMultipanelActive={!!panelMode}
              isGridsOnlyUser={isGridsOnlyUser}
              asOfDate={asOfDate}
              onSortChange={setSortBy}
              onSecuritySelect={onSecuritySelect}
              onLiquiditySelect={onLiquiditySelect}
              onAddFilters={addFilters}
              onAggregationsChange={setAggregations}
              onDeleteAllFilters={() => setFilters(null)}
              onDeleteFilter={deleteFilter}
              onSetAsOfDate={setAsOfDate}
              onToggleHiddenColumn={handleToggleHiddenColumn}
              onHideAllColumns={hideAllColumns}
              onUnhideAllColumns={unhideAllColumns}
              onMoveColumn={handleMoveColumn}
              onMoveColumnToPanel={handleMoveColumnToPanel}
              onRemoveColumnFromPanel={handleRemoveColumnFromPanel}
              onSetColumnWidth={handleSetColumnWidth}
              onUpdateColumn={handleUpdateColumn}
              onModellerSwitch={handleModellerSwitch}
              onModellingOptionsClick={modellingOptionsModal.open}
              onModellerResetClick={handleModellerReset}
              onModellerSendToTradingClick={sendToTradingModal.open}
              onMultipanelClick={multipanelModal.open}
              onUpdateCell={handleUpdateCell}
              onClosePosition={handleClosePosition}
            />
          </Stack>
        </Stack>
      </AppLayout>

      <MultipanelSettingsModal
        open={multipanelModal.isOpen}
        parsedGridData={parsedGridData}
        panelModeData={panelMode}
        onSave={setPanelMode}
        onClose={multipanelModal.close}
      />
      <ViewOptionsMenu
        open={viewsMenu.isOpen}
        views={gridOptions?.views}
        defaultViewRef={gridOptions?.default_view_ref}
        datasets={gridOptions?.datasets}
        isViewDirty={isViewDirty}
        userGroupRef={user?.group_ref}
        currentView={currentView}
        portfolioRef={portfolioRef}
        onViewSelect={setView}
        onDatasetSelect={setDataset}
        createSaveViewBody={createSaveViewBody}
        createSaveAsViewBody={createSaveAsViewBody}
        createEditViewBody={createEditViewBody}
        onViewSaveAsSuccess={handleViewSaveAsSuccess}
        onViewDeleteSuccess={handleViewDeleteSuccess}
        onClose={viewsMenu.close}
      />
      <ModellingOptionsModal
        open={modellingOptionsModal.isOpen}
        options={modellingOptions}
        onChange={handleModellingOptionsChange}
        onClose={modellingOptionsModal.close}
      />
      <ModellerSendToTradingModal
        open={sendToTradingModal.isOpen}
        portfolioRef={portfolioRef}
        updatedCells={updatedCells}
        gridData={mergedGridData}
        onTradingCreated={() => handleModellerSwitch(false)}
        onClose={sendToTradingModal.close}
      />
      <PortfolioUploadModal
        open={uploadModal.isOpen}
        portfolioRef={portfolioRef}
        onClose={uploadModal.close}
        onSucess={handlePortfolioUploadSuccess}
      />

      <ErrorSnackbar open={portfolioResponse.isError} message={portfolioResponse.error} />
      <ErrorSnackbar open={!!updateWhatIf.error} message={updateWhatIf.error} onClose={updateWhatIf.reset} />
      <ErrorSnackbar open={!!exportPortfolio.error} message={exportPortfolio.error} onClose={exportPortfolio.reset} />
      <ErrorSnackbar open={!!updateView.error} message={updateView.error} onClose={handleSaveErrorSnackbarClose} />
    </>
  )
}

export default PortfolioGrid

function checkIfSameUpdatedCell(cellA: UpdatedGridCell, cellB: UpdatedGridCell) {
  const isSameRow = cellA.y_ref === cellB.y_ref
  const isSameColum = cellA.datapoint_ref === cellB.datapoint_ref
  const isSamePanel = cellA.panel_index === cellB.panel_index && cellA.panel_is_new === cellB.panel_is_new

  return isSameRow && isSameColum && isSamePanel
}
