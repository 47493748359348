import { Button, CircularProgress, DialogActions, Divider, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

type ModalActionsProps = {
  confirmLabel: string
  confirmDisabled?: boolean
  removeLabel?: string
  isLoading?: boolean
  onConfirm?: () => void
  onCancel: () => void
  onRemove?: () => void
}

function ModalActions(props: ModalActionsProps) {
  const { confirmLabel, confirmDisabled, removeLabel, isLoading, onConfirm, onCancel, onRemove } = props

  const { t } = useTranslation()

  return (
    <>
      <Divider />
      <DialogActions>
        {!!onRemove && (
          <Button onClick={onRemove} sx={{ mr: 'auto' }} color="error">
            {removeLabel || t('common:remove')}
          </Button>
        )}
        <Button onClick={onCancel}>{t('common:cancel')}</Button>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          disabled={confirmDisabled || isLoading}
          onClick={onConfirm}
        >
          {isLoading && <CircularProgress size={24} color="inherit" sx={{ position: 'absolute' }} />}
          <Typography color="inherit" variant="inherit" sx={{ opacity: isLoading ? 0 : 1 }}>
            {confirmLabel}
          </Typography>
        </Button>
      </DialogActions>
    </>
  )
}

export default ModalActions
