import { Percent } from '@mui/icons-material'
import { InputAdornment } from '@mui/material'
import { Box, Stack } from '@mui/system'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import NumericField from '../../../../components/fields/numeric-field'
import { TradeDirectionField } from '../../../../components/fields/trade-direction-field'
import { Leg } from '../../../../services/data/types/trade-ticket'
import { defaultInputDecimalPlaces, priceInputDecimalPlaces } from '../../../../utils/numbers'
import { CurrencyAdorment, PercentAdorment } from './shared/fields/adorments'
import DateSelector from './shared/fields/date-selector'
import { ProfileOperator } from './shared/profile-operator'
import { ProfileRow } from './shared/profile-row'

type CloseRepoProfileProps = {
  leg: Leg
  currencySymbol: string
  onUpdateLeg: (leg: Leg) => void
  onVerifyCloseOut: () => void
}

export function CloseRepoProfile(props: CloseRepoProfileProps) {
  const { leg, currencySymbol, onUpdateLeg, onVerifyCloseOut } = props

  const { t } = useTranslation('tradeTicket')

  useEffect(() => {
    verifyCloseOut()
  }, [leg.actualSettlementDate?.getTime()])

  function verifyCloseOut() {
    if (!leg.actualSettlementDate || !leg.settlementAmount) {
      return
    }
    onVerifyCloseOut()
  }

  return (
    <Stack gap={3}>
      <ProfileRow>
        <TradeDirectionField
          value={leg.tradeDirection}
          disabled
          buyLabel="Rev Repo"
          sellLabel="Repo"
          sx={{ maxWidth: '150px', mr: 2 }}
        />

        <ProfileOperator operator="(" />
        <NumericField
          disabled
          fixedLabel
          name="notional"
          label={t('common:notional')}
          value={leg.notional}
          decimalPlaces={defaultInputDecimalPlaces}
        />
        <ProfileOperator operator="x" />
        <NumericField
          disabled
          fixedLabel
          name="sinkFactor"
          label={t('common:sink_factor')}
          value={leg.sinkFactor}
          decimalPlaces={priceInputDecimalPlaces}
          endAdornment={<PercentAdorment />}
        />
        <ProfileOperator operator="@" />
        <NumericField
          disabled
          fixedLabel
          name="price"
          label={t('common:clean_price')}
          value={leg.price}
          decimalPlaces={priceInputDecimalPlaces}
          endAdornment={<PercentAdorment />}
        />
        <ProfileOperator operator=") +" />
        <NumericField
          disabled
          fixedLabel
          name="accruedInterest"
          label={t('common:accrued_interest')}
          value={leg.accruedInterest}
          decimalPlaces={defaultInputDecimalPlaces}
        />
      </ProfileRow>

      <ProfileRow>
        <Box sx={{ maxWidth: '150px', mr: 2 }} />

        <ProfileOperator operator="x" />
        <NumericField
          disabled
          fixedLabel
          name="haircut"
          label={t('common:haircut')}
          value={leg.haircut}
          decimalPlaces={priceInputDecimalPlaces}
          endAdornment={
            <InputAdornment position="end">
              <Percent fontSize="inherit" sx={{ color: 'gray.300' }} />
            </InputAdornment>
          }
        />
        <ProfileOperator operator="=" />
        <NumericField
          disabled
          fixedLabel
          name="funding_amount_lc"
          label={t('common:funding_amount_lc')}
          value={leg.fundingAmountLc}
          decimalPlaces={defaultInputDecimalPlaces}
        />
      </ProfileRow>

      <ProfileRow>
        <Box sx={{ maxWidth: '150px', mr: 2 }} />

        <ProfileOperator operator="/" />
        <NumericField
          disabled
          fixedLabel
          name="inception_fx"
          label={t('common:inception_fx')}
          value={leg.inceptionFx}
          decimalPlaces={priceInputDecimalPlaces}
        />
        <ProfileOperator operator="=" />
        <NumericField
          disabled
          fixedLabel
          name="repo_funding_amount"
          label={t('common:repo_funding_amount')}
          value={leg.repoFundingAmount}
          decimalPlaces={defaultInputDecimalPlaces}
          endAdornment={<CurrencyAdorment symbol={currencySymbol} />}
        />
      </ProfileRow>

      <ProfileRow>
        <Box sx={{ maxWidth: '150px', mr: 2 }} />

        <ProfileOperator />
        <DateSelector disabled label={t('common:settlement_date')} value={leg.settlementDate} />
        <ProfileOperator />
        <DateSelector
          required
          label={t('common:actual_settlement_date')}
          value={leg.actualSettlementDate}
          onChange={(actualSettlementDate) => onUpdateLeg({ ...leg, actualSettlementDate })}
          onBlur={verifyCloseOut}
          onEnterDown={verifyCloseOut}
        />
        <ProfileOperator />
        <NumericField
          required
          fixedLabel
          name="settlement_amount"
          label={t('common:settlement_amount')}
          value={leg.settlementAmount}
          decimalPlaces={priceInputDecimalPlaces}
          onValueChange={(settlementAmount) => onUpdateLeg({ ...leg, settlementAmount })}
          onBlur={verifyCloseOut}
          onEnterDown={verifyCloseOut}
        />
      </ProfileRow>
    </Stack>
  )
}
