import { Divider, Stack } from '@mui/material'
import { Outlet, useNavigate } from 'react-router-dom'
import { useAuth } from '../../modules/auth/data/auth-context'
import NavBar, { NavItemName } from '../nav-bar'

const viewPaths: { [key in NavItemName]: string } = {
  [NavItemName.PortfolioGrid]: '/portfolios',
  [NavItemName.Transactions]: '/transactions',
  [NavItemName.DataEngine]: '/data-engine',
  [NavItemName.OrderBlotter]: '/order-blotter',
  [NavItemName.Compliance]: '/compliance',
  [NavItemName.Liquidity]: '/liquidity',
  [NavItemName.BankAccounts]: '/bank-accounts',
  [NavItemName.Account]: '/account',
  [NavItemName.Settings]: '/settings',
}

function MainNavLayout() {
  const navigate = useNavigate()
  const { logout } = useAuth()

  async function handleLogout() {
    await logout()
    navigate('/login')
  }

  function handleNavigate(view: NavItemName) {
    navigate(viewPaths[view])
  }

  return (
    <Stack direction="row" sx={{ height: '100%', width: '100vw' }}>
      <NavBar onNavigate={handleNavigate} onLogoutClick={handleLogout} />
      <Divider orientation="vertical" />
      <Outlet />
    </Stack>
  )
}

export default MainNavLayout
