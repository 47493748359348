import { DateTimeIso } from '../../../utils/dates'
import { DatapointType, DatapointValue } from './datapoint'
import { GridDataDataset as GridResponseDataset } from './grid-data'
import { GridDataView as GridResponseView } from './grid-data-view'

const assetTypes = [
  'currency',
  'margin',
  'bond',
  'convbond',
  'repo',
  'cds',
  'irs',
  'trs',
  'tbill',
  'loan_note',
  'equity',
  'fx_forward',
  'ndf',
  'future',
  'generic',
  'ticker_currency',
  'ticker_fxfwd',
  'ticker_ndf',
  'ticker_index',
  'ticker_refrate',
  'currency_pair',
  'regular_curve',
] as const

export type AssetType = (typeof assetTypes)[number]

const cashSecurities: AssetType[] = ['bond', 'convbond', 'tbill', 'equity', 'loan_note', 'margin', 'generic']

export function isCashSecurity(assetType: AssetType): boolean {
  return cashSecurities.includes(assetType)
}

export type AssetCategory = 'security' | 'currency' | 'index' | 'ref_rate' | 'curve'

export type DataDocType = 'date_schedule' | 'tenor_curve' | 'curve_definition' | 'subsequent_interest_periods'

export type StaticOptionsResponse = {
  default_view_ref: string | null
  asset_types: AssetTypeOption[]
  datasets: GridResponseDataset[]
  views: GridResponseView[]
}

export type AssetTypeOption = {
  tag: string
  name: string
}

export type StaticResponse = {
  dataset: string
  headings: StaticHeading[]
  rows: StaticRow[]
}

export type StaticHeading = {
  datapoint_ref: string
  datapoint_name: string
  datapoint_type: DatapointType
  datadoc_type?: DataDocType
  classification_id?: number
  source_dataset_ref: string
  source_dataset_name: string
  can_edit: boolean
  can_autogenerate?: true
  asset_category?: AssetCategory
  // added so TS is happy on initColumnOrder
  position: undefined
}

export type StaticRow = {
  asset_ref: string
  asset_desc: string
  datapoints: {
    [datapoint_ref: string]: StaticEntry
  }
}

export type StaticEntry = {
  value: DatapointValue
  display_as?: string
  as_on_date?: Date
  shadowed_value?: DatapointValue
  shadowed_display_as?: string
  modified_at: DateTimeIso
  modified_by: string
  modified_by_display: string
}

export type StaticUploadOptionsResponse = {
  identifier_types: {
    [datapoint_ref: string]: string
  }
  principal_columns: StaticUploadPrincipalColumn[]
  static_fields: StaticHeadingHeader[]
  contract_terms: UamContractTerm[]
}

export type StaticUploadPrincipalColumn = {
  tag: string
  display_as: string
}

export type StaticHeadingHeader = {
  datapoint_ref: string
  datapoint_name: string
  datapoint_type: DatapointType
  classification_id?: number
  asset_category?: AssetCategory
  datadoc_type?: DataDocType
  active_alias?: string
}

export type UamContractTerm = {
  term_tag: ContractTermTag
  name: string
  data_type: DatapointType
  datadoc_type?: DataDocType
  classification_id?: number
}

const contractTermTags = [
  'quote_amount',
  'contract_price',
  'contract_spread',
  'contract_tenor',
  'strike_price',
  'accrued_interest',
  'inception_fx',
  'haircut',
  'is_fully_funded',
  'fixing_date',
  'settlement_date',
  'swap_effective_date',
  'settle_on_quote',
  'initial_margin_rate',
  'margin_in_quote_currency',
  'is_give_up',
  'give_up_to',
  'sink_factor',
  'spot_rate',
  'cashflow_type',
  'settlement_amount',
  'is_collateral',
  'related_asset',
  'tranche',
  'venue',
  'uti',
  'external_txn_ref',
  'cci_number',
  'executor',
] as const

type ContractTermTag = (typeof contractTermTags)[number]

export type StaticUploadInspection = {
  headings: (string | null)[]
  rows: (string | null)[][]
}

export type StaticUploadParams = {
  datapoints: string[]
  useDate: boolean
  createConstituents: boolean
  datasetRef?: string
}

export type GenerateDatatocPayload = {
  asset_ref: string
  datapoint_ref: string
}

export type GenerateDatatocResponse = {
  asset_ref: string
  datapoint_ref: string
  value: DatapointValue
}

export type SymbologyDisplayHeader = {
  asset_ref: string
  asset_type: AssetType
  display_as: string
}
