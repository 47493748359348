import { useParams, useSearchParams } from 'react-router-dom'
import DatapointDetails from '../../components/datapoint-details'

function DatapointDetailsFlexView() {
  const { assetRef, datapointRef, datasetRef } = useParams()

  const [params] = useSearchParams()
  const decimalsParam = params.get('decimalPlaces')
  const decimalPlaces = decimalsParam ? Number(decimalsParam) : undefined

  if (!assetRef || !datapointRef || !datasetRef) {
    return null
  }

  return (
    <DatapointDetails
      assetRef={assetRef}
      datapointRef={datapointRef}
      datasetRef={datasetRef}
      decimalPlaces={decimalPlaces}
    />
  )
}

export default DatapointDetailsFlexView
