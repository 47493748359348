import { Box, InputAdornment, Stack } from '@mui/material'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import NumericField from '../../../../components/fields/numeric-field'
import { TradeDirectionField } from '../../../../components/fields/trade-direction-field'
import { DatapointValueType } from '../../../../services/data/types/datapoint'
import { PricingPayload } from '../../../../services/data/types/pricing'
import { Leg, TradeDirection } from '../../../../services/data/types/trade-ticket'
import { formatNaiveDate } from '../../../../utils/dates'
import { defaultInputDecimalPlaces } from '../../../../utils/numbers'
import DateSelector from './shared/fields/date-selector'
import { ProfileOperator } from './shared/profile-operator'
import { ProfileRow } from './shared/profile-row'

type CloseBondBySpreadProfileProps = {
  leg: Leg
  parValue: DatapointValueType
  isTradeConfirmation: boolean
  onUpdateLeg: (leg: Leg) => void
  onUpdatePricing: (payload: PricingPayload) => void
  onTradeDirectionChange: (tradeDirection: TradeDirection) => void
}

export function CloseBondBySpreadProfile(props: CloseBondBySpreadProfileProps) {
  const { leg, isTradeConfirmation, parValue, onUpdateLeg, onUpdatePricing, onTradeDirectionChange } = props

  const { t } = useTranslation('tradeTicket')

  useEffect(() => {
    updatePrices()
  }, [leg.tradeProfile, parValue])

  function updatePrices() {
    if (!leg.tradeAssetType || !leg.notional || !leg.spread) {
      return
    }

    const payload: PricingPayload = {
      asset_type: leg.tradeAssetType.asset_type_tag,
      quantity_type: 'notional',
      quantity: Number(leg.notional),
      price: undefined,
      consideration: undefined,
      static_data: {},
      proposed_terms: {
        contract_spread: { Float: Number(leg.spread) },
      },
    }

    if (leg.settlementDate) {
      payload.proposed_terms.settlement_date = { NaiveDate: formatNaiveDate(leg.settlementDate) }
    }

    if (typeof parValue === 'number') {
      payload.static_data.par_value = { Float: parValue }
    }

    onUpdatePricing(payload)
  }

  return (
    <Stack gap={3}>
      <ProfileRow>
        <TradeDirectionField
          value={leg.tradeDirection}
          disabled={isTradeConfirmation}
          onChange={onTradeDirectionChange}
          sx={{ maxWidth: '150px', mr: 2 }}
        />

        <NumericField
          required
          fixedLabel
          name="notional"
          label={t('common:notional')}
          value={leg.notional}
          decimalPlaces={defaultInputDecimalPlaces}
          onBlur={updatePrices}
          onEnterDown={updatePrices}
          onValueChange={(notional) => onUpdateLeg({ ...leg, notional })}
        />
        <ProfileOperator operator="" />
        <NumericField
          required
          fixedLabel
          name="spread"
          label={t('spread')}
          value={leg.spread}
          decimalPlaces={defaultInputDecimalPlaces}
          onBlur={updatePrices}
          onEnterDown={updatePrices}
          onValueChange={(spread) => onUpdateLeg({ ...leg, spread })}
          endAdornment={<InputAdornment position="end">bps</InputAdornment>}
        />
      </ProfileRow>
      <ProfileRow>
        <DateSelector
          required
          label={t('common:settlement_date')}
          value={leg.settlementDate}
          onChange={(settlementDate) => onUpdateLeg({ ...leg, settlementDate })}
        />
        <ProfileOperator />
        <Box />
        <ProfileOperator />
        <Box />
      </ProfileRow>
    </Stack>
  )
}
